.loginBar {
    &__username {
        flex-direction: column;
        border: 0;
        @include media('<desktop') {
            margin-right: 0;
        }
        @include media-breakpoint-down(xs) {
            padding: 0 0.25rem;
        }
        span {
            display: block;
            padding: 0;
            @include media-breakpoint-down(xs) {
                font-size: $font-size-xxs;
            }
            &:not(.sign-in) {
                display: block;
                max-width: 5rem;
            }
        }
        #{$icon-selector} {
            padding-right: 0;
            margin-right: 0;
        }
    }
}

.user-login-bar {
    position: static;

    @include media-breakpoint-up(lg) {
        position: relative;
    }
    #{$icon-selector} {
        margin-left: 0;
    }

    [data-db-target-for] {
        padding: 0;
        .icon-close,
        .main-menu__toggle-close {
            display: none;
        }
        &.js--open {
            .icon-close,
            .main-menu__toggle-close {
                display: block;
                color: $deep-gray;
            }

            .person-icon {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                .icon-close,
                .main-menu__toggle-close {
                    display: none;
                }
                .person-icon {
                    display: block;
                }
            }
        }
        & ~ .dropBlock__holder {
            left: 0;
            right: 0;
            top: var(--header-height);
            margin: 0;
            border-radius: 0;
            height: calc(100 * var(--vh) - var(--header-height));

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-up(lg) {
                left: 50%;
                right: auto;
                top: auto;
                margin: 0.625rem -50% 0.625rem 0;
                transform: translate(-50%, 0%);
                border-radius: 0.25rem;
                height: auto;
            }
            &:before {
                display: none;
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
            ul {
                padding: 0;
            }
            a {
                align-items: flex-start;
            }
        }
    }

    li {
        a {
            padding: 1rem 0.75rem;
            font-size: $font-size-sm;
            &:hover {
                background: $light-primary;
                box-shadow: $box-shadow-hover;
                text-decoration: underline;
                color: $btn-color-4;
            }
        }
    }
    & .in {
        display: flex;
        flex-direction: column;
        align-items: center;

        #{$icon-selector} {
            font-size: 3rem;
            color: $primary;
        }
        p {
            margin: 0 0 1rem 0;
        }

        .btn + .btn {
            margin-top: 0.5rem;
        }
    }
}
.pb-edit-view {
    .user-login-bar .d-none {
        display: block !important;
        width: 100%;
    }
}
