#exportCitation {
    .modal {
        &__body {
            position: relative;
            height: 25rem;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 1rem 0;
        }
    }
    .dropdown-menu.show {
        transform: translate3d(0, 2.5rem, 0) !important;
    }
    .csl-wrapper {
        background: $very-light-gray;
    }
    .csl-response {
        padding: 0.625rem;
    }
    .csl-entry {
        white-space: pre;
        + .csl-entry {
            margin-top: 1rem;
        }
    }
    #citation-format {
        width: 100%;
    }
}
