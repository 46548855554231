@import './variables-colors';
:root {
    --journal-color: #{$journal-color};
    --secondary-color: #{$secondary};
}

$has-colored-access-icons: false;
/* stylelint-disable unit-disallowed-list*/
// GRID VARIABLES
$banner-max-width: 1440px;
$container-max-width: 1280px;
$grid-gutter-width: 48px;
$gutter: $grid-gutter-width;
$sage-extra-gap: $grid-gutter-width * 0.5;
$icon-selector: '[class^=' icon- '], [class*=' icon- ']';

$screen-xs: 532px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xlg: 1920px;

$grid-breakpoints: (
    xs: 0,
    sm: 532px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1920px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 1024px,
    xl: 1280px,
);
/* stylelint-enable unit-disallowed-list*/

// Spacing
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        40: (
            $spacer * 2.5,
        ),
    ),
    $spacers
);

// FONTS VARIABLES
$default-font-family: 'Open Sans', sans-serif;
$sage-font-family: 'Sage Peak', sans-serif;
$custom-icomoon-font-family: 'icomoon';
$custom-font-path: './fonts';

$default-font-size: 1rem;
$font-size-base: $default-font-size;
$font-size-lg: $font-size-base * 1.25;
$font-size-md: $font-size-base * 1.125;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.75;
$font-size-xxs: $font-size-base * 0.625;

$font-sizes: ();
$font-sizes: map-merge(
    (
        'base': $font-size-base,
        'lg': $font-size-lg,
        'md': $font-size-md,
        'sm': $font-size-sm,
        'xs': $font-size-xs,
        'xxs': $font-size-xxs,
    ),
    $font-sizes
);

$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Heading Variables

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.125rem;

$heading-fonts: ();
$heading-fonts: map-merge(
    (
        '1': $h1-font-size,
        '2': $h2-font-size,
        '3': $h3-font-size,
        '4': $h4-font-size,
        '5': $h5-font-size,
        '6': $h6-font-size,
    ),
    $heading-fonts
);
$headings: (
    'xxl': (
        'sm': $h3-font-size 1.428,
        'md': $h2-font-size 1.375,
        'lg': $h1-font-size 1.2,
    ),
    'xl': (
        'sm': $h4-font-size 1.5,
        'md': $h3-font-size 1.428,
        'lg': $h2-font-size 1.375,
    ),
    'l': (
        'sm': $h4-font-size 1.5,
        'md': $h4-font-size 1.5,
        'lg': $h3-font-size 1.428,
    ),
    'm': (
        'sm': $h5-font-size 1.6,
        'md': $h5-font-size 1.6,
        'lg': $h4-font-size 1.5,
    ),
    's': (
        'sm': $h6-font-size 1.55,
        'md': $h6-font-size 1.55,
        'lg': $h5-font-size 1.6,
    ),
    'xs': (
        'sm': $font-size-base 1.75,
        'md': $font-size-base 1.75,
        'lg': $h6-font-size 1.55,
    ),
    'xxs': (
        'sm': $font-size-base 1.75,
        'md': $font-size-base 1.75,
        'lg': $font-size-base 1.75,
    ),
    'search': (
        'sm': $h6-font-size 1.555,
        'md': $font-size-base 1.75,
        'lg': $font-size-base 1.75,
    ),
);

$input-border-color: $gray;
$border-color: $gray;
$border-radius: toRem(8);
$box-shadow: 0 toRem(1) toRem(4) 0 rgba(0, 0, 0, 0.12);
/* stylelint-disable unit-disallowed-list*/
$default-border: 1px solid $border-color;
/* stylelint-enable unit-disallowed-list*/
$border-default: $default-border;

// BUTTONS
$btn-min-width: 11.375rem;

$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1rem;
$input-btn-font-family: $default-font-family;
$input-btn-font-size: $font-size-sm;
$input-btn-line-height: 1.57;

$btn-font-size: $input-btn-font-size;
$btn-font-weight: $font-weight-semibold;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-border-radius: toRem(4);

$btn-sso-login-border-radius: 0.25rem;
$btn-sso-login-font-size: $font-size-sm;
$btn-sso-login-line-height: 1.571;
$btn-sso-login-font-weight: $font-weight-bold;
$btn-sso-login-info-font-size: $font-size-xs;
$btn-sso-login-info-line-height: toRem(22);
$btn-sso-login-info-font-weight: $font-weight-normal;

$btn-disabled-background: $gray;
$select-disabled-background: $gray;
$btn-disabled-color: $deep-gray;
$btn-disabled-border-color: $gray-2;
$btn-outline-disabled-border-color: $dark-gray;

// Tooltip
$tooltip-box-shadow: 0 toRem(3) toRem(12) 0 rgba(0, 0, 0, 0.1);

// Body
$body-bg: $white;
/* stylelint-disable unit-disallowed-list*/
$box-shadow-focus: 0 0 0 1px $input-btn-focus-color, inset 0 0 0 1px $input-btn-focus-color;
$box-shadow-focus-secondary: 0 0 0 1px $input-btn-secondary-focus-color,
    inset 0 0 0 1px $input-btn-secondary-focus-color;

/* stylelint-enable unit-disallowed-list*/
$box-shadow-active: inset 0.125rem 0 0 $primary;
$box-shadow-hover: inset 0.125rem 0 0 $secondary;

//BOX SHADOWS
$box-shadow-basic: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.13);
$box-shadow-basic-sm: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.13);
$moreSageProducts-box-shadow: 0 toRem(1) toRem(4) 0 #0000001f;
$box-shadow-basic-sm-hover: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.26);

// COMPONENTS VARIABLES
$moreSageProducts-gap-horizontal: 0.5rem;
$moreSageProducts-gap-vertical: $moreSageProducts-gap-horizontal;
$moreSageProducts-gap-horizontal--sm: $sage-extra-gap;
$moreSageProducts-gap-vertical--sm: 0.5rem;
$moreSageProducts-gap-horizontal--lg: $sage-extra-gap;
$moreSageProducts-gap-vertical--lg: $moreSageProducts-gap-horizontal--lg;
$moreSageProducts-color: #626262;
$moreSageProducts-link-border-radius: toRem(8);
$moreSageProducts-title-line-width: toRem(80);

$disciplineHubs-color: $moreSageProducts-color;

$browse-cards-gap-vertical: 1.25rem;
$browse-cards-gap-horizontal--sm: $grid-gutter-width * 0.5;
$browse-cards-gap-vertical--sm: 1.75rem;
$browse-cards-gap-horizontal--lg: 1rem;
$browse-cards-gap-vertical--lg: $browse-cards-gap-horizontal--lg;

$footer-bg: $white;
$footer-text-color: $darkest-gray;
$footer-font-size: $font-size-xs;

$footer-title-font-size: $font-size-md;
$footer-title-font-weight: $font-weight-semibold;
$footer-title-line-height: 1.75;

$footer-title-font-size--xl: $h6-font-size;
$footer-title-font-weight--xl: $font-weight-semibold;
$footer-title-line-height--xl: 1.75;

$footer-title-margin-top: 1rem;
$footer-title-margin-bottom: $footer-title-margin-top;
$footer-title-color: $deep-gray;
$footer-link-color: $darkest-gray;
$footer-link-color-hover: darken($darkest-gray, 15%);

// Header
$header-height: 3.5rem;
$header-height-md: 4.875rem;
$header-color: $darkest-gray;
$header-top-bg: $white;
$header-icon-color: $primary;
$header-font-color: $darkest-gray;

// Menu
$menu-dropdown-toggle-color: $darkest-gray;
$main-nav-link-hover-color: $darkest-gray;
$main-dropdown-toggle-bg: $white;
$menu-dropdown-item-active-border: 0.125rem solid $primary;
$menu-dropdown-item-hover-border: 0.125rem solid $secondary;
$menu-dropdown-item-color: $darkest-gray;
$menu-dropdown-item-hover-bg: $light-secondary;
$menu-dropdown-item-active-bg: $very-light-gray;
$menu-dropdown-before-arrow-color: $light;
$menu-dropdown-before-arrow: 0.625rem solid transparent;
/* stylelint-disable unit-disallowed-list*/
$menu-dropdown-list-bottom-border: 1px solid $light-gray-2;
/* stylelint-enable unit-disallowed-list*/

// JOURNALS CATEGORIES
$journals-categories-font-size: $font-size-sm;
$journals-categories-font-weight: $font-weight-normal;
$journals-categories-color: $darkest-gray;

$journals-categories-dropdown-border: $default-border;
$journals-categories-font-color: $darkest-gray;
$journals-categories-dropdown--color: $dark-gray;
$journals-categories-dropdown-item-active-bg: $very-light-gray;
$journals-categories-dropdown-item-hover-bg: $light-secondary;
$journals-categories-disabled-dropdown: $light-gray;
$alternative-font-color: $darkest-gray;
$alternative-list-type-color: $secondary;
$alternative-button-border-color: $secondary;
$alternative-button-font-color: $btn-color-1;
$alternative-border: $default-border;
/* stylelint-disable unit-disallowed-list*/
$alternative-outline-border: 1px solid $secondary;
/* stylelint-enable unit-disallowed-list*/

// DROPDOWN overrides
$dropdown-link-color: $darkest-gray;
$dropdown-border-width: 0;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;

$drawer-links-padding: 1rem 0.75rem;

$dropdown-selectable-border: $default-border;
$dropdown-selectable-border-radius: 0 0 toRem(8) toRem(8);
$dropdown-selectable-item-active-bg: $very-light-gray;
$dropdown-selectable-item-hover-bg: $light-primary;

// Quick search
$quick-search-width: toRem(496);
$quick-search-height: 2.375rem;
$quick-search-dropblock-width: 10.375rem;
$quick-search-dropblock-height: 2.5rem;
$quick-search-dropblock-color: $darkest-gray;
$quick-search-dropblock-bg: $light-gray;
$quick-search-border: $default-border;
$quick-search-dropdown-bg: $very-light-gray;
$quick-search-dropdown-item-active-box-shadow: $box-shadow-active;
$quick-search-dropdown-item-hover-box-shadow: $box-shadow-hover;
$quick-search-dropdown-item-hover-bg: $light-secondary;
$quick-search-advanced-search-link: $link;
$zindex-qs: 1090;

// Quick search mobile
$quick-search-dropblock-color-mobile: $darkest-gray;
$quick-search-dropblock-bg-mobile: $light;
$quick-search-label-color: $light;

// institution banner
$institution-width: 13.625rem;
$institution-image-width: 7.25rem;
$institution-image-height: 2.5rem;

$institution-intro-color: $darkest-gray;
$institution-intro-font-size: $font-size-sm;
$institution-intro-line-height: 1.57;

$institution-intro-font-size-lg: $font-size-xs;
$institution-intro-line-height-lg: 1.667;

$institution-name-color: $dark-gray;
$institution-name-font-size: $font-size-xxs;
$institution-name-line-height: 1.57;

$institution-name-font-size-lg: $institution-name-font-size;
$institution-name-line-height-lg: 1.667;

$institution-name2-color: $deep-gray;
$institution-name2-font-size: $font-size-sm;
$institution-name2-font-size-lg: $font-size-xs;
/* stylelint-disable unit-disallowed-list*/
$institution-dropzone-top-border: 1px solid $light-gray-2;
/* stylelint-enable unit-disallowed-list*/
// login bar

$login-bar-dropdown-item-hover-border: 0.125rem solid $secondary;
$login-bar-dropdown-item-hover-bg: $light-secondary;

// dropblock
$dropblock-dropdown-item-hover-color: #333;

// breadcrumbs
$breadcrumbs-text-color: $black;
$breadcrumbs-icon-color: $primary;

// icons
$icon-eye: '\e91c';
$icon-eye-blocked: '\e91d';

// email alerts
$email-alerts-table-head-bg: $very-light-gray;
$email-alerts-table-color: $darkest-gray;
$email-alerts-table-border-bottom: $default-border;
$email-alerts-checkbox-color: $secondary;
$email-alerts-checkbox-border-color: $dark-gray;

// Journal description
$h5-heading-color: $deep-gray;
$aside-text-color: $darkest-gray;
$aside-container-border: $default-border;
$heading-bottom-border: $default-border;

// typo
$heading-title-color: $deep-gray;
$paragraph-font-color: $darkest-gray;
$disabled-text: $dark-gray;
$error-text-color: $error;
$list-text-color: $darkest-gray;

// Graphql API
$graphql-table-striped: $very-light-gray;
$graphql-member-name-color: $darkest-gray;
$graphql-member-affiliation-color: $darkest-gray;

// Accordion
$accordion-bg: $white;
$accordion-color: $primary;
$accordion-border: $default-border;
$accordion-bg-hover: $primary;
$accordion-color-hover: $white;
$accordion-body-text-color: $darkest-gray;
$accordion-show-all-color: $darkest-gray;
// accordion
//$accordion-header-section-bg: $white;
//$accordion-header-section-color: $secondary;

// Journal Menu Banner
$journal-menu-banner-height: 4.25rem;
$journal-menu-banner-padding-y: 0;
$journal-menu-banner-padding-x: $sage-extra-gap;
$journal-menu-banner-bg-color: $very-light-gray;
$journal-menu-banner-title-margin: 0;
$journal-menu-banner-title-font-weight: $font-weight-normal;
$journal-menu-banner-title-font-size: $font-size-base;
$journal-menu-banner-title-color: $darkest-gray;
$journal-menu-banner-border-radius: $border-radius;

$publication-menu-icon-color: $darkest-gray;

// W-SLIDE
$slide-head-height: 3.5rem;
$slide-head-height-md: 5.5rem;
$slide-head-bg: $white;
$slide-head-color: $deep-gray;
$slide-head-font-size: $font-size-base;
$slide-head-border-color: $gray;
$slide-bg: $white;

// banner widget
$banner-widget-gap: 2rem;
$banner-widget-gap--lg: $banner-widget-gap;
$banner-widget-bg-color: #ccc;
$banner-widget-default-padding: 0 0 $banner-widget-gap;
$banner-widget-default-margin: 0 auto $banner-widget-gap;
$banner-widget-default-padding--lg: $banner-widget-default-padding;
$banner-widget-default-margin--lg: $banner-widget-default-margin;

$banner-widget-heading-margin: $sage-extra-gap 0 1rem;
$banner-widget-heading-margin-md: $banner-widget-gap 0;
$banner-widget-heading-margin-lg: $banner-widget-gap 0;

$banner-widget-heading-font-size: $h5-font-size;
$banner-widget-heading-font-size-md: $h4-font-size;
$banner-widget-heading-font-size-lg: $h2-font-size;

$banner-widget-heading-line-height: 1.275;
$banner-widget-heading-line-height-md: 1.275;
$banner-widget-heading-line-height-lg: 1.375;

$banner-widget-heading-font-weight: $font-weight-normal;
$banner-widget-heading-color: $primary;
$banner-widget-min-height: 7.5rem;

// Aside Navigation Menu
$menu-border-radius: $border-radius;
$aside-navigation-border-color: $gray;
$aside-navigation-box-shadow: $box-shadow;
$aside-navigation-gap: 1rem;

$aside-navigation-link-font-size: $font-size-sm;
$aside-navigation-link-font-weight: $font-weight-normal;
$aside-navigation-link-line-height: 1.57;
$aside-navigation-link-color: $darkest-gray;

// Journal MDDB Navigation Menu
// remove after refactoring journal-mddb-navigation-menu widget with aside-navigation classes
$mddb-navigation-border-color: $gray;
/* stylelint-disable unit-disallowed-list*/
$mddb-navigation-border-width: 1px;
/* stylelint-enable unit-disallowed-list*/
$mddb-navigation-border-style: solid;
$mddb-navigation-gap: 1rem;
$mddb-navigation-box-shadow: $box-shadow;
$mddb-navigation-link-font-size: $font-size-sm;
$mddb-navigation-link-font-weight: $font-weight-normal;
$mddb-navigation-link-line-height: 1.57;
$mddb-navigation-link-color: $darkest-gray;

// AD Placeholder
// ad-placeholder
$placeholder-label-bg: $white;
/* stylelint-disable unit-disallowed-list*/
$placeholder-edit-border: 3px dashed $light-gray;
$placeholder-exist-border: none;
$placeholder-debug-border: 1px solid #f00;
/* stylelint-enable unit-disallowed-list*/

// Facets
$facet-padding-y: 1rem;
$facet-padding-x: $facet-padding-y;
/* stylelint-disable unit-disallowed-list*/
$facet-border-size: 1px;
/* stylelint-enable unit-disallowed-list*/
$facet-border-style: solid;
$facet-border-color: $gray;

$facet-title-font-size: $h6-font-size;
$facet-title-font-weight: $font-weight-normal;
$facet-title-line-height: 1.5;
$facet-title-color: $primary;

/* stylelint-disable unit-disallowed-list*/
$alphanumeric-border-size: 1px;
/* stylelint-enable unit-disallowed-list*/
$alphanumeric-border-style: solid;
$alphanumeric-border-color: $primary;
$alphanumeric-font-weight: $font-weight-normal;
$alphanumeric-font-size: $font-size-xs;
$alphanumeric-line-height: 1.667;
$alphanumeric-color: $darkest-gray;
$alphanumeric-color--selected: $white;
$alphanumeric-bg-color--selected: $primary;
$alphanumeric-color--disabled: $dark-gray;
$alphanumeric-bg-color--disabled: $light-gray;
$alphanumeric-border-color--disabled: $gray;

// Journals Metric
$journals-metric-list-font-size: $font-size-sm;
$journals-metric-list-line-height: 1.57;
$journals-metric-list-item-padding: 0.25rem 0;

$journals-metric-metric-header-font-size: $font-size-sm;
$journals-metric-metric-header-line-height: 1.57;
$journals-metric-metric-header-font-weight: $font-weight-bold;

$journals-metric-metric-description-font-size: $font-size-sm;
$journals-metric-metric-description-line-height: 1.57;
$alt-journals-metric-bg: #ebf6ff;

// Saerch result
$search-current-results-font-weight: 600;
$publications-search-item-padding-bottom: $grid-gutter-width * 0.5;
$publications-search-item-margin-bottom: $grid-gutter-width * 0.5;

$tab-font-weight: 400;
$tab-font-weight-tablet: 400;
$tab-active-font-weight: $tab-font-weight;
$tab-nav-active-color: $primary;
$tab-nav-active-border-color: $primary;
$tab-nav-active-background: #f2f3f8;
$tab-nav-hover-color: $secondary;
$tab-nav-hover-border-color: $secondary;

// Back to top
$back-to-top-uniform-padding: 2rem;
$back-to-top-size: 3.375rem;
$back-to-top-size-md: $back-to-top-size;
$back-to-top-ring-color: var(--primary);
$back-to-top-ring-bg-color: #c4c4c4;
$back-to-top-ring-thickness: 0.25rem;
$back-to-top-fade-position-amount: 1.25rem;
$back-to-top-action-icon-size: 2.125rem;
$back-to-top-action-icon-color: #888;
/* stylelint-disable unit-disallowed-list*/
$back-to-top-bounce-animation-translateY-amount: -4px;
/* stylelint-enable unit-disallowed-list*/
$back-to-top-bounce-animation-duration: 0.35s;
$back-to-top-z-index: 5;
$back-to-top-action-z-index: 2;

// Hamburger
$burger-width: 1.75rem;
$burger-icon-font-size: 1.375rem;
$burger-label-font-size: $font-size-xxs;
$burger-label-font-weight: $font-weight-normal;
$burger-label-line-height: 1.8;

// tooltips
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 19rem;
$tooltip-color: $darkest-gray;
$tooltip-bg: $white;
$tooltip-border-radius: 0.125rem;
$tooltip-border-color: $gray;
$tooltip-padding-y: 0.75rem;
$tooltip-padding-x: 1rem;
$tooltip-arrow-width: 0.625rem;
$tooltip-arrow-height: $tooltip-arrow-width;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-case: none;
$tooltip-font-weight: $font-weight-normal;
$tooltip-font-style: normal;

// altmetrics
$altmetric-size: 3rem;
$altmetric-size-lg: 5rem;

// ads
$article-ads-margin: 0 auto 1.25rem auto;

//journal cover box
$journal-cover-image-width: 8rem;

// society logo
$society-logo-width: 7.25rem;
$society-logo-height: 3.25rem;

$alert-padding-x: 1rem;
$alert-padding-y: $alert-padding-x * 0.5;
$alert-border-radius: toRem(8);

$custom-control-indicator-size: $font-size-lg;
$custom-control-inside-indicator-size: 0.75rem;
$component-active-bg: $secondary;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-border-color: $dark-gray;
$custom-control-indicator-active-bg: $white;
$custom-control-indicator-active-border-color: $dark-gray;
/* stylelint-disable unit-disallowed-list*/
$custom-control-indicator-border-width: 1px;
/* stylelint-enable unit-disallowed-list*/

$custom-switch-width: 3.5rem;
$custom-switch-indicator-border-radius: 1rem;
$custom-switch-indicator-size: subtract(2rem, $custom-control-indicator-border-width * 4);

$link-focus-outline: none;

$raa-form-max-width: 37.5rem;
$error-msg-color: $primary;

// eCommerce confirmation-table
$confirmation-margin: 0;
$confirmation-logo-width: 1.75rem;
$confirmation-text-margin: $font-size-base;
$confirmation-text-padding: 0;
$confirmation-table-thead-font-size: $font-size-sm;
$confirmation-table-thead-font-weight: $font-weight-semibold;
$confirmation-table-thead-line-height: 1.57;
$confirmation-table-thead-color: $darkest-gray;
$confirmation-table-thead-border-color: $gray;

$confirmation-table-tbody-font-size: $font-size-sm;
$confirmation-table-tbody-font-weight: $font-weight-normal;
$confirmation-table-tbody-line-height: 1.57;
$confirmation-table-tbody-color: $darkest-gray;
$confirmation-table-tbody-border-color: $gray;

$confirmation-table-total-font-size: $font-size-sm;
$confirmation-table-total-font-weight: $font-weight-semibold;
$confirmation-table-total-line-height: 1.57;
$confirmation-table-total-color: $darkest-gray;

$confirmation-table-total-price-color: $darkest-gray;

/* stylelint-disable unit-disallowed-list*/
$checkout-cart-border-size: 1px;
/* stylelint-enable unit-disallowed-list*/
$checkout-cart-border-style: solid;
$checkout-cart-border-color: $gray;

$form-grid-gutter-width: 1.5rem;

$icon-lock-color: $black;

/* stylelint-disable unit-disallowed-list*/
$sections-break-point: 9999px;
/* stylelint-enable unit-disallowed-list*/

$icon-loader-data: '../images/spiner.svg';
$icon-play-data: '../../images/play.svg';

$autocomplete-background-color: transparent;
$autocomplete-font-weight: $font-weight-semibold;

$multi-search-max-width: 50.5rem;
$publication-list-max-width: $multi-search-max-width;

$card-size: 11.25rem;

$marketing-spot-svg-container-size: 8.25rem;
$marketing-spot-svg-size: 7.75rem;
$marketing-spot-svg-container-size-md: 11.25rem;
$marketing-spot-svg-size-md: 10.25rem;
$marketing-spot-svg-container-size-lg: toRem(176);
$marketing-spot-svg-size-lg: 8.25rem;

$modal-max-width: 40rem;
$modal-wide-max-width: 45rem;

$logo-height: 2rem;
$logo-max-height: 100%;

// Tables
$table-head-background: #4e60ac;
$table-box-shadow: toRem(0) toRem(1) toRem(4) toRem(0) rgba(0, 0, 0, 0.12);
$table-row-selected-background: #f2f3f8;

// Z-INDEX
$zindex-behindall: -1;
$zindex-zero: 0;
$zindex-one: 1;
$zindex-two: 2;
$zindex-three: 3;
$zindex-sticky-video-wrapper: 10;
$zindex-header-first-row: 11;
$zindex-society-logos: 1010;
$zindex-loading-overlay: 1100;
$zindex-sr-only-focusable: 1200;
$zindex-alert: 9999;
$zindex-overall: 9900;
$zindex-kbart-tooltip: 1;
$zindex-kbart-modal: 9900;
$zindex-browse-publications: 9999;
$zindex-figure-wrap: 1;
