%search-field-icon {
    @include font-size($advanced-search-input-icon-size);
    font-weight: $advanced-search-input-icon-fw;
    color: $advanced-search-input-icon-color;

    &:hover {
        color: $advanced-search-input-icon-hvr-color;
    }

    @include media('<phone') {
        margin-inline-start: $advanced-search-input-icon-ml;
    }

    i {
        font-weight: inherit;
    }
}

.advanced-search {
    $this: &;

    label {
        min-width: $advanced-search-label-min-width;
        font-weight: $advanced-search-label-fw;
        color: $advanced-search-label-color;
        @include font-size($advanced-search-label-fs);
    }

    &__select {
        width: $advanced-search-in-select-width;
        flex-shrink: 0;
        padding: $advanced-search-in-select-p;
        margin-bottom: $advanced-search-in-select-mb;
        border-color: $advanced-search-inputs-border-color;
        @include media('>phone') {
            width: $advanced-search-in-select-width-lg;
            margin-bottom: $advanced-search-in-select-mb-lg;
        }
    }

    &__input {
        width: $advanced-search-searchIn-inputs-width;
        border-color: $advanced-search-inputs-border-color;

        @include media('>phone') {
            width: $advanced-search-searchIn-inputs-width-lg;
            margin: $advanced-search-searchIn-inputs-margin;
        }
    }

    &__search-in {
        @include media('<phone') {
            border-bottom: $advanced-search-searchIn-border;
            margin-bottom: $advanced-search-searchIn-mb;
        }

        &__area {
            font-size: $advanced-search-term-area-fs;
            margin-inline-end: $advanced-search-term-area-ml;
            color: $advanced-search-term-area-color;
            font-weight: $advanced-search-term-area-fw;
        }

        &__add,
        &__remove {
            @extend %search-field-icon;
        }

        &__field,
        &__term {
            display: flex;
            align-items: center;

            @include media('<=phone') {
                flex-wrap: wrap;
            }
        }
    }

    &__autocomplete {
        &__wrapper {
            position: relative;
            width: $advanced-search-searchIn-inputs-width;

            @include media('>phone') {
                width: $advanced-search-searchIn-inputs-width-lg;
            }

            & > #{$this}__autocomplete {
                width: 100%;
                margin-inline-start: $advanced-search-autocomplete-ml;
            }
        }
    }

    &__timeframe {
        display: flex;
        align-items: flex-start;
        @include media('<phone') {
            padding-top: $advanced-search-filter-date-pt;
            border-bottom: $advanced-search-filter-date-border;
            border-top: $advanced-search-filter-date-border;
            flex-direction: column;
            gap: $advanced-search-time-frame-gap;
        }

        label,
        .label {
            width: auto;
            min-width: $advanced-search-time-frame-label-mw;
            padding: 0;
            margin: 0;
        }

        .label-txt {
            color: $advanced-search-label-color;
            @include font-size($advanced-search-label-fs);
            font-weight: $advanced-search-label-fw;
            overflow: visible;
            display: flex;
            align-items: center;
            &::before {
                margin-top: 0;
                margin-inline-end: $advanced-search-time-frame-radio-mr;
            }
        }

        &__item {
            flex: 1;
        }
    }

    .autoComplete_wrapper {
        border: 1px solid $advanced-search-inputs-border-color;
        overflow: hidden auto;
        height: $advanced-search-inputs-height;
        padding: $advanced-search-autocomplete-wrapper-p;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $advanced-search-autocomplete-wrapper-gap;

        input {
            width: auto;
            border: none;
            outline: none;
            margin: 0;
            padding: 0;
        }
    }

    .accordion__control {
        color: $advanced-search-accordion-control-color;
        border-bottom: $advanced-search-accordion-control-border;
        font-weight: $advanced-search-accordion-control-fw;
        padding-bottom: $advanced-search-accordion-control-pb;
        @include font-size($advanced-search-accordion-control-fs);

        i {
            @include font-size($advanced-search-accordion-control-icon-size);
        }
    }

    > .btn {
        margin-bottom: $advanced-search-btn-mb;

        &:disabled {
            cursor: not-allowed;
        }
    }
}

.timeframe {
    &__fields {
        width: $advanced-search-searchIn-inputs-width;
    }
    &__to,
    &__from {
        display: inline-flex;
        flex: 1;
        margin: $advanced-search-custom-range-fields-m;
        gap: $advanced-search-custom-range-select-m;
    }

    &__from {
        @include media('>phone') {
            margin-inline-end: $advanced-search-timefrom-mr;
        }
    }

    &__year {
        min-width: $advanced-search-year-range-w;
        margin: 0;
    }

    &__month {
        min-width: $advanced-search-month-range-w;
    }

    &__static-range,
    &__custom-range-filter {
        display: flex;
        align-items: baseline;
        gap: $advanced-search-time-frame-fields-gap;
        @include media('>phone') {
            margin-inline-start: $advanced-search-time-frame-fields-ml;
        }
    }

    &__static-range {
        &__select {
            width: $advanced-search-static-range-w;
            padding: $advanced-search-time-frame-select-p;
        }
    }

    &__select {
        width: auto;
        flex: 1;
        padding: $advanced-search-time-frame-select-p;
    }
}
