.federated-access-login {
    p {
        line-height: 1.75;
        margin: 1rem 0;
    }
    a.btn[data-id='article-access-seamless'] {
        margin-bottom: 1rem;
        width: toRem(328);
        & + p {
            margin: 0;
        }
        padding: toRem(9) toRem(16);

        i {
            font-size: 1.5rem;
        }

        &:hover,
        &:focus {
            color: $btn-color-4;

            i {
                color: $btn-color-4;
            }

            em {
                background: $btn-color-4;
            }
        }
    }
    &__edit-institution {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        font-size: $font-size-sm;
        line-height: 1.57;
        text-decoration: none;
        &:hover {
            color: $link;
        }
    }
}
