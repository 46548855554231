.pill {
    &__item {
        &__header {
            @extend %heading-s;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: 1.75;
            margin: 0 0 1rem 0;
        }
        .btn {
            font-size: $font-size-xs;
            line-height: 1.667;
            width: 100%;
            text-transform: capitalize;

            @include media-breakpoint-up(sm) {
                width: auto;
                min-width: 16.5rem;
            }
        }
    }
    .form-group {
        label {
            font-size: $font-size-base;
        }
    }
}

.core-collateral {
    .btn {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: auto;
            min-width: 16.5rem;
        }
    }
}
