.teaser {
    $this: &;
    $gap: 1.25rem;
    border-bottom: $default-border;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    &__access-icon {
        position: absolute;
        left: 0;
        top: 0;
        &:not(:empty) {
            display: flex;
            align-items: center;
            width: 1.5rem;
        }
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            width: 1.5rem;
        }
        svg {
            width: 100%;
        }
    }

    &__meta {
        &--bottom,
        &--top {
            position: relative;
            display: block;
            margin-bottom: 0.75em;
            margin-left: 2.5rem;
            font-size: $font-size-sm;
            color: $darkest-gray;
            line-height: 1.57;
            letter-spacing: normal;

            #{$this}-access {
                #{$icon-selector} {
                    font-size: 1.5rem;
                    position: absolute;
                    left: -2.5rem;
                }
            }

            .teaser__meta__row {
                display: inline;
                padding-right: 1rem;
                font-size: $font-size-sm;
                &:after {
                    display: none;
                }

                + .teaser__meta__row {
                    padding-left: 1rem;
                    border-left: $default-border;
                }
            }
        }
        &--bottom {
            margin-left: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        @include media-breakpoint-up(sm) {
            gap: 1.5rem;
        }
        &__content {
            flex-grow: 1;
        }
    }

    &__title {
        font-size: $font-size-base;
        line-height: 1.75;
        margin-bottom: 1rem;
    }
    &__contribs {
        color: inherit;
        font-size: $font-size-sm;
        line-height: 1.57;
        a {
            text-decoration: none;
            color: inherit;
        }
    }
    &--collection {
        $gap: 2rem;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        @include media-breakpoint-up(sm) {
            padding-left: $gap;
        }
        .teaser__meta__row {
            margin-left: $gap;
            + .teaser__meta__row {
                &:before {
                    display: none;
                }
                margin-left: $gap;
            }
        }
        .teaser__meta {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            font-size: $font-size-sm;
            line-height: 1.57;
            margin-bottom: 0.5rem;
            @include media-breakpoint-up(sm) {
                margin-left: -1 * $gap;
            }
        }
        .teaser__title {
            font-size: $font-size-sm;
            line-height: 1.57;
            margin-bottom: 0.5rem;
        }
        .teaser__content {
            font-size: $font-size-xs;
            line-height: 1.667;
        }
        .teaser__authors {
            margin-bottom: 0.5rem;
        }
        .teaser__collapsible-details {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &--collection-external {
        $gap: 0;
        @include media-breakpoint-up(sm) {
            padding-left: $gap;
        }
        .teaser__meta {
            @include media-breakpoint-up(sm) {
                margin-left: -1 * $gap;
                flex-direction: column;
                align-items: flex-start;
            }
            &__row {
                margin-left: $gap;
                @include media-breakpoint-up(sm) {
                    display: flex;
                }
                + .teaser__meta__row {
                    margin-left: $gap;
                }
            }
        }
    }
    &--multisearch {
        padding-left: 0;
        @include media-breakpoint-up(sm) {
            padding-left: 0;
        }
        .teaser__title {
            margin-bottom: 0.75rem;
            font-family: $default-font-family;
        }
        .teaser__contribs {
            margin-bottom: 0.5rem;
        }

        .loa.mobile-authors {
            color: inherit;
        }
        .contribLabel {
            display: none;
        }
    }
}

.altmetric {
    flex: 0 0 auto;
    &__img {
        width: $altmetric-size;
        @include media-breakpoint-up(sm) {
            width: $altmetric-size-lg;
        }
    }
}
