.journals-categories {
    margin: 0.375rem 0;
    padding-bottom: 1rem;
    color: $journals-categories-color;
    font-size: $journals-categories-font-size;
    font-weight: $journals-categories-font-weight;
    @include media-breakpoint-up(md) {
        margin: 1rem;
    }

    &__disciplines,
    &__subjects {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        column-gap: 1rem;
        color: inherit;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &__disciplines {
        margin-bottom: 0.58rem;
    }

    &__text {
        width: 100%;
        margin-bottom: 0.3rem;
        @include media-breakpoint-up(md) {
            width: 25%;
            margin-bottom: 0;
        }
    }
    &__label {
        color: inherit;
        font-size: $font-size-sm;
        white-space: nowrap;
    }
    .dropdown {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: calc(75% - 1rem);
        }
        &-toggle {
            padding: 0.562rem 0.75rem;
        }
    }
    &__btn {
        margin-left: 0;
        width: 100%;
        line-height: inherit;
        font-size: toRem(14);
        padding: toRem(8) toRem(16);
        @include media-breakpoint-up(md) {
            margin-left: calc(25% + 1rem);
            width: auto;
        }
    }
}

.alternative {
    margin: 1rem;
    padding-top: 1rem;
    border-top: $alternative-border;
    color: $alternative-font-color;
    @include media('<desktop') {
        margin: 0;
        padding-bottom: 2rem;
    }
    &__title {
        font-size: $font-size-sm;
        font-weight: 400;
        margin-bottom: 0.625rem;
    }

    &__list {
        list-style-type: disc;
        padding-left: 1.5rem;
    }
    &__item {
        display: list-item;
    }
    &__item,
    p {
        font-size: $font-size-sm;
        font-weight: 400;
        margin-bottom: 0.625rem;

        p {
            margin: 0;
        }
    }

    &__link.btn[role='link'] {
        //padding: 0.625rem 1rem;
        display: inline-flex;
        width: auto;
        color: $alternative-button-font-color;
        font-size: toRem(14);
        font-weight: 600;
        padding: toRem(8) toRem(16);
        margin-top: 0.625rem;
        margin-left: 0;
        border: 1px solid $primary;

        @include media-breakpoint-up(md) {
            margin-left: calc(25% + 1rem);
        }

        &:hover {
            color: $white;
        }

        &.gold-open-link {
            margin-left: calc(14% + 1rem);
            font-size: toRem(14);
            font-weight: 600;
        }
    }

    li[data-id='listItem'] {
        display: list-item;
        &:after,
        &:before {
            display: none;
        }
        @include media('<desktop') {
            margin-left: 2rem;
        }
    }
}
