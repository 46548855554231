@each $key, $list in $headings {
    .heading-#{$key} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;

        font-size: nth(map-get($list, 'sm'), 1);
        line-height: nth(map-get($list, 'sm'), 2);

        @include media-breakpoint-up('sm') {
            font-size: nth(map-get($list, 'md'), 1);
            line-height: nth(map-get($list, 'md'), 2);
        }

        @include media-breakpoint-up('lg') {
            font-size: nth(map-get($list, 'lg'), 1);
            line-height: nth(map-get($list, 'lg'), 2);
        }

        #{$icon-selector} {
            font-size: 1.333em;
            color: $primary;
        }
    }
}
