.browse-by-buttons {
    display: flex;
    flex-direction: column;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
    margin: $sage-extra-gap 0;

    .btn {
        width: 100%;
        font-size: toRem(14);
        line-height: 1.667;
        text-transform: capitalize;
        padding: 0 1rem;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;

        .btn {
            width: 14.5rem;
        }
    }
}
