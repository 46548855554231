.rate-card {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    padding: 0 0 1.5rem;
    margin: 1.5rem 0 2.5rem;
    border-bottom: $default-border;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
    h3 {
        @extend %heading-s;
    }
    ul {
        @extend .slist;
    }

    &__image {
        width: 100%;
        @include media-breakpoint-up(sm) {
            flex-shrink: 0;
            width: auto;
        }
    }
    &__text {
        width: 100%;
        p {
            margin-top: 0;
        }
    }

    &__journal {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__image {
            img {
                height: $card-size;
                object-fit: cover;
            }
            &:after {
                content: '';
                height: toRem(1);
                width: 8rem;
                margin: 1.25rem 0 1.5rem;

                background-color: $primary;
            }
        }
        p {
            + p {
                margin-top: 1rem;
            }
        }
    }
}
