.remote_institutional_access {
    .section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
        margin: 0;
        border-bottom: $default-border;
        &:first-child {
            border-top: $default-border;
        }
        .section__info {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
        .section__actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;
            margin-left: auto;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
    .icons_part {
        #{$icon-selector} {
            font-size: 1.75rem;
            &:before {
                color: $primary;
            }
        }
    }
    .institution_part {
        .institution_name,
        .remaining_time {
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            line-height: 1.57;
            color: $darkest-gray;
        }
        .remaining_time {
            color: $deep-gray;
        }
    }

    .renew_part {
        flex: 0 auto;
    }

    .expired {
        .institution_part {
            opacity: 1;
            .remaining_time {
                color: $error;
            }
        }
        .icons_part {
            opacity: 1;
            #{$icon-selector} {
                &:before {
                    color: $dark-gray;
                }
            }
        }
    }

    a.btn {
        justify-content: center;
        font-size: 1rem;
        line-height: 1.667;
        text-transform: capitalize;
        min-width: 10.5rem;
    }

    &__remove {
        display: block;
        text-decoration: none;
        #{$icon-selector} {
            font-size: 1.75rem;
            &:before {
                color: $error;
            }
        }
    }
    &__info {
        @extend .tooltip__info;
    }
}
