@mixin focused {
    /* stylelint-disable unit-disallowed-list*/
    box-shadow: 0 0 0 3px $input-btn-focus-color;
    /* stylelint-enable unit-disallowed-list*/
}
@mixin acHighlight {
    padding: 0;
    background-color: $autocomplete-background-color;
    font-weight: $autocomplete-font-weight;
}
@mixin disabledState() {
    &.disabled,
    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        pointer-events: none;
        background-color: $btn-disabled-background;
        border-color: $btn-disabled-border-color;
        color: $btn-disabled-color;
        opacity: 1;
    }
}

.hvr-shrink {
    min-width: 1rem;
}

body {
    margin: 0;
    padding: 0;
    color: $darkest-gray;
    font-size: $default-font-size;
    font-family: $default-font-family;

    &.restricted-abstracts {
        article:has(.denial-block) {
            #abstracts,
            #supplementary-materials {
                display: none;
            }
        }
    }
}

.offscreen {
    position: absolute;
    left: -100rem;
    top: auto;
    width: toRem(1);
    height: toRem(1);
    overflow: hidden;
}

.container-fluid {
    max-width: $container-max-width;
}
.container {
    &:before,
    &:after {
        content: unset;
    }
}
.content {
    padding: 0.1rem 0 0;
}
.padding-wrapper {
    padding: 0;
}

.row:before,
.row:after {
    content: none;
    display: none;
}

::-webkit-input-placeholder {
    letter-spacing: normal;
}

input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.popover {
    font-family: $default-font-family;
}

.lock-screen {
    position: fixed;
    width: 100%;
}

body:not(.mouse-user) * {
    &:focus-visible,
    &:focus {
        outline: none;
        box-shadow: $box-shadow-focus;
    }
}

a[disabled] {
    cursor: default;
    text-decoration: none;
    pointer-events: none;
}
.pb-rich-text {
    margin-bottom: 1rem;
}

/// screen reader and show on tab focus
.sr-only-focusable {
    &:focus,
    &:active {
        color: $secondary !important;
        background: $white !important;
        border-color: $secondary !important;
        box-shadow: $box-shadow-focus !important;
        border-radius: $border-radius;
        z-index: $zindex-sr-only-focusable !important;
        text-decoration: none !important;
    }
    &:hover {
        opacity: 1;
    }
}

.loader,
%loader {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 2.5rem;
    height: 2.5rem;
    background-image: url($icon-loader-data);
}

.loading-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $zindex-loading-overlay;
    background-color: $white;
    opacity: 0.8;
    display: none;

    .loading-container {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .loading {
        @extend %loader;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$custom-icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.hidden {
    display: none !important;
}
.verticalSeparator {
    width: toRem(1);
    background-color: $gray;
    align-self: stretch;
}
.page__content {
    padding: 1.5rem 0;
}

.cookie-privacy {
    color: $link !important; // I had to add important to override the style from the third party cookie
    text-decoration: underline !important; // I had to add important to override the style from the third party cookie

    &:hover {
        text-decoration: none !important; // I had to add important to override the style from the third party cookie
    }
}

%heading-xxs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.75;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
        line-height: 1.75;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: 1.75;
    }
}

%heading-xs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.75;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
        line-height: 1.75;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        line-height: 1.55;
    }
}

%heading-m {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.6;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        line-height: 1.6;
    }

    @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.5;
    }
}

%heading-s {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.55;

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 1.55;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 1.6;
    }
}

%aside__header {
    padding: 1rem 0 0.5rem;
    margin: 0 0 1rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    text-transform: none;
    color: $primary;
    border-bottom: 1px solid #ccc;
}

%more-sage-products__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    justify-content: center;

    @include media-breakpoint-between(sm, md) {
        justify-content: flex-start;
    }
}

.success-message {
    span {
        color: $black;
    }
}
