.access-widget {
    &__searching {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: flex-start;
        justify-content: flex-start;
        @include media-breakpoint-up(md) {
            align-items: center;
            flex-direction: row;
            gap: 0.5rem;
        }
        .input-group {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 26rem;
            }
        }
    }
    &__sorting {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;

        .form-control {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 11.5rem;
            }
        }
    }
    .sort-by {
        margin-bottom: 1.5rem;
    }
    .input-group-append {
        margin-left: toRem(-4);
        .btn {
            background-color: $white;
            border-radius: 0 0.25rem 0.25rem 0;
            height: toRem(40);
            color: $primary;
            &:hover {
                background-color: $white;
                color: darken($primary, 10%);
            }
        }
    }

    .account-tab__nav {
        .tab__nav__item {
            font-family: $sage-font-family;

            @include media-breakpoint-down(lg) {
                font-family: $default-font-family;
            }
        }
    }
}
