.publication-list {
    .content-center & {
        width: 100%;
        max-width: $publication-list-max-width;
        margin: 0 auto;
    }
    .reduced-width & {
        width: 100%;
        max-width: $publication-list-max-width;
    }
    .show-more[aria-expanded='true'] {
        display: none;
    }
}
