.flag {
    display: inline-block;
    height: toRem(32);
    width: toRem(32);
    vertical-align: text-top;
    line-height: toRem(32);
    background: url(../../images/flags32.png) no-repeat;
    padding-left: toRem(34);
}

._African_Union {
    background-position: 0 toRem(-32);
}

._Arab_League {
    background-position: 0 toRem(-64);
}

._ASEAN {
    background-position: 0 toRem(-96);
}

._CARICOM {
    background-position: 0 toRem(-128);
}

._CIS {
    background-position: 0 toRem(-160);
}

._Commonwealth {
    background-position: 0 toRem(-192);
}

._England {
    background-position: 0 toRem(-224);
}

._European_Union,
.eu {
    background-position: 0 toRem(-256);
}

._Islamic_Conference {
    background-position: 0 toRem(-288);
}

._Kosovo {
    background-position: 0 toRem(-320);
}

._NATO {
    background-position: 0 toRem(-352);
}

._Northern_Cyprus {
    background-position: 0 toRem(-384);
}

._Northern_Ireland {
    background-position: 0 toRem(-416);
}

._Olimpic_Movement {
    background-position: 0 toRem(-448);
}

._OPEC {
    background-position: 0 toRem(-480);
}

._Red_Cross {
    background-position: 0 toRem(-512);
}

._Scotland {
    background-position: 0 toRem(-544);
}

._Somaliland {
    background-position: 0 toRem(-576);
}

._Tibet {
    background-position: 0 toRem(-608);
}

._United_Nations {
    background-position: 0 toRem(-640);
}

._Wales {
    background-position: 0 toRem(-672);
}

.ad {
    background-position: 0 toRem(-704);
}

.ae {
    background-position: 0 toRem(-736);
}

.af {
    background-position: 0 toRem(-768);
}

.ag {
    background-position: 0 toRem(-800);
}

.ai {
    background-position: 0 toRem(-832);
}

.al {
    background-position: 0 toRem(-864);
}

.am {
    background-position: 0 toRem(-896);
}

.ao {
    background-position: 0 toRem(-928);
}

.aq {
    background-position: 0 toRem(-960);
}

.ar {
    background-position: 0 toRem(-992);
}

.as {
    background-position: 0 toRem(-1024);
}

.at {
    background-position: 0 toRem(-1056);
}

.au {
    background-position: 0 toRem(-1088);
}

.aw {
    background-position: 0 toRem(-1120);
}

.ax {
    background-position: 0 toRem(-1152);
}

.az {
    background-position: 0 toRem(-1184);
}

.ba {
    background-position: 0 toRem(-1216);
}

.bb {
    background-position: 0 toRem(-1248);
}

.bd {
    background-position: 0 toRem(-1280);
}

.be {
    background-position: 0 toRem(-1312);
}

.bf {
    background-position: 0 toRem(-1344);
}

.bg {
    background-position: 0 toRem(-1376);
}

.bh {
    background-position: 0 toRem(-1408);
}

.bi {
    background-position: 0 toRem(-1440);
}

.bj {
    background-position: 0 toRem(-1472);
}

.bm {
    background-position: 0 toRem(-1504);
}

.bn {
    background-position: 0 toRem(-1536);
}

.bo {
    background-position: 0 toRem(-1568);
}

.br {
    background-position: 0 toRem(-1600);
}

.bs {
    background-position: 0 toRem(-1632);
}

.bt {
    background-position: 0 toRem(-1664);
}

.bw {
    background-position: 0 toRem(-1696);
}

.by {
    background-position: 0 toRem(-1728);
}

.bz {
    background-position: 0 toRem(-1760);
}

.ca {
    background-position: 0 toRem(-1792);
}

.cd {
    background-position: 0 toRem(-1824);
}

.cf {
    background-position: 0 toRem(-1856);
}

.cg {
    background-position: 0 toRem(-1888);
}

.ch {
    background-position: 0 toRem(-1920);
}

.ci {
    background-position: 0 toRem(-1952);
}

.ck {
    background-position: 0 toRem(-1984);
}

.cl {
    background-position: 0 toRem(-2016);
}

.cm {
    background-position: 0 toRem(-2048);
}

.cn {
    background-position: 0 toRem(-2080);
}

.co {
    background-position: 0 toRem(-2112);
}

.cr {
    background-position: 0 toRem(-2144);
}

.cu {
    background-position: 0 toRem(-2176);
}

.cv {
    background-position: 0 toRem(-2208);
}

.cy {
    background-position: 0 toRem(-2240);
}

.cz {
    background-position: 0 toRem(-2272);
}

.de {
    background-position: 0 toRem(-2304);
}

.dj {
    background-position: 0 toRem(-2336);
}

.dk {
    background-position: 0 toRem(-2368);
}

.dm {
    background-position: 0 toRem(-2400);
}

.do {
    background-position: 0 toRem(-2432);
}

.dz {
    background-position: 0 toRem(-2464);
}

.ec {
    background-position: 0 toRem(-2496);
}

.ee {
    background-position: 0 toRem(-2528);
}

.eg {
    background-position: 0 toRem(-2560);
}

.eh {
    background-position: 0 toRem(-2592);
}

.er {
    background-position: 0 toRem(-2624);
}

.es {
    background-position: 0 toRem(-2656);
}

.et {
    background-position: 0 toRem(-2688);
}

.fi {
    background-position: 0 toRem(-2720);
}

.fj {
    background-position: 0 toRem(-2752);
}

.fm {
    background-position: 0 toRem(-2784);
}

.fo {
    background-position: 0 toRem(-2816);
}

.fr {
    background-position: 0 toRem(-2848);
}

.bl,
.cp,
.mf,
.yt {
    background-position: 0 toRem(-2848);
}

.ga {
    background-position: 0 toRem(-2880);
}

.gb {
    background-position: 0 toRem(-2912);
}

.sh {
    background-position: 0 toRem(-2912);
}

.gd {
    background-position: 0 toRem(-2944);
}

.ge {
    background-position: 0 toRem(-2976);
}

.gg {
    background-position: 0 toRem(-3008);
}

.gh {
    background-position: 0 toRem(-3040);
}

.gi {
    background-position: 0 toRem(-3072);
}

.gl {
    background-position: 0 toRem(-3104);
}

.gm {
    background-position: 0 toRem(-3136);
}

.gn {
    background-position: 0 toRem(-3168);
}

.gq {
    background-position: 0 toRem(-3232);
}

.gr {
    background-position: 0 toRem(-3264);
}

.gt {
    background-position: 0 toRem(-3296);
}

.gu {
    background-position: 0 toRem(-3328);
}

.gw {
    background-position: 0 toRem(-3360);
}

.gy {
    background-position: 0 toRem(-3392);
}

.hk {
    background-position: 0 toRem(-3424);
}

.hn {
    background-position: 0 toRem(-3456);
}

.hr {
    background-position: 0 toRem(-3488);
}

.ht {
    background-position: 0 toRem(-3520);
}

.hu {
    background-position: 0 toRem(-3552);
}

.id {
    background-position: 0 toRem(-3584);
}

.mc {
    background-position: 0 toRem(-3584);
}

.ie {
    background-position: 0 toRem(-3616);
}

.il {
    background-position: 0 toRem(-3648);
}

.im {
    background-position: 0 toRem(-3680);
}

.in {
    background-position: 0 toRem(-3712);
}

.iq {
    background-position: 0 toRem(-3744);
}

.ir {
    background-position: 0 toRem(-3776);
}

.is {
    background-position: 0 toRem(-3808);
}

.it {
    background-position: 0 toRem(-3840);
}

.je {
    background-position: 0 toRem(-3872);
}

.jm {
    background-position: 0 toRem(-3904);
}

.jo {
    background-position: 0 toRem(-3936);
}

.jp {
    background-position: 0 toRem(-3968);
}

.ke {
    background-position: 0 toRem(-4000);
}

.kg {
    background-position: 0 toRem(-4032);
}

.kh {
    background-position: 0 toRem(-4064);
}

.ki {
    background-position: 0 toRem(-4096);
}

.km {
    background-position: 0 toRem(-4128);
}

.kn {
    background-position: 0 toRem(-4160);
}

.kp {
    background-position: 0 toRem(-4192);
}

.kr {
    background-position: 0 toRem(-4224);
}

.kw {
    background-position: 0 toRem(-4256);
}

.ky {
    background-position: 0 toRem(-4288);
}

.kz {
    background-position: 0 toRem(-4320);
}

.la {
    background-position: 0 toRem(-4352);
}

.lb {
    background-position: 0 toRem(-4384);
}

.lc {
    background-position: 0 toRem(-4416);
}

.li {
    background-position: 0 toRem(-4448);
}

.lk {
    background-position: 0 toRem(-4480);
}

.lr {
    background-position: 0 toRem(-4512);
}

.ls {
    background-position: 0 toRem(-4544);
}

.lt {
    background-position: 0 toRem(-4576);
}

.lu {
    background-position: 0 toRem(-4608);
}

.lv {
    background-position: 0 toRem(-4640);
}

.ly {
    background-position: 0 toRem(-4672);
}

.ma {
    background-position: 0 toRem(-4704);
}

.md {
    background-position: 0 toRem(-4736);
}

.me {
    background-position: 0 toRem(-4768);
}

.mg {
    background-position: 0 toRem(-4800);
}

.mh {
    background-position: 0 toRem(-4832);
}

.mk {
    background-position: 0 toRem(-4864);
}

.ml {
    background-position: 0 toRem(-4896);
}

.mm {
    background-position: 0 toRem(-4928);
}

.mn {
    background-position: 0 toRem(-4960);
}

.mo {
    background-position: 0 toRem(-4992);
}

.mq {
    background-position: 0 toRem(-5024);
}

.mr {
    background-position: 0 toRem(-5056);
}

.ms {
    background-position: 0 toRem(-5088);
}

.mt {
    background-position: 0 toRem(-5120);
}

.mu {
    background-position: 0 toRem(-5152);
}

.mv {
    background-position: 0 toRem(-5184);
}

.mw {
    background-position: 0 toRem(-5216);
}

.mx {
    background-position: 0 toRem(-5248);
}

.my {
    background-position: 0 toRem(-5280);
}

.mz {
    background-position: 0 toRem(-5312);
}

.na {
    background-position: 0 toRem(-5344);
}

.nc {
    background-position: 0 toRem(-5376);
}

.ne {
    background-position: 0 toRem(-5408);
}

.ng {
    background-position: 0 toRem(-5440);
}

.ni {
    background-position: 0 toRem(-5472);
}

.nl {
    background-position: 0 toRem(-5504);
}

.no {
    background-position: 0 toRem(-5536);
}

.bv,
.nq,
.sj {
    background-position: 0 toRem(-5536);
}

.np {
    background-position: 0 toRem(-5568);
}

.nr {
    background-position: 0 toRem(-5600);
}

.nz {
    background-position: 0 toRem(-5632);
}

.om {
    background-position: 0 toRem(-5664);
}

.pa {
    background-position: 0 toRem(-5696);
}

.pe {
    background-position: 0 toRem(-5728);
}

.pf {
    background-position: 0 toRem(-5760);
}

.pg {
    background-position: 0 toRem(-5792);
}

.ph {
    background-position: 0 toRem(-5824);
}

.pk {
    background-position: 0 toRem(-5856);
}

.pl {
    background-position: 0 toRem(-5888);
}

.pr {
    background-position: 0 toRem(-5920);
}

.ps {
    background-position: 0 toRem(-5952);
}

.pt {
    background-position: 0 toRem(-5984);
}

.pw {
    background-position: 0 toRem(-6016);
}

.py {
    background-position: 0 toRem(-6048);
}

.qa {
    background-position: 0 toRem(-6080);
}

.re {
    background-position: 0 toRem(-6112);
}

.ro {
    background-position: 0 toRem(-6144);
}

.rs {
    background-position: 0 toRem(-6176);
}

.ru {
    background-position: 0 toRem(-6208);
}

.rw {
    background-position: 0 toRem(-6240);
}

.sa {
    background-position: 0 toRem(-6272);
}

.sb {
    background-position: 0 toRem(-6304);
}

.sc {
    background-position: 0 toRem(-6336);
}

.sd {
    background-position: 0 toRem(-6368);
}

.se {
    background-position: 0 toRem(-6400);
}

.sg {
    background-position: 0 toRem(-6432);
}

.si {
    background-position: 0 toRem(-6464);
}

.sk {
    background-position: 0 toRem(-6496);
}

.sl {
    background-position: 0 toRem(-6528);
}

.sm {
    background-position: 0 toRem(-6560);
}

.sn {
    background-position: 0 toRem(-6592);
}

.so {
    background-position: 0 toRem(-6624);
}

.sr {
    background-position: 0 toRem(-6656);
}

.st {
    background-position: 0 toRem(-6688);
}

.sv {
    background-position: 0 toRem(-6720);
}

.sy {
    background-position: 0 toRem(-6752);
}

.sz {
    background-position: 0 toRem(-6784);
}

.tc {
    background-position: 0 toRem(-6816);
}

.td {
    background-position: 0 toRem(-6848);
}

.tg {
    background-position: 0 toRem(-6880);
}

.th {
    background-position: 0 toRem(-6912);
}

.tj {
    background-position: 0 toRem(-6944);
}

.tl {
    background-position: 0 toRem(-6976);
}

.tm {
    background-position: 0 toRem(-7008);
}

.tn {
    background-position: 0 toRem(-7040);
}

.to {
    background-position: 0 toRem(-7072);
}

.tr {
    background-position: 0 toRem(-7104);
}

.tt {
    background-position: 0 toRem(-7136);
}

.tv {
    background-position: 0 toRem(-7168);
}

.tw {
    background-position: 0 toRem(-7200);
}

.tz {
    background-position: 0 toRem(-7232);
}

.ua {
    background-position: 0 toRem(-7264);
}

.ug {
    background-position: 0 toRem(-7296);
}

.us {
    background-position: 0 toRem(-7328);
}

.uy {
    background-position: 0 toRem(-7360);
}

.uz {
    background-position: 0 toRem(-7392);
}

.va {
    background-position: 0 toRem(-7424);
}

.vc {
    background-position: 0 toRem(-7456);
}

.ve {
    background-position: 0 toRem(-7488);
}

.vg {
    background-position: 0 toRem(-7520);
}

.vi {
    background-position: 0 toRem(-7552);
}

.vn {
    background-position: 0 toRem(-7584);
}

.vu {
    background-position: 0 toRem(-7616);
}

.ws {
    background-position: 0 toRem(-7648);
}

.ye {
    background-position: 0 toRem(-7680);
}

.za {
    background-position: 0 toRem(-7712);
}

.zm {
    background-position: 0 toRem(-7744);
}

.zw {
    background-position: 0 toRem(-7776);
}

.sx {
    background-position: 0 toRem(-7808);
}

.cw {
    background-position: 0 toRem(-7840);
}

.ss {
    background-position: 0 toRem(-7872);
}

.nu {
    background-position: 0 toRem(-7904);
}

.io {
    background-position: 0 toRem(-7931);
}

.cc {
    background-position: 0 toRem(-7956);
}

.bq {
    background-position: 0 toRem(-7986);
}

.cx {
    background-position: 0 toRem(-8012);
}

.gf {
    background-position: 0 toRem(-8043);
}

.gp {
    background-position: 0 toRem(-8075);
}

.pm {
    background-position: 0 toRem(-8138);
}

.sj {
    background-position: 0 toRem(-8167);
}

.cs {
    background-position: 0 toRem(-8230);
}

.fk {
    background-position: 0 toRem(-8259);
}

.mp {
    background-position: 0 toRem(-8296);
}

.nf {
    background-position: 0 toRem(-8286);
}

.tk {
    background-position: 0 toRem(-8344);
}

.wf {
    background-position: 0 toRem(-8374);
}
