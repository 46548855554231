.journal__content {
    margin-top: $sage-extra-gap;
    margin-bottom: $sage-extra-gap;
}

.padding-wrapper {
    div + .col-r {
        @include media-breakpoint-up(lg) {
            width: 21.75rem;
            margin-left: auto;
            flex-basis: auto;
        }
    }
}
