@each $color, $value in $theme-colors {
    @include text-emphasis-variant('.text-#{$color}', $value, true);
    @include bg-variant('.bg-#{$color}', $value, true);
}

// Access colors
@each $color, $value in $access-colors {
    @include text-emphasis-variant('.text-#{$color}', $value, true);
    @include bg-variant('.bg-#{$color}', $value, true);
}

// Grays
@each $color, $value in $semanticGrays {
    @include text-emphasis-variant('.text-#{$color}', $value, true);
    @include bg-variant('.bg-#{$color}', $value, true);
    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color, $value in $socialnetworks-colors {
    .#{$color} {
        color: $value;
    }
    .bg-#{$color} {
        background-color: $value;
    }
}

@each $color, $value in $sageProducts {
    .#{$color} {
        color: $value;
    }
    .bg-#{$color} {
        background-color: $value;
    }
}
