.society-login {
    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .btn {
            font-family: $default-font-family;
            font-size: toRem(14);
        }

        .dropdown-toggle {
            padding-left: 0;
            justify-content: flex-start;
            @include media-breakpoint-up(lg) {
                justify-content: space-between;
            }
        }
    }
}
