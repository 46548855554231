@mixin triangel() {
    content: ' ';
    position: absolute;
    z-index: $zindex-behindall;
    left: 10%;
    /* stylelint-disable unit-disallowed-list*/
    transform: rotate(45deg) translateY(-10px);
    /* stylelint-enable unit-disallowed-list*/
    width: 0.625rem;
    height: 0.625rem;
    border: $default-border;
    border-width: 1px 0 0 1px;
    background-color: $white;
}
@mixin sageDropdownItem() {
    text-decoration: none;
    &:active,
    &.active {
        color: $darkest-gray;
        background-color: $dropdown-selectable-item-active-bg;
        box-shadow: $box-shadow-active;
        font-weight: $font-weight-normal;
    }
    &:hover,
    &:hover:not(.active) {
        color: $btn-color-4;
        text-decoration: underline;
        background-color: $dropdown-selectable-item-hover-bg;
        box-shadow: $box-shadow-hover;
    }
}
@mixin sageDropdownMenu() {
    flex-direction: column;
    padding: 0;
    min-width: 100%;
    font-size: inherit;
    font-weight: inherit;
    box-shadow: $box-shadow-basic-sm;
    margin: 0;
    max-height: 16.5rem;
    overflow: auto;
}
.dropdown {
    font-size: $font-size-sm;
    &-item {
        cursor: pointer;
        flex-shrink: 0;
        padding: 1rem 0.75rem;
        text-decoration: none;
        font-size: inherit;
        font-weight: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include sageDropdownItem();
    }
    .dropdown-item a {
        color: inherit;
        text-decoration: none;
    }
    &-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.75rem;
        font-size: $font-size-sm;
        font-weight: 400;
        line-height: 1.57;
        text-transform: none;
        text-decoration: none;
        color: inherit;
        padding: 0.562rem 0.75rem;
        background-color: $white;
        box-shadow: $box-shadow;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            background-color: transparent;
            color: inherit;
            box-shadow: $box-shadow;
        }
        &:hover {
            //background: $journals-categories-dropdown-item-hover-bg;
            //box-shadow: $box-shadow-hover;
        }
        @include disabledState();
        &:after {
            display: none;
        }
        span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & #{$icon-selector} {
            font-size: $font-size-base;
        }

        i[class*='_arrow']:not(.animated_arrow_down) {
            color: $dark-gray;
            font-size: $font-size-base;
            opacity: 1;
            font-weight: 600;
            line-height: 1;
            margin-left: 0.25rem;
        }
        &--link {
            border: none;
            display: inline-flex;
            color: $link;
            text-decoration: underline;
            padding-left: 0;
            padding-right: 0;
            #{$icon-selector}:last-child {
                margin: 0;
                color: $deep-gray;
            }
        }
    }

    &.show {
        .dropdown-toggle {
            border-radius: toRem(8) toRem(8) 0 0;
            &#{$icon-selector} {
                transform: rotate(180deg);
            }
        }
    }
    &-menu {
        box-shadow: $box-shadow-basic;
        border-radius: $dropdown-selectable-border-radius;
        margin: 0;

        li.menu-item {
            padding-left: 0;
            white-space: nowrap;

            &:focus {
                box-shadow: none;
            }
            a {
                color: $menu-dropdown-item-color;
                @include sageDropdownItem();
            }
        }
        a.menu-item {
            color: $menu-dropdown-item-color;
            @include sageDropdownItem();
        }

        @include media('<desktop') {
            transform: none;
            position: relative;
        }
    }

    &.dropdown--full {
        width: 100%;
        .dropdown-toggle,
        .dropdown-menu {
            width: 100%;
        }
    }
    &.stripped {
        .dropdown-toggle {
            box-shadow: none;
        }
        .dropdown-menu {
            border-radius: $menu-border-radius;
        }
        li {
            a {
                @include sageDropdownItem();
                display: block;
                padding: 0.75rem 1rem;
                color: $darkest-gray;
                text-decoration: none;
                white-space: nowrap;
            }
        }
    }
}
.dropdown-selectable {
    input {
        border-color: transparent;
    }
    &.required {
        position: relative;
        .dropdown-toggle {
            position: absolute;
            top: 0;
        }
        &:before {
            content: '';
            margin: 0;
        }
    }
    .dropdown-menu {
        @include sageDropdownMenu();
    }
    .dropdown-item {
        @include sageDropdownItem();
    }
    .dropdown-toggle {
        width: 100%;
        border: none;
        box-shadow: $box-shadow;
        background-color: $white;
        @include disabledState();
    }
    &.show {
        .dropdown-menu {
            display: flex;
            //transform: translateX(0)!important;
            box-shadow: $box-shadow;
            border-top: 0;
        }
    }
}
.bootstrap-autocomplete,
.autocomplete {
    &.dropdown-menu {
        @include sageDropdownMenu();
        min-width: 10rem;
        width: 100%;
        max-height: 19.5rem;
        @include media('<desktop') {
            position: absolute;

            &.autocomplete {
                &::-webkit-scrollbar {
                    width: 0.5rem;
                    background-color: $dark-gray;
                    border-radius: 6.25rem;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $dark-gray;
                    border-radius: 6.25rem;
                }

                &::-webkit-scrollbar-track {
                    border: 1px solid $dark-gray;
                    background-color: $white;
                    border-radius: 6.25rem;
                }
            }
        }
        &[hidden],
        &:empty {
            display: none;
            opacity: 0;
            transform: scale(0);
        }
    }
    .dropdown-item {
        @include sageDropdownItem();
        &.active,
        &[aria-selected='true'] {
            color: $darkest-gray;
            background-color: $dropdown-selectable-item-hover-bg;
            box-shadow: $box-shadow-active;
        }
    }
}
