.alphanumeric {
    &__title {
        border-radius: toRem(4);
        border: $alphanumeric-border-size $alphanumeric-border-style $alphanumeric-border-color;
        font-size: $alphanumeric-font-size;
        font-weight: $alphanumeric-font-weight;
        line-height: $alphanumeric-line-height;
        color: $alphanumeric-color;
        text-decoration: none !important;
        text-transform: capitalize;
        font-family: $sage-font-family;

        display: flex;
        width: 1.75rem;
        min-width: 0;
        height: 1.75rem;
        padding: 0;
        justify-content: center;
        align-items: center;

        &:hover,
        &.is--selected {
            background-color: $alphanumeric-bg-color--selected;
            color: $alphanumeric-color--selected;
            text-decoration: none !important;
        }

        &.is--disabled {
            background-color: $alphanumeric-bg-color--disabled;
            color: $alphanumeric-color--disabled;
            border-color: $alphanumeric-border-color--disabled;
        }
    }

    &__content {
        display: flex;
        gap: 0.25rem;
    }
}

.alphanumeric__content.acess__content {
    flex-direction: column;
    .alphanumeric__title.access__title {
        width: fit-content;
        padding: toRem(10);
        text-decoration: none;
        font-family: $sage-font-family;

        &:nth-child(2) {
            margin-top: toRem(10);
        }
    }
}

.alphanumeric__content.special-collection__content {
    .alphanumeric__title.special-collection__title {
        width: fit-content;
        padding: toRem(10);
    }
}
