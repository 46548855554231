.discipline-hubs-promotion {
    background-color: $very-light-gray;
    padding-top: toRem(40);
    padding-bottom: toRem(40);
    & .container {
        display: grid;
        row-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-areas: 'dhp-img' 'dhp-text';
        @include media-breakpoint-up(md) {
            column-gap: $grid-gutter-width;
            grid-template-columns: auto 15rem;
            grid-template-areas: 'dhp-text dhp-img';
        }
        @include media-breakpoint-up(lg) {
            column-gap: $grid-gutter-width;
            grid-template-columns: auto 23.75rem;
            grid-template-areas: 'dhp-text dhp-img';
        }
    }

    &__image {
        grid-area: dhp-img;
        img {
            display: block;
            width: 100%;
        }
    }
    &__text {
        grid-area: dhp-text;
    }
    &__list {
        margin-bottom: 1.5rem;
        font-size: 1rem;
        line-height: toRem(28);
        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
        }

        li {
            color: $deep-gray;
        }
    }
    .btn {
        width: 100%;
        @include media-breakpoint-up(sm) {
            max-width: 18rem;
        }
    }
}
