@use 'sass:math';

.discipline-hub {
    color: $disciplineHubs-color;

    &__container {
        background-color: $white;
        padding-top: $sage-extra-gap;
        padding-bottom: $sage-extra-gap;
        border-top: $default-border;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        justify-content: flex-start;

        @include media-breakpoint-up(sm) {
            gap: $moreSageProducts-gap-horizontal--sm $moreSageProducts-gap-vertical--sm;
        }
        @include media-breakpoint-up(md) {
            gap: $moreSageProducts-gap-horizontal--lg;
        }

        @include media-breakpoint-up(lg) {
            gap: $moreSageProducts-gap-horizontal--lg;
        }

        &__item {
            display: block;
            flex-basis: 100%;
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: $moreSageProducts-gap-vertical--sm;
            background-color: $white;

            /* stylelint-disable unit-disallowed-list*/

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                flex-basis: calc(50% - (#{$moreSageProducts-gap-horizontal--sm * 0.5} + 2px));
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                flex-basis: calc(33% - (#{math.div(2 * $moreSageProducts-gap-horizontal--lg, 3)} + 2px));
            }

            @include media-breakpoint-up(lg) {
                flex-basis: calc(25% - (#{3 * $moreSageProducts-gap-horizontal--lg * 0.25} + 2px));
            }

            /* stylelint-enable unit-disallowed-list*/
        }
    }
    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 1.5rem 1rem;
        color: inherit;
        text-align: center;
        text-decoration: none;

        box-shadow: $box-shadow-basic-sm;
        box-sizing: border-box;
        border-radius: $moreSageProducts-link-border-radius;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
            box-shadow: $box-shadow-basic-sm-hover;
        }
    }
    &__title {
        margin: 0;
        color: inherit;
        font-size: $font-size-md;
        font-weight: $font-weight-normal;
        line-height: toRem(28);
        span {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-family: $sage-font-family;
            min-height: calc(2 * 1.75 * 1.125rem);
        }

        &:after {
            content: '';
            display: block;
            width: $moreSageProducts-title-line-width;
            height: toRem(1);
            margin: 1rem auto 0;
        }
    }

    @each $color, $value in $sageProducts {
        &__link.#{$color} {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 0.25rem;
                background-color: $value;
                border-radius: 0.25rem 0.25rem 0 0;
            }
        }
        .#{$color} &__title:after {
            background-color: $value;
        }
    }
}
