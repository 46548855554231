.co-branding {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    &__col {
        width: 100%;

        &.organization-logo {
            flex: 1;

            .jcf-file {
                width: 100%;
            }

            .jcf-fake-input {
                border: $default-border;
                border-radius: $border-radius;
                margin-right: 1rem;
                flex: 1;
                line-height: 2.375rem;
            }
        }
    }
    @include media-breakpoint-up(md) {
        &__row {
            flex-direction: row;
            flex-wrap: nowrap;
        }
        &__col {
            width: auto;
            white-space: nowrap;
        }
    }
}
