.multi-search {
    .content-center & {
        width: 100%;
        max-width: $multi-search-max-width;
        margin: 0 auto;
    }
    .reduced-width & {
        width: 100%;
        max-width: $multi-search-max-width;

        .btn-submit {
            padding: toRem(10) toRem(20);
        }
    }
}
