.aims-scope td {
    padding: 0.5em;
}

.separator {
    table {
        font-size: $font-size-sm;
        margin-bottom: 1.5rem;
        border-radius: toRem(8);
        box-shadow: $table-box-shadow;

        @include media-breakpoint-down(md) {
            box-shadow: none;
        }

        label {
            font-size: $font-size-sm;
        }

        tr {
            &.selected {
                background-color: $table-row-selected-background;
            }
            min-height: toRem(48);
            &:last-of-type {
                td {
                    border-bottom: none;
                }
            }
        }

        th,
        td {
            color: $darkest-gray;
            padding: 1rem;
            border-bottom: toRem(1) solid $gray-2;

            p {
                font-size: toRem(14);
            }

            @include media-breakpoint-down(md) {
                border-bottom: none;

                label {
                    margin-right: 1rem;
                }
            }
        }

        thead {
            th {
                background-color: $table-head-background;
                color: $white;
                font-size: $font-size-sm;
                line-height: 1.57;
                font-weight: $font-weight-semibold;
                white-space: normal;
                &:first-of-type {
                    border-top-left-radius: toRem(8);
                }

                &:last-of-type {
                    border-top-right-radius: toRem(8);
                }
            }
        }
        #{$icon-selector} {
            &:before {
                color: $primary;
                font-size: 1.5rem !important;
            }
        }

        tbody {
            tr.expanded {
                border-top: 1px solid $gray-2;
                border-bottom: 1px solid $gray-2;

                @include media-breakpoint-down(md) {
                    border-top: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    table {
        &.expandable tbody tr {
            margin: 1rem 0 0;
            padding: 0;
            td {
                &.action {
                    + .action {
                        margin-left: 2.5rem;
                    }
                }
            }
        }
        &.expandable {
            td {
                padding: 0 !important;
                margin-bottom: 1rem !important;
                &:before {
                    content: attr(data-thead) !important;
                    float: none !important;
                    color: $darkest-gray !important;
                    margin-bottom: 0.25rem;
                    font-weight: $font-weight-semibold;
                }
                &:not(:first-child) {
                    display: flex;
                }
                &.unlabeled {
                    &:before {
                        display: none;
                    }
                }
            }
            .table__control__button {
                #{$icon-selector} {
                    &:before {
                        color: $dark-gray;
                    }
                }
            }
        }
    }
}
