.registration {
    .form-group {
        #ocupationLabel,
        #jobTitleLabel,
        #institutionCountrySelectLabel {
            & ~ select {
                width: 100%;
            }
        }
    }
}
