.content-navigation {
    $diameter: 1.25rem;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    @include media-breakpoint-up(md) {
        flex-grow: 0;
        column-gap: 2.5rem;
    }
    & &__btn {
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;

        color: $link;
        text-transform: none;
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        line-height: 1.75;
        text-decoration: none;
        flex-wrap: nowrap;
        white-space: nowrap;

        span {
            text-decoration: underline;
        }

        #{$icon-selector} {
            display: inline-flex;
            width: $diameter;
            height: $diameter;
            align-items: center;
            justify-content: center;
            border-radius: $diameter;
            border: 1px solid $primary;
            color: $primary;
            text-decoration: none;
        }
        &:hover {
            span {
                text-decoration: none;
            }

            #{$icon-selector} {
                color: $white;
                background-color: $primary;
                text-decoration: none;
            }
        }

        &--pre {
            &:before {
                content: '';
            }
        }
        &--next {
            &:after {
                content: '';
            }
        }

        &[disabled] {
            color: $dark-gray;
            span {
                text-decoration: none;
            }
            #{$icon-selector} {
                color: $dark-gray;
                border-color: $dark-gray;
            }
        }
    }
}
