.toc-container {
    &__actions {
        display: flex;
        flex-direction: column;
        border-bottom: $default-border;
        padding: 0.5rem 0;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__actions-2 {
        display: flex;
        flex-direction: column;
        border-bottom: $default-border;
        padding: 1.5rem 0;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .additional-files {
        @include media-breakpoint-up(sm) {
            justify-self: end;
            margin-left: auto;
            .dropdown {
                .dropdown-menu {
                    &.show {
                        left: auto !important;
                        right: 0 !important;
                        &:before {
                            left: unset;
                            right: 10%;
                        }
                    }
                }
            }
        }
    }
}

.table-of-content {
    margin-top: 1.5rem;
}
