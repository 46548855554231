.impact-factor {
    font-size: $font-size-xs;
    line-height: 1.667;
    text-align: start;
    margin-left: 0;
    @include media-breakpoint-up(md) {
        text-align: end;
        margin-left: auto;
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-sm;
        line-height: 1.57;
    }

    &__text {
        display: block;
        @include media-breakpoint-up(xl) {
            display: inline;
        }
    }

    &__link {
        color: $darkest-gray;
        text-decoration: underline;
        &:hover {
            color: $darkest-gray;
            text-decoration: none;
        }
    }

    &__slash {
        display: none;
        @include media-breakpoint-up(xl) {
            display: inline-block;
        }
    }
}
