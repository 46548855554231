.banner-widget {
    $this: &;
    background-color: $banner-widget-bg-color;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin: $banner-widget-default-margin;
    width: 100%;
    max-width: $banner-max-width;

    &--home,
    &--microsites {
        margin-bottom: 2.5rem;
        color: $white;
        h1,
        h2,
        h3 {
            color: inherit;
            margin: 0;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            min-height: 15.75rem;
            padding: 1.5rem 0;
            background-color: rgba(0, 0, 0, 0.7);

            @include media-breakpoint-up(md) {
                min-height: 17.5rem;
            }
            @include media-breakpoint-up(lg) {
                min-height: 20.625rem;
            }
            p {
                color: inherit;
            }
        }
    }
    &--home {
        //background-image: url('../../images/landing-page-banner.jpg') !important;
        //@include media-breakpoint-down(md) {
        //    background-image: url('../../images/landing-page-banner-tablet.jpg') !important;
        //}
        //@include media-breakpoint-down(sm) {
        //    background-image: url('../../images/landing-page-banner-mobile.jpg') !important;
        //}
        &__content {
            background-color: transparent;
            .quick-search__advanced-link {
                display: block;
                color: $primary;
                text-align: end;
                width: 100%;
                margin: 0;
                margin-top: 0.25rem;
                font-weight: $font-weight-normal;
                font-size: $font-size-base;
                @include media-breakpoint-up(lg) {
                    max-width: 31.25rem;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &--microsites {
        max-width: 100%;

        &__content {
            padding: 2rem;
        }
    }

    &__container {
        position: relative;
    }

    &__heading {
        margin: $banner-widget-heading-margin;
        font-weight: $banner-widget-heading-font-weight;
        color: $banner-widget-heading-color;

        font-size: $banner-widget-heading-font-size;
        line-height: $banner-widget-heading-line-height;

        @include media-breakpoint-up(sm) {
            margin: $banner-widget-heading-margin-md;
            font-size: $banner-widget-heading-font-size-md;
            line-height: $banner-widget-heading-line-height-md;
        }
        @include media-breakpoint-up(md) {
            margin: $banner-widget-heading-margin-lg;
            font-size: $banner-widget-heading-font-size-lg;
            line-height: $banner-widget-heading-line-height-lg;
        }

        a {
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__box {
        position: absolute;
        width: 100%;
        //transform: translateY(50%);
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &-main {
            align-items: center;
            display: flex;
            min-height: 7.625rem;
        }

        &--vertical {
            [class*='col-'] {
                flex-direction: column;
            }
        }
    }

    &--main-content {
        overflow-x: clip;
        padding: $banner-widget-default-padding;
        margin: $banner-widget-default-margin;
        @include media-breakpoint-up(md) {
            padding: $banner-widget-default-padding--lg;
            margin: $banner-widget-default-margin--lg;
        }

        #{$this}__heading {
            margin: 0.5rem 0;
            padding: 0.5rem 0;
            position: relative;
            color: $white;

            .journal-title {
                font-family: $sage-font-family;
                font-size: toRem(20);
            }

            a,
            span {
                margin: 0 !important;
                position: relative;
                z-index: $zindex-one;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: $zindex-zero;
                top: 0;
                left: -1.5rem;
                right: -1.5rem;
                bottom: 0;
                background: rgba(0, 0, 0, 0.7);
            }
            @include media-breakpoint-up(lg) {
                display: inline-block;
                max-width: 100%;
                padding-right: 18rem;
                background-image: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.7) 0%,
                    rgba(0, 0, 0, 0.7) calc(100% - 18rem),
                    transparent
                );
                &:before {
                    left: auto;
                    right: 100%;
                    width: 9em;
                }
            }
        }
    }
}

.banner-widget--article-page .banner-widget {
    .banner-widget__content-main {
        min-height: 5.625rem;
    }
}
