.citing-articles {
    p {
        margin: 0;
        + p {
            margin-top: 0.5rem;
        }
    }
    &__list {
        padding: 0;
        list-style: decimal inside;
    }
    &__sign-up {
        margin: toRem(16) auto;

        span {
            margin-left: toRem(16);
        }
    }
    &__article {
        padding-bottom: 1rem;

        &:not(:last-child) {
            border-bottom: $default-border;
            margin-bottom: 1rem;
        }

        &__title {
            display: inline;
            line-height: 1.75;
        }
        &__links {
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            justify-content: flex-start;
            margin-top: 0.5rem;

            @include media('<tablet') {
                align-items: flex-start;
                flex-direction: column;

                .btn.btn-outline-secondary {
                    width: 100%;
                }
            }

            .btn {
                flex: auto 0 0;
                width: $btn-min-width;

                &.btn-outline-primary {
                    min-width: unset;

                    @include media('<tablet') {
                        width: 100%;
                    }
                }
            }
            a {
                &:not(.btn) {
                    font-size: $font-size-sm;
                    @include media('>=tablet') {
                        + a {
                            border-left: $default-border;
                            padding-left: 1.5rem;
                        }
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
