.tab {
    $tab: &;
    &__content {
        #{$tab}__pane {
            font-size: $font-size-sm;
            padding: 0;
        }
    }
    &--flex,
    &--generic {
        #{$tab} {
            &__nav {
                min-width: 100%;
                font-size: $font-size-base;
                white-space: nowrap;
                margin-bottom: 1.5rem;
                &__item,
                li {
                    overflow: visible;
                    &:before {
                        display: none;
                        height: 0;
                        transform: none;
                        transition: height 0.3s ease-out;
                    }
                    h2,
                    h3 {
                        all: unset;
                        font-family: $sage-font-family;
                        font-size: toRem(18);

                        @include media-breakpoint-down(lg) {
                            font-family: $default-font-family;
                            font-size: toRem(14);
                        }
                    }
                }
                &__item__link,
                a,
                button {
                    font-size: $font-size-md;
                    font-weight: $font-weight-semibold;
                    line-height: 1.75;
                    text-transform: none;
                    padding: toRem(12) toRem(24) toRem(14) toRem(24);
                    color: $deep-gray;
                    border: none;
                    background: transparent;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size-sm;
                        padding: toRem(12);
                    }
                    @include border-underline-reveal('&.active', 3px);
                    &:before {
                        background: $tab-nav-active-border-color;
                        @include brand('background');
                    }

                    &.active {
                        border-bottom-width: 0;
                        border-radius: toRem(4) toRem(4) toRem(0) toRem(0);
                        color: $tab-nav-active-color;
                        background-color: $tab-nav-active-background;
                        height: 100%;
                        @include brand('color');
                    }
                    &:not(.active):hover {
                        cursor: pointer;
                        color: $tab-nav-hover-color;
                        &:before {
                            transform: translateY(0);
                            background: $tab-nav-hover-border-color;
                        }
                    }
                }
            }
        }
    }
    &.clean {
        .hide {
            display: none;
        }
    }
    .dropBlock {
        &__link {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        #{$icon-selector} {
            margin: 0;
            font-weight: normal;
        }
    }
}

.swipe {
    padding: 0;
    &__wrapper {
        &:before {
            display: none;
        }
        &:after {
            z-index: $zindex-one;
        }
        .scroll {
            padding: 0;
            overflow: auto;
        }
    }
}
