.confirmation {
    &__logo {
        display: flex;
        justify-content: center;
        color: $secondary;
        margin: 2rem 0 1rem;

        img {
            display: block;
            width: $confirmation-logo-width;
        }
    }

    &__text {
        margin: $confirmation-text-margin;
        padding: $confirmation-text-padding;
        text-align: center;

        p {
            font-size: $font-size-base;
            margin: 0;
        }

        a {
            font-weight: $font-weight-bold;
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__table {
        $this: &;
        width: 100%;

        a {
            text-decoration: underline;
        }

        th,
        td {
            vertical-align: top;
        }
        .type {
            width: 13rem;
        }
        .item {
            padding-right: 1rem;
        }

        thead {
            th {
                font-size: $confirmation-table-thead-font-size;
                font-weight: $confirmation-table-thead-font-weight;
                line-height: $confirmation-table-thead-line-height;
                //color: $confirmation-table-thead-color;
                padding: 1rem 0;
                border-bottom: 1px solid $confirmation-table-thead-border-color;
            }
        }

        tbody {
            border-top: 0;
            th,
            td {
                color: $confirmation-table-tbody-color;
                font-size: $confirmation-table-tbody-font-size;
                line-height: $confirmation-table-tbody-line-height;
                padding: 1rem 0;
                border-bottom: 1px solid $confirmation-table-tbody-border-color;
            }

            th {
                font-weight: $confirmation-table-tbody-font-weight;
            }

            .price {
                text-align: right;
                white-space: nowrap;
            }

            #{$this}__total {
                th,
                td {
                    font-size: $confirmation-table-total-font-size;
                    font-weight: $confirmation-table-total-font-weight;
                    line-height: $confirmation-table-total-line-height;
                    color: $confirmation-table-total-color;
                }

                .price {
                    color: $confirmation-table-total-price-color;
                }
            }
        }
    }
}
