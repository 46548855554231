.header {
    background: $header-top-bg;
    /* stylelint-disable unit-disallowed-list*/
    box-shadow: 0 1px 0.25rem #e4e3e3;
    /* stylelint-enable unit-disallowed-list*/

    @include media-breakpoint-down(sm) {
        margin-block-end: 0.25rem;
    }

    @include media-breakpoint-down(md) {
        padding: 0 1rem;
    }

    &__inner {
        width: 100%;
        max-width: $container-max-width;
        margin: 0 auto;
        padding: 0;

        @include media('<desktop') {
            display: flex;
            flex-direction: row-reverse;
            gap: 1.25rem;
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $grid-gutter-width * 0.5;
        }
    }

    &__quick-menu {
        position: static;
        a {
            font-size: $font-size-xxs;
            font-weight: $font-weight-normal;
            color: $header-font-color;
            flex-direction: column;
            text-decoration: none;
            @include media-breakpoint-up(sm) {
                font-size: $font-size-xs;
            }

            img {
                margin-bottom: toRem(4);
                width: toRem(20);
                height: toRem(20);
                @include media-breakpoint-up(sm) {
                    width: toRem(28);
                    height: toRem(28);
                }
                @include media-breakpoint-up(md) {
                    width: toRem(32);
                    height: toRem(32);
                }
            }

            i {
                font-size: toRem(20);
                margin-bottom: toRem(4);
                color: $primary;
                @include media-breakpoint-up(sm) {
                    font-size: toRem(28);
                }
                @include media-breakpoint-up(md) {
                    font-size: toRem(32);
                }
            }

            .header-icon {
                margin-bottom: 0.25rem;
                color: $header-icon-color;
                width: toRem(20);
                height: toRem(20);
                @include media-breakpoint-up(sm) {
                    width: toRem(28);
                    height: toRem(28);
                }
                @include media-breakpoint-up(md) {
                    width: toRem(32);
                    height: toRem(32);
                }
            }
        }

        .cart-wrapper {
            margin-right: 0;
            margin-left: 0;
            line-height: inherit;
            @include media-breakpoint-down(sm) {
                padding: 0 0.25rem;
            }

            .cartLabel {
                display: flex;
                flex-direction: column;

                .shopping-cart {
                    right: toRem(-2);
                    top: toRem(-2);
                    line-height: $font-size-sm;
                    //padding-left: 0.188rem;
                    background: $error;
                    padding: toRem(1) toRem(3);
                }
            }
        }

        .show-login {
            @include media('<desktop') {
                margin-right: 0;
            }
            @include media-breakpoint-down(sm) {
                padding: 0 0.25rem;
            }
        }

        .sign-in,
        .welcome-label {
            font-size: $font-size-xxs;

            font-weight: $font-weight-normal;
            color: $header-font-color;
            line-height: inherit;
            display: block;
            margin-left: 0;
            @include media-breakpoint-up(sm) {
                font-size: $font-size-xs;
            }
        }
        .rlist--inline {
            gap: 1rem;

            @include media-breakpoint-up('md') {
                margin-left: toRem(-7);
            }

            @include media-breakpoint-up(sm) {
                gap: toRem(32);
            }
        }
    }

    &--first-row {
        align-items: center;
        gap: 0.5rem;
        border-bottom: $default-border;
        background: $header-top-bg;
        width: 100%;
        height: $header-height;
        @include media-breakpoint-up(md) {
            height: $header-height-md;
        }

        @include media('<desktop') {
            border: 0;
            z-index: $zindex-header-first-row;
        }

        .quick-search__searchbox {
            margin-top: toRem(6);
            margin-right: 0.9rem;
        }
    }

    &--second-row {
        min-width: 2.375rem;
        height: auto;
        align-items: flex-start;
        @include media('<desktop') {
            z-index: $zindex-one;
            .generic-menu {
                z-index: $zindex-behindall;
            }
        }
    }

    &--second-col {
        flex: none;
    }

    &--first-col {
        @include media('<desktop') {
            justify-content: center;
        }
    }
    &__logo {
        &:focus {
            box-shadow: none;
        }
        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
        @include media-breakpoint-down(xs) {
            justify-content: start;
        }
        img {
            height: $logo-height;
            max-height: $logo-max-height;
            vertical-align: unset;
            margin-bottom: 0.45rem;
        }
    }
    // This overrides the ui-theme styles for institution in header
    .institution {
        &-wrapper {
            margin-left: 0.6rem;
            margin-bottom: toRem(4);
            width: $institution-width;
            @include media('<desktop') {
                display: none;
            }
        }
        img {
            display: block !important;
            max-width: unset;
            padding: 0;
        }
    }

    .secondary-container {
        position: relative;
        background: $header-top-bg;
    }
}

body[data-active='menu'] .header--first-row {
    transform: none;
}
