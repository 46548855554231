.saved-searches {
    font-size: $font-size-sm;
    .btn {
        padding: 0;
        gap: 0.5rem;
        font-family: $default-font-family;
        font-size: toRem(14);

        .icon-delete:before {
            /* stylelint-disable unit-disallowed-list*/
            width: 20px;
            height: 20px;
            /* stylelint-enable unit-disallowed-list*/
        }
    }

    #{$icon-selector} {
        &:before {
            color: $primary;
            font-size: 1.5rem !important;
        }
    }
    .saved-searches__run {
        color: $darkest-gray;
        font-weight: $font-weight-normal;
        white-space: nowrap;
    }

    .table__action {
        width: max-content;
        padding-right: toRem(2);
    }

    td.action {
        .icon-delete:before {
            color: $error !important;
        }
    }

    p:empty {
        display: none;
    }
}
