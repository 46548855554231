.w-slide {
    $this: &;
    $padding: 1.5rem;
    padding: $padding;
    &__btn {
        padding: 0;
        #{$icon-selector} {
            font-size: 1.5rem;
        }
    }
    &__head {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        height: auto;
        max-height: unset;
        padding: 0 0 1rem;
        border-bottom: 1px solid $slide-head-border-color;
        box-shadow: none;
        @include media-breakpoint-up(sm) {
            height: auto;
        }
        #{$this}__back {
            font-size: 1.5rem;
            margin: 0;
            #{$icon-selector} {
                font-size: 1.5rem;
            }
        }
    }
    &__title {
        position: relative;
        transform: unset;
        left: unset;
        max-height: unset;
        &,
        &[class*='heading-'] {
            margin: 0;
        }
        &.js--open {
            transform: unset;
            color: $deep-gray;
        }
        &:focus-visible {
            outline: none;
        }
    }
    &__content {
        position: relative;
        top: unset;
        padding: $padding;
        margin: 0 -1 * $padding;
        overflow-y: auto;
        min-width: 100%;
        height: calc(100vh - 4 *#{$padding} - #{toRem(50)});

        @include media-breakpoint-up(sm) {
            top: unset;
            height: calc(100vh - #{$padding} - #{toRem(50)});
        }
    }

    &.js--open {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: $zindex-overall;
            transform: translateX(-100%);
            /* stylelint-disable unit-disallowed-list*/
            backdrop-filter: blur(6px);
            /* stylelint-enable unit-disallowed-list*/
        }
    }
    #sectionsNavigation_Pop,
    #additionalFiles_Pop {
        &.dropdown-menu {
            position: static;
            display: block;
            box-shadow: none;
        }
    }
}
[data-slide-target] {
    i {
        margin: 0;
    }
}
