.dropBlock {
    &__holder {
        &:not(.quick-search__dropBlock) {
            li {
                padding: 0;
            }
        }
    }
}

.dropBlock__holder.js--open a:hover,
.dropBlock__holder.js--open .likeLink:hover {
    color: $btn-color-4;
}

.dropblock {
    &--tab {
        &.js--open {
            max-height: 16rem;
            overflow: auto;
        }
    }
}
