// ofh => online first header
.ofh {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    @include media-breakpoint-up(sm) {
        grid-template-columns: auto 11.5rem;
    }
    &__title {
        [class*='heading'] {
            margin: 0;
        }
    }
    &__actions {
        .btn {
            width: 100%;
        }
    }
}
