.sage-special-collections {
    text-align: left;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
    & &__title {
        font-size: $font-size-lg;
        font-weight: $font-weight-normal;
        line-height: 1.75;
        margin: 0 0 1rem;
        border-bottom: $default-border;
    }
    &__collection {
        &__title {
            font-size: $font-size-sm;
            font-weight: $font-weight-semibold;
            margin-bottom: 1rem;
        }
    }
    .btn--collapse {
        display: inline-flex;
        padding: 0;
        width: auto;
        height: auto;
        justify-content: flex-start;
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        line-height: 1.57;

        span {
            color: $darkest-gray;
        }

        [class^='icon-'],
        [class*='icon-'] {
            font-size: $font-size-base;
            margin-left: 0.5rem;
        }
    }
    &__view-more {
        text-align: center;
        .btn {
            font-size: $font-size-sm;
            line-height: 1.57;
            padding: 0;
            text-decoration: underline;
            color: $view-more-color;
            font-weight: $font-weight-semibold;
            &:hover {
                color: darken($link-text, 10%);
                text-decoration: none;
            }

            &[data-id='article-similar-more-articles'],
            &[data-id='article-recommends-more-items'] {
                font-family: $default-font-family;
                color: $view-more-color;
                text-decoration: underline;
            }
        }
    }

    li:last-of-type .teaser {
        border-bottom: none;
        padding-bottom: 0;
    }
}
