//@mixin font-face($font-family, $file-path, $font-weight, $font-style, $local1, $local2,$isInlineFont:false) ...

$openSansList: (
                ('Open Sans', './open-sans-v15-latin-300', 300, normal),
                ('Open Sans', './open-sans-v15-latin-300italic', 300, italic),
                ('Open Sans', './open-sans-v15-latin-regular', 400, normal),
                ('Open Sans', './open-sans-v15-latin-italic', 400, italic),
                ('Open Sans', './open-sans-v15-latin-600', 600, normal),
                ('Open Sans', './open-sans-v15-latin-600italic', 600, italic),
                ('Open Sans', './open-sans-v15-latin-700', 700, normal),
                ('Open Sans', './open-sans-v15-latin-700italic', 700, italic),
                ('Open Sans', './open-sans-v15-latin-800', 800, normal),
                ('Open Sans', './open-sans-v15-latin-800italic', 800, italic),
);

@mixin set-font-face($font, $dir, $weight, $style) {
  @font-face {
    font-family: $font;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: url('#{$dir}.woff2') format('woff2'), url('#{$dir}.woff') format('woff');
    unicode-range: U+000-5FF;
  }
}

@each $font, $dir, $weight, $style in $openSansList {
  @include set-font-face($font, $dir, $weight, $style);
}
