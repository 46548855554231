.serial-publication-details {
    position: relative;
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'spdActions' 'spdTitle' 'spdOther';

    @include media-breakpoint-up(md) {
        column-gap: 1rem;
        grid-template-rows: auto auto;
        grid-template-areas: 'spdTitle spdActions' 'spdOther spdOther';
    }
}
.spd {
    @extend .serial-publication-details;
    &__title {
        grid-area: spdTitle;
        .heading-m {
            margin: 0;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: end;
        grid-area: spdActions;
    }
    &__other {
        grid-area: spdOther;
        &__row {
            overflow: hidden;
        }
    }
    &__editors {
        margin-left: calc(-2 * 1rem - #{toRem(1)});
        &__editor {
            font-size: $font-size-sm;

            &:before {
                content: '';
                display: block;
                width: toRem(1);
                background: $gray;
                margin: 0 1rem;
            }
        }
    }
}
