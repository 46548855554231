.main-nav {
    a {
        text-transform: none;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        &.btn {
            padding: 0 1rem;
        }
    }
    .dropdown-toggle {
        display: flex;
        text-decoration: none;
        height: auto;
        padding: 0.5rem 0.5rem 0.75rem 0;
        cursor: pointer;

        @include media('<desktop') {
            padding: 1.125rem 0;
            margin: 0;
        }

        i,
        span {
            color: $menu-dropdown-toggle-color;
        }

        &:focus {
            text-decoration: none;
        }
    }

    .menu-parent {
        & > .dropdown-toggle {
            border: none;
            box-shadow: none;
        }
        &.modal-open {
            > .dropdown-toggle {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .drawer__nav.sub-menu__opened .menu-header {
        @include media('<desktop') {
            display: none;
        }
    }

    .menubar {
        > li {
            & + li {
                margin-left: 2rem;
            }
        }
    }

    .modal {
        position: fixed;
        z-index: $zindex-modal-backdrop;
        top: 100%;
        width: 28rem;
        min-height: 34.5rem;
        overflow: visible;
        box-shadow: $box-shadow-basic-sm;
        background-color: $white;

        &__dialog {
            width: 100%;
            height: 100%;
            max-height: 100%;
            padding: 0;
            margin: 0;
        }

        &__close {
            position: absolute;
            top: 0.125rem;
            right: 0.125rem;
        }
    }

    &.menu--res {
        z-index: $zindex-behindall;
        top: -200vh;
        width: 100%;
        height: calc(100vh - var(--header-height));
        transition: all 0.5s ease-in;

        &.js--open {
            top: var(--header-height);
            width: 100%;
        }

        &:before,
        &:after {
            display: none;
        }

        .menu-parent {
            border-bottom: $default-border;
            @include media('<desktop') {
                overflow: hidden;
                margin: 0.3rem 0;
                padding: 0;
            }

            > .dropdown-menu {
                box-shadow: none;
                &.show {
                    position: static !important;
                    transform: none !important;
                    will-change: unset !important;
                    width: 100%;
                    &:before {
                        display: none;
                    }
                }
            }

            > a {
                font-size: $font-size-base;
                font-weight: 600;
                border-bottom: none;
                padding-left: 0;
            }
        }

        .menu-item {
            padding-left: 0;
            padding-right: 0;
        }

        .prev-items {
            @include media('<desktop') {
                transform: none;
                border: 0;
            }
        }

        .drawer__nav {
            box-shadow: none;
            width: 100%;
            margin: auto;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 1.5rem 1.5rem;

            @include media-breakpoint-up(sm) {
                padding: 0 15% 1.5rem;
                margin: auto;
            }
        }

        a[data-toggle='nav'] {
            right: auto;
            flex-direction: column;
            left: 0;
            top: 0;
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $grid-gutter-width * 0.5;
            background: $white;
            z-index: $zindex-dropdown;

            .main-menu {
                &__toggle,
                &__toggle-close {
                    height: toRem(28);
                }
                &__toggle-close {
                    display: none;
                }
            }

            &.js--open {
                .main-menu {
                    &__toggle {
                        display: none;
                    }
                    &__toggle-close {
                        display: block;
                    }
                }
            }

            .label {
                font-size: 0.625rem;
                font-weight: normal;
                color: $menu-dropdown-toggle-color;
            }
        }

        ul {
            a[data-id='link'] {
                padding: 0.625rem 1rem;
                border-color: $alternative-button-border-color;
                color: $alternative-button-font-color;
                text-align: center;
                font-size: $font-size-sm;
                line-height: inherit;
            }
            a {
                text-transform: none;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                line-height: 1.57;
                color: $menu-dropdown-item-color;
            }
            li[role='listitem'] {
                a {
                    padding: 1rem 0.75rem;
                }
            }

            .mddb-navigation {
                text-align: center;
                a {
                    margin: 0 auto 0.5rem;
                    width: 15rem;
                    &.btn {
                        @extend %btn;
                        cursor: pointer;
                        font-weight: 600;
                        text-align: center;
                        vertical-align: middle;
                        user-select: none;
                        background-color: transparent;
                        border: 0.063rem solid $secondary;
                        color: $secondary;
                    }

                    &.btn-secondary {
                        @extend .btn-secondary;
                    }
                    &.btn-outline-secondary {
                        @extend .btn-outline-secondary;
                    }
                }
            }
        }

        .ux-modal-container {
            .modal {
                position: static;
                display: none !important;
                top: 0;
                width: 100%;
                height: auto;
                overflow: visible;
                box-shadow: none;
                background-color: $white;
                &:before {
                    display: none;
                }
                &.in {
                    display: block !important;
                }
            }
        }
    }
}

.animated_arrow_down:after,
.animated_arrow_down:before {
    background: $menu-dropdown-toggle-color;
    width: toRem(1);
}

.menubar > .menu-item:before,
.menubar > .menu-parent:before {
    background: none;
}
