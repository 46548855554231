.form-group {
    #institutionCountrySelectLabel {
        & ~ select,
        .is-invalid {
            width: 100%;
            @include media('>phone') {
                width: 70%;
            }
        }
    }
}
