$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-epub-path1: unquote('"\\eddd"');
$icon-epub-path2: unquote('"\\edde"');
$icon-download-outline: unquote('"\\e9013"');
$icon-seamless-access: unquote('"\\e900"');
$icon-lock_closed_not_fill: unquote('"\\e9324"');
$icon-shopping_cart: unquote('"\\e9000"');
$icon-SSS: unquote('"\\e9011"');
$icon-embedely: unquote('"\\e9022"');
$icon-email-social: unquote('"\\e9033"');
$icon-youtube: unquote('"\\e9044"');
$icon-whatsapp: unquote('"\\e9055"');
$icon-wechat-path1: unquote('"\\e9066"');
$icon-wechat-path2: unquote('"\\e9067"');
$icon-wechat-path3: unquote('"\\e9068"');
$icon-wechat-path4: unquote('"\\e9069"');
$icon-wechat-path5: unquote('"\\e906a"');
$icon-wechat-path6: unquote('"\\e906b"');
$icon-twitter: unquote('"\\e90cc"');
$icon-RSS-feed: unquote('"\\e90dd"');
$icon-pinterest: unquote('"\\e90ee"');
$icon-more: unquote('"\\e90ff"');
$icon-linkedin-square: unquote('"\\e9100"');
$icon-instagram: unquote('"\\e9111"');
$icon-facebook: unquote('"\\e9122"');
$icon-arrow_up: unquote('"\\e9133"');
$icon-section_arrow_u: unquote('"\\e9133"');
$icon-arrow_right: unquote('"\\e9144"');
$icon-arrow_r: unquote('"\\e9144"');
$icon-section_arow_rt: unquote('"\\e9144"');
$icon-previous: unquote('"\\e9155"');
$icon-next: unquote('"\\e9166"');
$icon-arrow_left: unquote('"\\e9177"');
$icon-arrow_l: unquote('"\\e9177"');
$icon-section_arrow_l: unquote('"\\e9177"');
$icon-back: unquote('"\\e9188"');
$icon-arrow_down: unquote('"\\e9199"');
$icon-arrow_d: unquote('"\\e9199"');
$icon-order: unquote('"\\e91aa"');
$icon-video-camera: unquote('"\\e91bb"');
$icon-lock-open-path1: unquote('"\\e91cc"');
$icon-lock-open-path2: unquote('"\\e91cd"');
$icon-lock-open-path3: unquote('"\\e91ce"');
$icon-subject: unquote('"\\e91ff"');
$icon-reply: unquote('"\\e9200"');
$icon-peer-review: unquote('"\\e9211"');
$icon-metrics: unquote('"\\e9222"');
$icon-metrics-alt: unquote('"\\e9222"');
$icon-locked-grey-path1: unquote('"\\e9233"');
$icon-locked-grey-path2: unquote('"\\e9234"');
$icon-locked-grey-path3: unquote('"\\e9235"');
$icon-lock-alt-path1: unquote('"\\e9266"');
$icon-lock-alt-path2: unquote('"\\e9267"');
$icon-lock-alt-path3: unquote('"\\e9268"');
$icon-lock-alt-path4: unquote('"\\e9269"');
$icon-location: unquote('"\\e92aa"');
$icon-list: unquote('"\\e92bb"');
$icon-contents: unquote('"\\e92bb"');
$icon-discover: unquote('"\\e92cc"');
$icon-cite: unquote('"\\e92dd"');
$icon-tables: unquote('"\\e92ee"');
$icon-tables-alt: unquote('"\\e92ee"');
$icon-delete: unquote('"\\e92ff"');
$icon-bin: unquote('"\\e92ff"');
$icon-bank-card: unquote('"\\e9300"');
$icon-abstracting: unquote('"\\e9312"');
$icon-full: unquote('"\\e9322"');
$icon-free: unquote('"\\e9322"');
$icon-access-full: unquote('"\\e9322"');
$icon-access-free: unquote('"\\e9322"');
$icon-check-circle-outline: unquote('"\\e9333"');
$icon-sort-descending: unquote('"\\e9344"');
$icon-sort-ascending: unquote('"\\e9355"');
$icon-hide: unquote('"\\e9366"');
$icon-eye-blocked: unquote('"\\e9366"');
$icon-eye-off: unquote('"\\e9366"');
$icon-search: unquote('"\\e9377"');
$icon-save: unquote('"\\e9388"');
$icon-minus-circle-outline: unquote('"\\e9399"');
$icon-person_outline: unquote('"\\e93aa"');
$icon-notification: unquote('"\\e93bb"');
$icon-no: unquote('"\\e93cc"');
$icon-access-no: unquote('"\\e93cc"');
$icon-access-restricted: unquote('"\\e93cc"');
$icon-like: unquote('"\\e93dd"');
$icon-incorrect: unquote('"\\e93ee"');
$icon-home_fill: unquote('"\\e93ff"');
$icon-full-screen: unquote('"\\e9400"');
$icon-folder: unquote('"\\e9411"');
$icon-filter_list: unquote('"\\e9422"');
$icon-file: unquote('"\\e9433"');
$icon-favourite: unquote('"\\e9444"');
$icon-enrole-learners: unquote('"\\e9455"');
$icon-mail-outline: unquote('"\\e9466"');
$icon-edit-mode: unquote('"\\e9477"');
$icon-edit: unquote('"\\e9477"');
$icon-download1: unquote('"\\e9488"');
$icon-cross-fill-path1: unquote('"\\e9499"');
$icon-cross-fill-path2: unquote('"\\e949a"');
$icon-cross-fill-path3: unquote('"\\e949b"');
$icon-cross-fill-path4: unquote('"\\e949c"');
$icon-cross: unquote('"\\e94dd"');
$icon-correct: unquote('"\\e92fd"');
$icon-copy-link: unquote('"\\e94ff"');
$icon-collapse-full-screen: unquote('"\\e9500"');
$icon-close: unquote('"\\e9511"');
$icon-close_thin: unquote('"\\e9511"');
$icon-clock: unquote('"\\e9522"');
$icon-cart-filled-path1: unquote('"\\e9df"');
$icon-cart-filled-path2: unquote('"\\e9e0"');
$icon-cart-filled-path3: unquote('"\\e9e1"');
$icon-cart-filled-path4: unquote('"\\e9e2"');
$icon-audio: unquote('"\\e9577"');
$icon-circle-plus: unquote('"\\e9588"');
$icon-plus-circle-outline: unquote('"\\e9588"');
$icon-share: unquote('"\\e9599"');
$icon-share-alt: unquote('"\\e9599"');
$icon-warning-1: unquote('"\\e95aa"');
$icon-warning: unquote('"\\e95bb"');
$icon-text-size: unquote('"\\e95cc"');
$icon-speech-marks: unquote('"\\e95d"');
$icon-view: unquote('"\\e95ee"');
$icon-eye: unquote('"\\e95ee"');
$icon-settings-1: unquote('"\\e95ff"');
$icon-settings: unquote('"\\e9600"');
$icon-person: unquote('"\\e9611"');
$icon-notification-active: unquote('"\\e9622"');
$icon-external-link: unquote('"\\e9633"');
$icon-export_citation: unquote('"\\e9633"');
$icon-info-active: unquote('"\\e9644"');
$icon-info: unquote('"\\e9655"');
$icon-info-alt: unquote('"\\e9655"');
$icon-folder-active: unquote('"\\e9666"');
$icon-filter-1: unquote('"\\e9677"');
$icon-file-active: unquote('"\\e9688"');
$icon-favourite-1: unquote('"\\e9699"');
$icon-email-active: unquote('"\\e96aa"');
$icon-list-view-active-path1: unquote('"\\e96bb"');
$icon-list-view-active-path2: unquote('"\\e96bc"');
$icon-list-view-active-path3: unquote('"\\e96bd"');
$icon-list-view-active-path4: unquote('"\\e96be"');
$icon-grid-view-active-path1: unquote('"\\e96ff"');
$icon-grid-view-active-path2: unquote('"\\e9700"');
$icon-grid-view-active-path3: unquote('"\\e9701"');
$icon-grid-view-active-path4: unquote('"\\e9702"');
$icon-grid-view-active-path5: unquote('"\\e9703"');
$icon-download: unquote('"\\e974"');
$icon-download-circle: unquote('"\\e974"');
$icon-star-outline: unquote('"\\e975"');
$icon-star-half: unquote('"\\e9766"');
$icon-star-full: unquote('"\\e9777"');
$icon-vantage: unquote('"\\e9788"');
$icon-grid-view: unquote('"\\e979"');
$icon-list-view: unquote('"\\e97a"');
$icon-readership: unquote('"\\e97b"');
$icon-oa: unquote('"\\e97c"');
$icon-access-open: unquote('"\\e97c"');
$icon-institution: unquote('"\\e97d"');
$icon-societies: unquote('"\\e97e"');

