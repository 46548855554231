.rhs {
    &__container {
        $rhs-container: &;
        box-shadow: $box-shadow;
        border-radius: $menu-border-radius;
        padding: 1rem;

        &:empty {
            display: none;
        }
        h5 {
            font-weight: $font-weight-normal;
            color: $h5-heading-color;
            margin-bottom: 0.5rem;
        }

        aside &,
        .col-r & {
            margin-bottom: $sage-extra-gap;
        }
    }

    &__title {
        border-bottom: $heading-bottom-border;
        margin-bottom: 1.5rem;
    }

    &__socialMedia {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
            justify-content: space-around;
        }
        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        button,
        a {
            align-items: center;
            background: transparent;
            border: none;
            color: $aside-text-color;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-size: $font-size-xxs;
            padding: 0;
            text-decoration: none;
            @include media-breakpoint-down(xs) {
                width: 33.3%;
                margin-bottom: 1rem;
            }

            img {
                width: 2.563rem;
                height: 2.5rem;
                margin-bottom: 0.3rem;
            }
        }
    }
    &__emailAlerts {
        margin-top: 1.5rem;
        color: $aside-text-color;
        font-size: $font-size-sm;

        h6 {
            font-weight: $font-weight-normal;
            color: $aside-text-color;
            margin-bottom: 0.5rem;
        }

        ul {
            list-style: none;
            padding-left: 1.25rem;

            li::before {
                content: '\2022';
                color: $primary;
                font-weight: $font-weight-bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        &--btn {
            width: 100%;
            text-transform: capitalize;
            padding: 0 1rem;
            font-size: 0.875rem;
        }

        &--link {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            color: $link-text;
            text-decoration: underline;
            @include media-breakpoint-down(md) {
                margin-top: 2rem;
            }
            @include media-breakpoint-down(xs) {
                margin-top: 0;
            }
        }
    }
}
.advanced-search {
    &__tips {
        position: relative;
        padding-bottom: 1.25rem;
        border-color: $gray;
        &__header {
            font-size: $font-size-sm;
            font-weight: $font-weight-semibold;
            line-height: 1.57;
            margin: 1rem 0 0.5rem;
        }
        &__paragraph {
            font-size: $font-size-sm;
            margin: 0 0 1rem;

            a {
                fill: $white;
                outline: $gray;
            }
        }
        &__trigger {
            display: none;
            @include media-breakpoint-up(lg) {
                position: absolute;
                cursor: pointer;
                color: $darkest-gray;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                height: 2.5rem;
                font-size: $font-size-sm;
                font-weight: $font-weight-semibold;
                background: $very-light-gray;
                border: none;
                border-top: 1px solid $light-gray;
            }

            .js--subdue-sidebar-height--shown & {
                &:before {
                    display: none;
                }
            }
        }
    }
}
.btn-collapsed {
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        padding-top: 20%;
        bottom: 100%;
        background: linear-gradient(0deg, $white 0%, rgba(255, 255, 255, 0) 100%);
    }
}
.js--subdue-sidebar-height {
    overflow: hidden;
    transition: 0.35s ease max-height;
}

.js--release-sidebar {
    $this: &;

    display: none;
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__close {
        display: none;
    }
    i {
        font-size: 1.42em;
    }
    .js--subdue-sidebar-height--shown & {
        #{$this}__close {
            display: inline-block;
        }
        #{$this}__open {
            display: none;
        }
        i {
            transform: scaleY(-1);
        }
    }
}
