.promotional-section {
    @extend .discipline-hubs-promotion;

    width: 117%;
    margin-left: -9%;
    margin-bottom: toRem(40);

    @include media-breakpoint-down(md) {
        width: 108%;
        margin-left: -4%;
    }

    &__image {
        @extend .discipline-hubs-promotion__image;

        margin: auto;

        @include media-breakpoint-only(md) {
            display: flex;
            align-items: center;
        }
    }
    &__text {
        @extend .discipline-hubs-promotion__text;
    }
    &__list {
        @extend .discipline-hubs-promotion__list;
    }
}

.promotional-section + h2 {
    margin-bottom: 0;
}
