.search-result {
    $this: &;
    position: relative;
    margin-top: 1rem;
    font-size: $font-size-base;
    i {
        display: inline-block;
        font-size: inherit;
        vertical-align: unset;
        padding: 0;
    }
    .ofh__actions i {
        color: inherit;
    }
    .issue-item {
        &__title {
            i {
                display: inline;
            }
        }
    }
    &__save {
        margin-bottom: 1rem;
        @include media-breakpoint-up(sm) {
            position: absolute;
            right: 0;
            top: -1rem;
        }
        .btn {
            font-size: toRem(14);
            width: 100%;
            @include media-breakpoint-up(sm) {
                width: 9.5rem;
            }
            @include media-breakpoint-up(lg) {
                width: 11.5rem;
            }
        }
    }
    &__follow {
        padding-bottom: 1.5rem;
        margin-bottom: 0;
        border-bottom: $default-border;
    }
    &__meta {
        &__top {
            display: flex;
            flex-direction: column;
            border-bottom: $default-border !important;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                padding-bottom: 1rem;
            }
            a {
                white-space: nowrap;
            }
            > div {
                flex: 0 !important;
                &.search__result {
                    .doSearch & {
                        flex: 0 !important;
                        border-right: none !important;
                    }
                }
            }
        }
    }
    &__results {
        font-size: $font-size-base;
        color: $deep-gray;
        .result {
            &__sep {
                color: inherit;
                &--semibold {
                    font-weight: $font-weight-semibold;
                }
            }
            &__current,
            &__count {
                font-weight: $font-weight-semibold;
                color: inherit;
            }
            &__suffix {
                font-weight: $font-weight-semibold;
                color: inherit;
                &:before,
                &:after {
                    content: '’';
                    padding: 0;
                }
            }
        }
    }
    &__no-result {
        margin: 1.5rem 0;
    }
    &__counter {
        font-size: $font-size-base;
        line-height: 1.75;
        color: $black;
    }
    .result__suffix-label {
        font-weight: $search-current-results-font-weight;
        color: $search-current-results-color;
    }
    .search__item {
        display: flex;
        flex-direction: row;
        font-size: $font-size-xs;
        line-height: 1.667;
        color: $deep-gray;
        padding-bottom: toRem(24);
        margin-bottom: toRem(24);
        border-bottom: 0.063rem solid $gray;

        .item__image {
            display: block;
            width: 5.625rem;
            padding: 0;
            margin: 0 0.875rem 0 0;

            @include media-breakpoint-up(sm) {
                width: 7.75rem;
            }
            a,
            img {
                display: block;
            }
            img {
                border: $default-border;
            }
        }

        .item__title {
            margin: 0 0 1rem;

            .hlFld-Title {
                font-family: $default-font-family;
            }
        }
    }

    .meta__row {
        pointer-events: none;
        a[href^='tel'] {
            text-decoration: none;
            color: inherit;
        }
        + .meta__row {
            margin-top: 0.5rem;
        }
        &--mobile {
            display: block;
            margin-top: 0.5rem;
            @include media-breakpoint-up(sm) {
                display: inline-block;
                margin-top: 0;
            }
        }
    }
    .meta__label {
        font-weight: $font-weight-bold;
    }
    .meta__sep {
        display: none;
        color: $gray;
        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
    &__advanced-search-wrapper {
        position: relative;
        width: 100%;
    }
    &__filter {
        padding: 0;
        margin: 0;
        background: transparent;

        .w-slide__btn {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            width: 9.5rem;
            font-size: $font-size-sm;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
            top: 3.25rem;
            margin: 0;
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }

        &.browse-publications {
            .w-slide__btn {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
    &__sort {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        font-size: $font-size-sm;
        @include media-breakpoint-up(md) {
            width: auto;
        }

        .form-control {
            text-overflow: ellipsis;
            padding-right: toRem(42);

            @include media('>=tablet') {
                width: fit-content;
            }
        }

        .dropdown {
            width: 11.5rem;
            flex: 1;

            a {
                display: block;
            }
        }
    }

    .modal__close {
        position: absolute;
        top: 0.125rem;
        right: 0.125rem;
    }

    &__special-collection {
        .item__body {
            width: 100%;
            @include media-breakpoint-down(sm) {
                width: unset;
            }

            .issue-item__header {
                margin-left: 0;

                .issue-item-access {
                    border-left: unset;
                    border-right: 1px solid $gray;

                    &:last-child {
                        border-right: unset;
                    }
                }
            }

            .item__title {
                font-family: $default-font-family;
            }
        }
    }

    & #{$this}__special-collection {
        .item__body {
            color: $darkest-gray;
            font-size: toRem(14);
            font-family: $default-font-family;

            .issue-item__header {
                margin-left: 0;
            }

            .journal-title {
                margin-top: toRem(8);
            }
        }
    }
}

.article-actionbar {
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
        width: auto;
        justify-content: flex-start;
    }
    &__btn {
        white-space: nowrap;

        &.disabled {
            color: $dark-gray;
        }
    }
}

.browse-publications {
    .loader {
        top: 50%;

        &-wrapper {
            background-color: rgba(0, 0, 0, 0.3);
            z-index: $zindex-browse-publications;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.showPublications {
    .search-result {
        &__follow {
            border-bottom: $default-border;
        }
    }
}

.advanced-search {
    &__tabs {
        border: $default-border;
        padding: 1rem;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
        list-style: none;
        padding: 0 0.25rem;
        @include media-breakpoint-up(sm) {
            padding: 0 0.625rem;
        }
    }
    &__link {
        $size: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        padding: 0;
        text-decoration: none !important;
        color: inherit !important;
        font-size: $font-size-sm;
        font-weight: 400;
        &:not(.disabled):not(.pagination__dots):not(.back):not(.next):hover,
        &.active {
            width: $size;
            height: $size;
            padding: 0;
            color: $white !important;
            background-color: transparent;
            background-image: url(../../images/pagination.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.back,
        &.next {
            &:hover {
                background: none;
                color: inherit;
            }
        }
    }
    &__dots {
        width: auto;
        padding: 0;
    }
}
.hvr-backward,
.hvr-forward {
    &:hover,
    &:focus,
    &:active {
        display: inline-flex;
    }
}

.search-page,
#collection-tabs {
    position: relative;
    .loader {
        &-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: $zindex-overall;
        }

        position: absolute;
        left: 50%;
        top: toRem(200);
        transform: translateX(-50%);
    }
}

.search-result--grid {
    border-bottom: $default-border;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'block1 block3' 'block5 block5' 'block4 block4' 'block2 block2';
    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'block1 block1 block1' 'block2 block3 block4' 'block5 block5 block5';
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'block2 block3 block4' 'block5 block5 block5';
    }

    &__block {
        display: flex;
        align-items: center;
        border-bottom: none;
        padding: 1rem 0;

        &__1 {
            grid-area: block1;
            @include media-breakpoint-up(md) {
                padding: 0;
                border: none;
            }
        }
        &__2 {
            grid-area: block2;
        }
        &__3 {
            grid-area: block3;
            justify-content: right;
            @include media-breakpoint-up(md) {
                justify-content: center;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            a {
                white-space: nowrap;
            }
        }
        &__4 {
            grid-area: block4;
            @include media-breakpoint-up(md) {
                justify-content: right;
            }
            .showMostCitedArticles &,
            .showMostReadArticles & {
                display: none;
                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }
        }
        &__5 {
            grid-area: block5;
            padding: 0;
            border: none;
        }
    }
}
.single_highlight_class {
    @include acHighlight();
}
.saveSearchMsg {
    box-shadow: $box-shadow;
    &.error,
    &.success {
        margin: 0;
        color: $white;

        a {
            color: $white;
        }

        .icon-pencil {
            margin: 0 toRem(8);
        }
    }
}
#saveSearchDialog a {
    color: $link;
    &:hover {
        text-decoration: none;
    }
}
#newSearchSaveButton {
    height: auto;
    &:hover,
    &:focus,
    &:active {
        animation: none;
    }
}

.section-topic-page {
    .search-result--grid {
        .search-result--grid__block__3 {
            width: 100%;
            .ofh__actions {
                width: 100%;
            }

            @include media('<desktop', '>820px') {
                width: 150%;
                .ofh__actions {
                    width: 150%;
                }
            }
            @include media('>=desktop') {
                width: 100%;
                .ofh__actions {
                    width: 100%;
                }
            }
            @include media('>=1280px') {
                width: 150%;
                .ofh__actions {
                    width: 150%;
                }
            }
        }

        @include media('<tablet') {
            grid-template-areas: 'block3 block3' 'block5 block5' 'block4 block4' 'block2 block2';

            //.search-result--grid__block__3 {
            //    width: 100%;
            //    margin-left: unset;
            //    .ofh__actions {
            //        width: 100%;
            //    }
            //}

            .search-result--grid__block__3,
            .search-result--grid__block__4 {
                border-bottom: 1px solid $gray;
            }
        }
    }
}
