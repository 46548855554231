.facets__wrapper {
    margin-right: unset;

    @include media-breakpoint-down(md) {
        margin-right: toRem(-15);
    }

    .jcf-range {
        .jcf-range-display {
            background-color: $primary;
            height: toRem(2);
            opacity: 1;
            border-radius: 0;
        }
        .jcf-range-handle {
            border: 2px solid $primary;
            box-shadow: unset;
        }
        .jcf-range-frequencies__slice {
            background-color: transparent;
        }
    }
}
