.article {
    &__breadcrumbs {
        display: flex;
        margin: $sage-extra-gap * 0.5 0;
        //padding: 0 $sage-extra-gap;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    &__tocHeading {
        font-size: $font-size-xs;
        display: flex;
        align-items: center;
        color: $breadcrumbs-text-color;
        text-decoration: none;
        &:hover {
            color: $breadcrumbs-text-color;
            span {
                text-decoration: underline;
            }
        }
        &:after {
            content: $icon-arrow_r;
            font-family: $custom-icomoon-font-family;
            margin: 0 0.625rem;
            font-size: $font-size-base;
            text-decoration: none;
        }
        &:last-child:after {
            content: '';
        }
        .icon-home_fill {
            color: $breadcrumbs-icon-color;
        }
    }
}
