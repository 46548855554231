.institution-admin-report {
    &__title {
        border-bottom: 4px solid $journal-color;
        width: max-content;
        margin-bottom: 0;
    }

    &__list {
        border-top: 2px solid $dark-gray;
        padding: 1.5rem 1rem;

        .date-range,
        .recipients {
            color: $black;

            &__header {
                font-weight: 600;
                padding-bottom: 1rem;
                border-bottom: 2px solid $journal-color;
                text-transform: capitalize;
            }

            &::marker {
                color: $black !important; //To override the scss in the base.scss
                font-size: toRem(28);
            }
        }

        .date-range {
            &__from,
            &__to {
                label {
                    min-width: 3.5rem;
                }

                select {
                    width: auto;
                    margin: 1rem;
                    padding: 0 1.75rem 0 0.625rem;
                }
            }
        }

        .recipients {
            &-emails,
            .all-admins-emails {
                &__label {
                    text-transform: capitalize;
                }
            }

            &-emails {
                &__input {
                    display: block;
                }
            }
        }
    }
}
