.facet {
    &--disciplines {
        .journals-categories {
            padding: 0;
            margin: 0;
            &__disciplines,
            &__subjects {
                flex-direction: column;
            }
            &__text {
                margin-bottom: 0.25rem;
                width: 100%;
            }
            &__label {
                font-size: $font-size-sm;
                line-height: 1.57;
            }
            & .dropdown {
                width: 100%;
            }
        }
    }
}
