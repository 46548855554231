.link-resolver {
    position: relative;
    &__form-disable {
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 0.5rem;
            left: toRem(312);
        }
    }
}
