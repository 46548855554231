.institutional-access {
    &__section {
        padding: 1.5rem;
        box-shadow: $box-shadow-basic;
        .js--autocomplete-element {
            background-image: url(../../images/search.svg);
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 1.5rem 1.5rem;
        }
    }
    &__lean-library {
        padding: 1rem;
        background-color: $very-light-gray;
    }
}
.recent-institutions {
    &__section {
        @extend .institutional-access__section;
    }
    &__item {
        width: 100%;
        height: auto;
        padding: 0.5rem;
        justify-content: space-between;
        border: 1px solid $primary;
        border-radius: $border-radius;
        font-size: $default-font-size;
        font-weight: $font-weight-normal;
        line-height: 1.75;
        color: $darkest-gray;
        span {
            display: flex;
            gap: 1rem;
            align-items: center;
            #{$icon-selector} {
                font-size: 1.875rem;
                padding: 0.25rem;
                border-radius: $border-radius;
                background: $primary;
                color: $white;
            }
        }
    }
}

.preference-institution {
    .form-check {
        align-items: flex-start;
    }
    &__actions {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        align-items: center;
    }
    .form-check-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
        }
    }
    .btn {
        white-space: nowrap;
    }
}

//OPEN ATHENS
.open-athens {
    .btn {
        width: 24.5rem;
        max-width: 100%;
    }
}
