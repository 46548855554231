.buyItemFooter {
    .promoCodeBlock {
        display: none;
    }
}

.checkoutProcessHeader {
    @extend %heading-m;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-bottom: 1rem;
    font-size: toRem(24);
    line-height: toRem(36);
    border-bottom: $default-border;
    &__container {
        position: relative;
        .reset-cart {
            position: absolute;
            right: 0;
            top: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.25rem;
            font-size: $font-size-sm;
            color: inherit;
            text-decoration: none;
            #{$icon-selector} {
                font-size: 1.25rem;
                color: $primary;
            }
        }
    }
}
.eCommerceCheckoutBuyingItemsWidget {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
        border: $checkout-cart-border-size $checkout-cart-border-style $checkout-cart-border-color;
        padding: 1rem;
        border-radius: $border-radius;
    }

    ul.articleDataContainer {
        padding-left: 0;

        .showMoreAndLess-wrapper-access,
        .shopping-cart-remove-item {
            .btn {
                font-size: toRem(14);
                font-family: $default-font-family;
            }
        }

        .accessTypeDropZone {
            ul li p {
                font-size: toRem(14);
            }
        }
    }
}

.eCommerceCheckoutPaymentWidget {
    .acceptTermsConditions {
        #acceptTermsConditions {
            width: toRem(22);
        }
    }
}

.checkout-expand {
    .login-form {
        .form-check {
            .label {
                font-size: toRem(14);
            }
        }
    }
}

.checkoutProcessRightCol {
    box-shadow: none;
    font-size: $font-size-sm;
    margin-top: 0;
    border: none;
    border-radius: 0;
    color: $darkest-gray;

    a:not(.btn) {
        color: $secondary;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: toRem(1);
        background-color: $gray;
    }

    @include media-breakpoint-up(lg) {
        &:before {
            display: none;
        }
    }

    .item-container {
        list-style: none;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: $default-border;

        .slist {
            margin: 0 0 0 0.5rem;
        }

        ul {
            li {
                list-style: disc;
            }
        }
    }
    .item-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1.5rem;

        color: $darkest-gray;
        margin-bottom: 0.5rem;
    }
    .item-title {
        font-weight: $font-weight-semibold;

        a {
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .item-price {
        font-weight: $font-weight-semibold;
    }
    .buyItemFooter {
        border: none;
        padding: 0;
        .totalPrice {
            float: none;
            font-size: $font-size-sm;
            font-weight: $font-weight-semibold;
            text-align: right;
            color: inherit;
        }
    }
}

.commerce-header {
    &.header {
        @extend %aside__header;
        padding-top: 0;
        font-size: toRem(24);
    }
}

.showMoreAndLess-wrapper-access {
    [data-toggle='collapse'] {
        font-weight: normal;
        padding: 0;
        gap: 0.5rem;
        justify-content: flex-start;
        span {
            color: $link;
            text-decoration: underline;
        }
        &:hover {
            span {
                text-decoration: none;
            }
        }
    }
}

.errorMsgBox {
    font-size: 0.75rem;
    background: transparent;
    border: none;
    margin: 0.25rem 0;
    color: $error;
}

.form {
    &--narrow {
        width: 100%;
        max-width: 31rem;
    }
}

.billing-address-info,
.identity-note-email {
    font-style: italic;
    font-size: $font-size-sm;
}
.billing-address-summary {
    width: auto;
    font-size: $font-size-sm;
    margin-top: 1rem;
    tbody {
        border: none;
    }
    td {
        border: none;
        line-height: 1.57;
    }
    .eCommerce-labels {
        padding: 0.25rem 0.625rem 0.25rem 0;

        .eCommerce-colon::after {
            content: none;
        }
    }
    .eCommerce-data {
        padding: 0.25rem 0.625rem;
        font-weight: $font-weight-semibold;
    }
}

.billingAddressFields {
    padding: 0;
    border: none;

    .ecomm {
        &-state {
            .state {
                #state {
                    width: 70%;
                }
            }
        }
        &-country {
            #country {
                width: 70%;
            }
        }
    }
}

.ecomm-vat {
    .form-row {
        flex-wrap: nowrap;
        gap: 1.5rem;
    }
}

.purchaseMessage.info {
    display: none !important;
}
