footer {
    background-color: $white;
}
.footer {
    position: relative;
    padding-top: 2.5rem;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1.25rem;
        background: linear-gradient(180deg, #ececec 0%, #f5f5f5 40.1%, $white 100%);
    }

    &__row {
        &__one {
            padding-bottom: $sage-extra-gap;
            border-bottom: $default-border;
            .col-12:not(:last-child) {
                margin-bottom: $sage-extra-gap;
            }
        }
        &__two {
            padding: $sage-extra-gap 0;
        }
    }

    &__heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        @include media-breakpoint-up(sm) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
        }
    }

    &__icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
        @include media-breakpoint-up(sm) {
            margin: 0;
            width: 3.375rem;
            height: 3.375rem;
        }
    }

    &__title {
        font-size: $footer-title-font-size;
        font-weight: $footer-title-font-weight;
        line-height: $footer-title-line-height;
        margin-bottom: $footer-title-margin-bottom;
        margin-top: $footer-title-margin-top;
        color: $footer-title-color;
    }

    &__nav {
        li {
            font-size: $font-size-sm;
            line-height: 1.667;
            padding-left: 0;
            &.top-gap {
                margin-top: 1rem;
            }
        }
        a {
            font-weight: $font-weight-normal;
            color: $footer-link-color;
            text-decoration: none;
            &:focus,
            &:hover {
                color: $footer-link-color-hover;
            }
            &:focus {
                text-decoration: underline;
            }
        }
        .rlist li:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }

        .rlist--inline.separator > li:not(:last-of-type) {
            display: block;
            border: none;
            padding: 0;
            margin-bottom: 0.5rem;
            @include media-breakpoint-up(sm) {
                display: inline-block;
                border-right: $default-border;
                padding-right: 1rem;
                margin-right: 1rem;
                margin-bottom: 0;
            }
        }
    }
    &__social-links {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }
        &__link {
            display: block;
            border-radius: 100%;
            @include media-breakpoint-up(sm) {
                margin-right: 1rem;
            }
            img {
                width: 2rem;
                height: 2rem;
            }
            body:not(.mouse-user) & {
                &:focus {
                    box-shadow: none;
                    img {
                        @include focused();
                    }
                }
            }
        }
    }
    &__copyright {
        p {
            margin: 0.5rem 0;
            font-size: toRem(14);
            line-height: 1.667;
        }
    }
}

.more-sage-products {
    color: $moreSageProducts-color;

    &__container {
        background-color: $white;
        padding-top: toRem(40);
        padding-bottom: $sage-extra-gap;
        border-top: $default-border;
    }
    &__title {
        font-size: toRem(24);
        font-weight: $font-weight-normal;
        margin: 0 0 $sage-extra-gap;
        color: $sageknowledgeFooter;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        justify-content: flex-start;

        @include media-breakpoint-up(sm) {
            gap: $moreSageProducts-gap-horizontal--sm $moreSageProducts-gap-vertical--sm;
        }

        @include media-breakpoint-up(lg) {
            gap: $moreSageProducts-gap-horizontal--lg;
        }

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        &__item {
            display: block;
            flex-basis: 100%;
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: $moreSageProducts-gap-vertical--sm;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                flex-basis: calc(50% - #{toRem(12)});
            }
            @include media-breakpoint-up(lg) {
                max-width: toRem(288);
            }
        }
    }
    &__link {
        position: relative;
        font-family: $default-font-family;
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem;
        color: inherit;
        background-color: $white;

        text-align: center;
        font-size: toRem(14);
        font-weight: $font-weight-normal;
        line-height: toRem(22);
        text-decoration: none;

        box-shadow: $moreSageProducts-box-shadow;
        box-sizing: border-box;
        border-radius: $moreSageProducts-link-border-radius;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.5);
        }

        .product-name {
            color: $primary;
            font-size: toRem(18);
            font-family: 'Sage Peak', sans-serif;
            line-height: toRem(28);
            &:after {
                content: '';
                display: block;
                width: $moreSageProducts-title-line-width;
                height: toRem(1);
                margin: toRem(8) auto;
            }
        }
    }

    @each $color, $value in $sageProductsFooter {
        .#{$color} .product-name:after {
            background-color: $value;
        }
        .#{$color}:focus {
            outline-color: $value;
        }
    }

    .tfs .product-name:after {
        background: linear-gradient(90deg, #00556a 0%, #792a8f 50%, #f2096d 100%);
    }
}
