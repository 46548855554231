.sage-custom-pages {
    h1 {
        @extend %heading-m;
    }
    h2 {
        @extend %heading-s;
    }
    h3 {
        @extend %heading-xs;
    }
    h4 {
        @extend %heading-xxs;
        font-weight: $font-weight-semibold;
    }
    h5 {
        @extend %heading-xxs;
        font-weight: $font-weight-semibold;
    }
    h6 {
        @extend %heading-xxs;
        font-weight: $font-weight-semibold;
    }
    p {
        font-size: $font-size-base;
        line-height: 1.75;
        margin-top: 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    table {
        width: unset;
    }
    .smallButton {
        &.redButton {
            a {
                @extend %btn;
                @extend %btn-outline-secondary;
                cursor: pointer;
                font-weight: 600;
                text-align: center;
                vertical-align: middle;
                user-select: none;
                background-color: transparent;
                border: 0.063rem solid $secondary;
                color: $secondary;
                text-transform: capitalize;
                text-decoration: none;

                &:hover {
                    color: $white;
                    background-color: $btn-color-4;
                }
            }
        }
    }
    .caps {
        text-transform: uppercase;
    }
}
