.container {
    &.egw40 {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}
.collapsing-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    justify-content: flex-start;
    gap: 1.5rem;

    > li {
        position: relative;
        display: flex;
        flex: 0 0 100%;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            flex-basis: calc(50% - (1.5rem / 2));
        }

        @include media-breakpoint-up(lg) {
            flex-basis: calc(25% - (3 * 1.5rem / 4));
        }

        &.collapse-sm:not(.show) {
            display: none;
            @include media-breakpoint-up(sm) {
                display: flex;
            }
            @include media-breakpoint-up(md) {
                display: flex;
            }
        }
        &.collapse-md:not(.show) {
            display: none;
            @include media-breakpoint-up(sm) {
                display: none;
            }
            @include media-breakpoint-up(md) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
        &.collapse-lg:not(.show) {
            display: none;
            @include media-breakpoint-up(sm) {
                display: none;
            }
            @include media-breakpoint-up(md) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}
.top-carousel-picks {
    $tcpPadding: 1.5rem;
    box-shadow: $box-shadow-basic;
    border-radius: $border-radius;
    padding: $tcpPadding;
    &__header {
        min-height: 1.5 * $h4-font-size;
    }
    .top-pick {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        color: inherit;
        text-decoration: none;
        visibility: unset !important; //To override the CSS rule from the Photo-theme
        opacity: 1 !important; //To override the CSS rule from the Photo-theme
        @include media-breakpoint-up(md) {
            gap: 1.5rem;
        }
        &__row {
            margin: 0 0 0.75rem;
            font-size: $font-size-sm;
        }
        &__description {
            font-size: $font-size-md;
        }
        &__image {
            width: 5rem;
            flex-shrink: 0;
            @include media-breakpoint-up(md) {
                width: 7.5rem;
            }
            img {
                display: block;
                width: 100%;
            }
            border: $default-border;
        }
    }
    .owl-carousel {
        padding: 0;
        .owl-dots {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            margin-top: 1.5rem;
            @include media-breakpoint-up(sm) {
                gap: 2rem;
            }
            .owl-dot {
                border: none;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 0.75rem;
                /* stylelint-disable unit-disallowed-list*/
                box-shadow: inset 0 0 0 1px $primary;
                /* stylelint-enable unit-disallowed-list*/
                background: $white;
                span {
                    display: none;
                }
                &.active {
                    background: $primary;
                }
            }
        }
        .owl-nav {
            position: absolute;
            top: toRem(-36);
            right: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            @include media-breakpoint-up(sm) {
                top: toRem(-48);
            }
            [class*='owl-'] {
                position: static;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 1.5rem;
                margin: 0;
                padding: 0;
                border: 1px solid $primary;
                background: $white;
                color: $primary;
                @include media-breakpoint-up(sm) {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 2rem;
                }
                &:hover {
                    background: $primary !important;
                    color: $white;
                }
                & #{$icon-selector} {
                    font-size: 1rem;
                    font-weight: $font-weight-normal;
                }
            }
        }
    }
}
.interested-in {
    color: $darkest-gray;

    &__list {
        &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: inherit;
            background-color: $white;

            text-align: left;
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            line-height: 1.57;

            box-shadow: $box-shadow-basic-sm;
            box-sizing: border-box;
            border-radius: 0.125rem;
            padding: 1rem;

            .btn {
                width: 100%;
                text-transform: capitalize;
            }
        }
        &__bottom {
            padding: 1rem;

            .btn {
                gap: 0.5rem;
            }
        }
    }

    &__pub {
        display: flex;
        flex-direction: column;
        font-size: $h6-font-size;
        line-height: 1.556;
        font-family: $sage-font-family;
        font-weight: $font-weight-normal;
        margin: 0;
        color: $deep-gray;
        span {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            min-height: calc(2 * 1.556 *#{$h6-font-size});
        }
        &:after {
            content: '';
            display: block;
            width: 7.5rem;
            height: toRem(1);
            margin: 0.75rem auto 1rem;
            background-color: $secondary;
        }
    }
    &__info {
        margin: 0 0 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.related-journals {
    &__link {
        display: block;
        width: 100%;
        padding: 1rem;
        text-decoration: none;
        box-shadow: $box-shadow-basic-sm;
        box-sizing: border-box;
        border-radius: $border-radius;
        &:hover,
        &:focus {
            box-shadow: $box-shadow-focus;
        }
    }
    &__image {
        width: 7.5rem;
        height: $card-size;
        display: block;
        margin: 0 auto;
        border: $default-border;
    }
    &__title {
        $line-margin-top: 1.25rem;
        $line-margin-bottom: 1rem;
        $line-height: toRem(1);
        font-size: $font-size-base;
        line-height: 1.75;
        color: $darkest-gray;
        text-align: center;
        min-height: calc(
            2 * 1.75 * #{$font-size-base} + (#{$line-height} + #{$line-margin-top} + #{$line-margin-bottom})
        );
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            width: toRem(80);
            height: $line-height;
            margin: $line-margin-top auto $line-margin-bottom;
            background-color: $primary;
        }
    }
}
.other-content {
    &__list {
        &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
            color: inherit;
            background-color: $white;

            text-align: left;
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            line-height: 1.57;

            box-shadow: $box-shadow-basic-sm;
            box-sizing: border-box;
            border-radius: $border-radius;

            .btn {
                width: 100%;
                text-transform: capitalize;
            }
        }
    }
    &__title {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: calc(2 * 1.556 * #{$h6-font-size});
        font-size: $h6-font-size;
        line-height: 1.556;
        font-weight: $font-weight-normal;
        margin: 0;
        color: $darkest-gray;
    }
    .h-line {
        content: '';
        display: block;
        width: 7.5rem;
        height: toRem(1);
        margin: 0.75rem auto;
        background-color: $secondary;
    }
    &__image {
        border-radius: 4px;

        &-container {
            position: relative;
            height: 8.75rem;
            text-align: center;
            margin-bottom: 1rem;
            @include media-breakpoint-up(sm) {
                height: 10rem;
            }
            &.video {
                &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    content: '';
                    background-image: url($icon-play-data);
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                }
            }
        }
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
    &__info {
        margin: 1rem 0 0.75rem;
    }
}
.other-content,
.related-journals,
.interested-in {
    [data-toggle='collapse'] {
        display: flex;
        font-size: toRem(14);
        line-height: toRem(22);
        width: 100%;
        margin: 1.5rem auto 0;
        text-transform: capitalize;
        @include media-breakpoint-up(sm) {
            max-width: 18rem;
        }
    }
}
