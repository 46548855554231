
$sagePeakList: (
                ('Sage Peak', './SagePeak-Thin_722507982', 100, normal),
                ('Sage Peak', './SagePeak-ThinItalic_3264660010', 100, italic),
                ('Sage Peak', './SagePeak-Extralight_449414493', 200, normal),
                ('Sage Peak', './SagePeak-ExtralightItalic_3231968441', 200, italic),
                ('Sage Peak', './SagePeak-Light_2005438004', 300, normal),
                ('Sage Peak', './SagePeak-LightItalic_3321356688', 300, italic),
                ('Sage Peak', './SagePeak-Regular_1990041360', 400, normal),
                ('Sage Peak', './SagePeak-Italic_3419560339', 400, italic),
                ('Sage Peak', './SagePeak-Medium_2922102942', 500, normal),
                ('Sage Peak', './SagePeak-MediumItalic_502633978', 500, italic),
                ('Sage Peak', './SagePeak-Semibold_2374610286', 600, normal),
                ('Sage Peak', './SagePeak-SemiboldItalic_88436426', 600, italic),
                ('Sage Peak', './SagePeak-Bold_876557244', 700, normal),
                ('Sage Peak', './SagePeak-BoldItalic_2028679448', 700, italic),
);

@mixin set-font-face($font, $dir, $weight, $style) {
  @font-face {
    font-family: $font;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: url('#{$dir}.ttf');
    unicode-range: U+000-5FF;
  }
}

@each $font, $dir, $weight, $style in $sagePeakList {
  @include set-font-face($font, $dir, $weight, $style);
}
