.login-form {
    font-size: $font-size-sm;
    [type='text'],
    [type='password'],
    [type='email'] {
        width: 100%;
    }
    .form-row {
        .col-12 {
            margin-bottom: 1rem;
        }

        .btn {
            padding: 0 1rem;
            font-size: toRem(14);
        }
    }

    .form-buttons {
        .btn {
            padding: toRem(10) toRem(20);
        }
    }
}
.form-password {
    position: relative;

    [class*=' icon-view'] {
        position: absolute;
        right: $grid-gutter-width * 0.25;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: $font-size-lg;
        color: $primary;
    }
}
[class^='icon-eye'],
[class*=' icon-eye'] {
    position: absolute;
    right: $grid-gutter-width * 0.25;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: $font-size-lg;
    color: $primary;
}
.is-invalid + [class^='icon-eye'],
.is-invalid + [class*=' icon-eye'] {
    right: toRem(45);
}

[data-id='access-create'] {
    padding: 0 1rem;
    font-size: toRem(14);
}

.raa-form {
    //max-width: $raa-form-max-width;
    //margin: 3rem auto;
    p {
        font-size: $font-size-sm;
    }

    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &__register {
        font-size: $font-size-sm;
    }
    &__reset-password {
        font-weight: $font-weight-normal;
    }

    &__note {
        font-size: $font-size-xs;
        line-height: 2em;
        font-weight: normal;
        font-style: italic;
    }

    .btn {
        &[type='submit'] {
            @extend .btn-submit;
        }
        &.dropdown-toggle {
            width: 100%;
        }
    }
}

.change-password-form {
    width: 25rem;

    .form-row {
        .btn {
            padding: toRem(10) toRem(20);
        }
    }
}

.modal {
    .raa-form {
        max-width: 100%;
        margin: 0;
    }
}

.qr-code {
    padding: 2rem;
    text-align: center;
}

@include media('>=tablet') {
    .marketable,
    .terms-section {
        padding: 0;
    }
}

.email-confirmation {
    text-align: center;
    &__icon {
        width: toRem(28);
        height: toRem(20);
    }
    &__heading {
        @extend %heading-m;
        display: block;
        color: $primary;
        margin: 1rem 0;
        font-size: toRem(24);
        font-family: $sage-font-family;
    }
}

.login-page-box {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: $box-shadow-basic;
    border-radius: $border-radius;
}
.popover-body {
    border-radius: $border-radius;
}
.password-popover {
    font-size: $font-size-sm;
    line-height: 1.57;
    color: $darkest-gray;
    border-radius: $border-radius;

    #{$icon-selector} {
        font-size: 1.5rem;
    }

    .popover-body {
        div {
            display: inline-block;
            width: fit-content;
        }

        p {
            display: inline-block;
            width: 85%;
            margin-left: toRem(10);
            vertical-align: middle;
        }
    }

    ul {
        .is-valid {
            color: $darkest-gray;
            font-size: toRem(14);

            i {
                font-size: toRem(8);
                padding: toRem(4);
                border: 1px solid $success;
                border-radius: 50%;
            }
        }

        .is-invalid {
            color: $error;
            font-size: toRem(14);

            i.icon-close {
                font-size: toRem(8);
                padding: toRem(4);
                border: 1px solid $error;
                border-radius: 50%;

                &:before {
                    color: $error;
                }
            }
        }

        li:not(:last-child) {
            margin-block-end: 0.25rem;
        }
    }

    p {
        font-size: inherit;
        line-height: inherit;
        margin: 0 0 0.5rem 0;
    }
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        #{$icon-selector} {
            font-size: $font-size-base;
        }
    }
    .is-invalid {
        color: $error;
        #{$icon-selector}::before {
            color: $primary;
        }
    }
    .is-valid {
        #{$icon-selector}::before {
            color: $success;
        }
    }
    @include media-breakpoint-down(sm) {
        position: static !important;
        transform: translate3d(0, 0, 0) !important;
        margin-top: 1rem;
        max-width: 100%;
        width: 100%;

        .arrow {
            top: toRem(-9) !important;
            right: 0.5rem !important;
            left: unset !important;
        }
    }
}

.terms-section {
    .custom-control-label {
        display: inline-block;
        &::before {
            height: 1.5rem;
            border-radius: 0.75rem;
            width: 4rem;

            @include media-breakpoint-down(lg) {
                left: -5rem;
            }
        }
    }
    .custom-switch {
        padding: 0 0 0 3.75rem;

        .custom-control-label:after {
            width: 1rem;
            height: 1rem;
            border-radius: 0.625rem;
            background-color: transparent;
            border: toRem(1.5) solid $primary;
            top: toRem(6);
            left: toRem(-58);

            @include media-breakpoint-down(lg) {
                left: -4.625rem;
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::after {
                /* stylelint-disable unit-disallowed-list*/
                transform: translateX(30px);
                /* stylelint-enable unit-disallowed-list*/
            }
        }
    }
}
.botdetect-captcha {
    .LBD_CaptchaDiv {
        margin: 0.25rem 0 !important;
    }
    #captchaCode {
        width: 100%;
        @include media-breakpoint-up(md) {
            max-width: 18rem;
        }
    }
}

.terms-message {
    a.ext_link {
        color: $secondary;
    }
}

.message {
    background: transparent;
    border: none;
    margin: 0.25rem 0;
    &.error {
        font-size: toRem(14);
        color: $error;

        & + input,
        & + div input {
            border-color: $error;
            color: $error !important;

            &:focus {
                color: $error !important;
            }
        }
    }
    &.success {
        color: $success;
    }
    &:empty {
        display: none;
    }
}
.tfa-form-container .error-msg,
.error-msg {
    font-size: 0.75rem;
    background: transparent;
    border: none;
    margin: 0.25rem 0;
    color: $error;
    padding: 0;
}
.tfa-form-container {
    max-width: unset;
    .js__pinCode {
        input[type='checkbox'] {
            display: block;
            & + label {
                span {
                    &:empty {
                        display: none;
                    }
                }
            }
        }
    }
}

// PROFILE FORM
[name='userProfileForm'],
[name='billing'] {
    width: 100%;
    max-width: 44.625rem;
    fieldset {
        border: none;
        padding: 0;
        .form-group {
            .link {
                color: $secondary;
                font-size: 1rem;
            }
        }

        .actions {
            .btn {
                font-size: toRem(14);
                font-family: $default-font-family;
            }
        }
    }
    .form-control,
    .input-group,
    .dropdown-selectable {
        @include media-breakpoint-up(md) {
            width: 70%;
        }
    }
    .input-group {
        .dropdown-selectable {
            width: 100%;
        }
    }
}

[name='billing'] {
    .actions {
        .btn {
            padding: toRem(10) toRem(20);
        }
    }
}

[name='customAPGPayment'] {
    .form-buttons {
        .btn {
            padding: toRem(10) toRem(20);
        }
    }
}

[name='userProfileForm'] {
    fieldset .actions {
        .btn {
            font-size: toRem(16);
            font-family: $sage-font-family;
            padding: toRem(10) toRem(20);
        }

        a[href*='delete'] {
            font-size: toRem(14);
            font-family: $default-font-family;
            gap: toRem(5);
        }
    }
}

.delete-account-form-widget {
    .delete__account__form {
        .btn {
            padding: 0 1rem;
            font-size: toRem(14);
        }
    }
}

.profile-emails__container {
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .message {
        width: 100%;
    }
    button[data-id='personal-details-change-password'] {
        font-size: toRem(14);
        font-family: $default-font-family;
    }
}
.formDropZone {
    & + div.actions {
        padding: 0;
        margin-top: toRem(8);
        margin-bottom: toRem(24);

        .btn {
            padding: toRem(10) toRem(20);
        }
    }
}
