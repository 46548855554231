.alert {
    &--floating {
        position: fixed;
        z-index: $zindex-alert;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-start;
        align-items: center;

        background: $deep-gray;
        color: $white;
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        line-height: 1.571;

        border-radius: $alert-border-radius;
    }
    &--success {
        &::before {
            content: #{$icon-check-circle-outline};
            display: inline-block;
            font-family: '#{$custom-icomoon-font-family}' !important;
            font-size: 1.5rem;
        }
    }
}

.local-storage-error {
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 2.75rem;
    margin-top: 1rem;
    background-color: $very-light-gray;
    font-size: $font-size-sm;
    line-height: 1.571;

    .icon-info {
        position: absolute;
        left: 0.75rem;
        top: 0.75rem;
    }
    #{$icon-selector} {
        font-size: 1.5rem;
    }
    button {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        color: $darkest-gray;
    }

    &:before {
        content: '';
        position: absolute;
        top: toRem(-20);
        left: 0;
        width: 0;
        height: 0;
        border: 0.625rem solid transparent;
        display: inline-block;
        border-bottom: 0.625rem solid #f7f7f7;
    }
}
