.article-actionbar {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: $font-size-sm;
    &__check-all {
        label {
            font-size: inherit;
            line-height: normal;
            white-space: nowrap;
        }
    }
    &__btns {
        display: flex;
        gap: 0.5rem;
    }
}
