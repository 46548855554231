.issue-item {
    $this: &;
    width: 100%;
    &__checkbox-container {
        grid-area: issuecheckbox;
        margin: 0;
        padding-top: 0.125rem;
        grid-column: 1;
        padding-right: 1rem;
    }

    &__container {
        width: 100%;
        display: grid;
        row-gap: 0.75rem;
        grid-template-columns: 1fr;
        grid-template-areas: 'issuecheckbox issueHeader' 'issuecheckbox issueImage' 'issuecheckbox issueBody' 'issuecheckbox issueActions';
        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr minmax(0, 100%);
            grid-template-areas: 'issuecheckbox  issueHeader' 'issuecheckbox  issueBody' ' issueActions issueActions';
        }
        &:has(.issue-item__image) {
            grid-template-columns: 1fr;
            grid-template-areas: 'issuecheckbox issueHeader' 'issuecheckbox issueImage' 'issuecheckbox issueBody' 'issuecheckbox issueActions';
            @include media-breakpoint-up(sm) {
                grid-template-columns: 0fr minmax(0, 24%);
                grid-template-areas: 'issuecheckbox issueImage issueHeader' 'issuecheckbox issueImage issueBody' 'issuecheckbox issueActions issueActions';
            }
            #{$this}__image {
                grid-column-start: unset;
            }
            #{$this}__header {
                grid-column-start: unset;
            }
            #{$this}__body {
                grid-column-start: unset;
            }
        }
    }

    &__image {
        grid-area: issueImage;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: $card-size;
            margin-right: 1rem;
        }
        img {
            width: 100%;
            object-fit: contain;
            border-radius: $border-radius;
        }
    }

    &__header {
        grid-area: issueHeader;
        position: relative;
        display: block;
        margin-bottom: 0.75em;
        margin-left: 2.5rem;
        font-size: $font-size-sm;
        color: $darkest-gray;
        line-height: 1.57;
        letter-spacing: normal;

        #{$this}-access {
            #{$icon-selector} {
                font-size: 1.5rem;
                position: absolute;
                left: -2.5rem;
            }
        }

        > span {
            display: inline;
            padding-right: 1rem;
            &:after {
                display: none;
            }

            + span {
                padding-left: 1rem;
                border-left: $default-border;
            }
        }
    }

    &__body {
        grid-area: issueBody;
    }

    &__row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;
        font-size: $font-size-sm;
        line-height: 1.57;
        margin: 0.5rem 0;

        span {
            &:after {
                display: none;
            }

            + span {
                padding-left: 1rem;
                border-left: $default-border;
            }
        }
    }

    &__title {
        a {
            display: block;
        }
        h5 {
            font-weight: $font-weight-normal;
        }
    }
    &__heading {
        display: block;
        color: inherit;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
        font-family: $default-font-family;
        line-height: 1.75;
        margin-bottom: 0.75em;
    }

    &__authors {
        font-size: $font-size-sm !important;
        #{$this} & {
            color: $darkest-gray;
            a:not(.read-more):not(.read-less) {
                color: $darkest-gray;
                text-decoration: none;
                &:hover {
                    color: darken($darkest-gray, 10%);
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                display: inline-flex;
                align-items: center;
                &:last-child {
                    display: inline-flex;
                    flex-grow: 1;
                }
                #{$icon-selector} {
                    color: $orcid-color;
                }
            }
        }
        .orcid-id {
            margin-left: 0.25rem;
            display: inline-flex;
            &:before {
                background-image: url(../../images/id.svg);
                content: '';
                display: block;
                height: 1rem;
                width: 1rem;
            }
        }
        .read-less,
        .read-more {
            color: $link-text;
            display: flex;
            align-items: center;
            font-size: $font-size-sm;
            & #{$icon-selector} {
                color: $deep-gray;
            }
            &:hover {
                color: darken($link-text, 10%);
                text-decoration: none;
            }
        }
        .read-more {
            width: 100%;
            justify-content: space-between;
            &__label {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 0.5em;
            }
        }
        .read-less {
            width: 100%;
            margin-left: 1rem;
            justify-content: flex-end;
        }
    }

    &__abstract {
        margin: 0.5rem 0;
        &__content {
            margin: 1rem 0;
            padding: 1.5rem;
            background-color: $very-light-gray;
            font-size: $font-size-base;
            line-height: 1.75;
            border-radius: toRem(2);
        }
        &__heading {
            font-size: $font-size-lg;
            line-height: 1.6;
            margin: 0 0 0.5rem;
        }
        .btn--icon {
            font-family: $default-font-family;
            font-size: toRem(14);

            #{$icon-selector} {
                color: $gray;
            }
        }
    }

    &__actions {
        grid-area: issueActions;
        grid-column-end: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 0.5rem;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 0.5rem 1.5rem;
        }
        .btn {
            font-size: toRem(14);
            line-height: 1.66;
            width: 100%;
            @include media-breakpoint-up(sm) {
                width: auto;
                min-width: 11.5rem;
            }
            .icon-lock {
                color: inherit;
            }
        }
    }
    &__badges {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
    }

    &--checkbox {
        gap: 1rem;
    }

    &-access {
        display: inline-flex;
        gap: 1rem;
        align-items: center;

        #{$icon-selector} {
            font-size: 1.5rem;
        }
    }
    &--toc {
        #{$this}__content {
            display: grid;
        }
    }
}

body {
    &.restricted-abstracts {
        .toc-page,
        .search-page {
            .issue-item {
                &__container {
                    width: 100%;
                    display: grid;
                    row-gap: 0.75rem;
                    grid-template-columns: 1fr;
                    grid-template-areas: 'issuecheckbox issueHeader' 'issuecheckbox issueImage' 'issuecheckbox issueBody' 'issuecheckbox issueActions';
                    @include media-breakpoint-up(sm) {
                        grid-template-columns: 1fr minmax(0, 100%);
                        grid-template-areas: 'issuecheckbox  issueHeader' 'issuecheckbox  issueBody' ' issueActions issueActions';
                    }
                    &:has(.issue-item__image) {
                        .issue-item__image {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
