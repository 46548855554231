.uploadImageButton .jcf-file,
.jcf-file {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 20.375rem;
    padding: 0;
    margin: 0;
    border: none;
    font-size: $font-size-sm;
    & .jcf-upload-button {
        @extend %btn;
        @extend %btn-outline-secondary;
        position: static;
        font-size: $font-size-sm;
        line-height: 1.667;
        padding-top: 0.562rem;
        padding-bottom: 0.562rem;
        width: $btn-min-width;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 0.063rem solid $secondary;
        color: $secondary;
        &:hover {
            background-color: $light-secondary;
            color: $secondary;
        }
    }
    &.jcf-focus {
        .jcf-upload-button {
            box-shadow: $box-shadow-focus;
        }
    }
}
.uploadImageButton .jcf-fake-input,
.jcf-fake-input {
    padding: 0;
    font-size: $font-size-sm;
    border-right: 1px solid #ccc;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    overflow: hidden;
    width: 7.125rem;
}

.uploadImageButton {
    .jcf-button-content {
        padding: 0;
    }
    .jcf-fake-input {
        padding: 0;
    }
}

@if ($has-jcf-range) {
    $JCF-RANGE-HEIGHT: 0.125rem;
    $JCF-RANGE-HANDLE_SIZE: 1rem;
    .jcf-range {
        margin: 0 $JCF-RANGE-HANDLE_SIZE * 0.5 0 0;
        width: calc(100% - #{$JCF-RANGE-HANDLE_SIZE * 0.5});
        &-frequencies {
            height: $JCF-RANGE-HEIGHT;
            &__slice {
                height: $JCF-RANGE-HEIGHT;
                background: unset;
            }
        }
        & &-display {
            background: $primary;
            opacity: 1;
            height: toRem(2);
        }

        & &-wrapper {
            background: $gray-2;
            border-radius: 0;
        }
        & &-track {
            height: $JCF-RANGE-HEIGHT;
            margin-right: $JCF-RANGE-HANDLE_SIZE * 0.5;
        }
        & &-handle {
            width: $JCF-RANGE-HANDLE_SIZE;
            height: $JCF-RANGE-HANDLE_SIZE;
            border-radius: $JCF-RANGE-HANDLE_SIZE;
            margin: 0;
            top: toRem(-7);
            border: 2px solid $primary;
            box-shadow: unset;
        }
        &.jcf-focus {
            .jcf-active-handle {
                margin: 0;
            }
        }
    }
}
