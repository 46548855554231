.or-separator {
    position: relative;
    height: toRem(1);
    margin: 2rem 0;
    background: $gray;
    font-family: $sage-font-family;
    &__label {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0 1rem;
        background-color: $white;
        color: $deep-gray;
        font-size: $font-size-lg;
        line-height: 1.6;

        .or-separator--short & {
            &:before,
            &:after {
                content: '-';
                margin: 0 0.25rem;
            }
        }
    }

    &--short {
        height: 2rem;
        margin: 0;
        background: transparent;
    }
}
