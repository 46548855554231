.burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $burger-width;
    color: $darkest-gray;
    text-decoration: none;

    &:hover {
        color: inherit;
    }

    #{$icon-selector} {
        color: $deep-gray;
        font-size: $burger-icon-font-size;
    }
    &__label {
        font-size: $burger-label-font-size;
        font-weight: $burger-label-font-weight;
        line-height: $burger-label-line-height;
    }
}
