.publication-content {
    &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        @include media-breakpoint-up(sm) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
        [class*='heading-'] {
            margin: 0;
        }
    }
    &__backlink {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        font-size: $font-size-sm;

        #{$icon-selector} {
            color: $dark-gray;
        }
    }
    &__section {
        &:not(:last-child) {
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: $default-border;
        }
    }
    &__download {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        span {
            text-decoration: underline;
        }
        &:hover {
            span {
                text-decoration: none;
            }
        }

        #{$icon-selector} {
            $diameter: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: $diameter;
            height: $diameter;
            border-radius: $diameter;
            border: 1px solid $primary;
            color: $primary;
        }
    }

    .icon-download1 {
        color: $primary;
        margin-inline-end: 0.25rem;
    }
}
