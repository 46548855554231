.dropzone {
    min-width: 3rem;
    min-height: 6rem;
    background: $gray-100;
    border: 3px dashed $gray-300;

    &:after {
        display: block;
        padding: 0.5rem;
        content: attr(data-name);
        font-size: 0.75rem;
        line-height: normal;
        color: rgb(255, 0, 0);
    }
}

#pb-editor {
    &.pb-role-designer {
        .pb-dropzone {
            border: 1px dashed rgba(0, 0, 255, 0.8);
            box-sizing: border-box;
            min-width: 3rem;
            min-height: 3rem;
            position: relative;
            padding: 0.625rem 0 0 !important;
            &:after {
                display: block;
                font-size: 0.75rem;
                line-height: normal;
                padding: 0.25em;
                color: rgb(255, 0, 0);
                content: attr(data-pb-dropzone);
            }
        }
    }
    &.pb-role-editor:not(.pb-role-designer),
    &.pb-dropzone-mode.pb-role-editor:not(.pb-role-designer) {
        .pb-dropzone {
            border: none;

            &:after {
                content: '';
            }
        }
    }
}
