// This needs some refactoring
@use 'sass:math';

.taxonomy-index {
    $_this: &;

    margin-top: toRem(-24);

    #{$_this} &__header {
        margin-bottom: 1rem;
    }
    h3 {
        font-size: toRem(20);
        display: inline-block;
        color: $link-text;
        text-decoration: underline;
        margin-bottom: toRem(8);

        &:hover {
            text-decoration: none;
        }
    }
    ul {
        list-style: unset;
    }
    a {
        border-top: none;
        padding-left: 0;
    }

    &__list {
        padding-left: 0;
        li {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-top: 0.063rem solid $gray;

            .taxonomy-meta {
                order: -1;
                font-size: toRem(14);
            }

            .count {
                span {
                    margin-right: toRem(6);
                }
            }
        }

        .expandable-list {
            button {
                span {
                    margin-right: toRem(6);
                }

                i {
                    margin-right: toRem(4);
                    min-width: toRem(23);
                }
            }

            &__body {
                padding: 0;
            }
        }
    }

    .count,
    .last--published,
    .submission-status,
    .submission-deadline {
        display: inline-block;

        .pipe {
            margin-left: toRem(2);
            margin-right: toRem(8);
        }
    }

    .submission-status,
    .submission-deadline {
        span:first-child {
            margin-right: toRem(6);
        }
    }

    .collection {
        &-meta {
            line-height: toRem(28);

            div {
                margin-bottom: toRem(8);
            }

            a {
                font-size: 1rem;
            }
            .special-link {
                a {
                    padding-left: toRem(6);
                    padding-right: toRem(2);
                    display: inline-block;
                }
            }
        }
        &-editors {
            font-style: italic;
            margin-bottom: toRem(12);
        }
    }

    .expandable-list {
        h3 {
            color: unset;
            text-decoration: none;
        }

        &__body {
            a {
                padding: toRem(7) 0 0 0;
            }

            li {
                list-style: none;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-left: 1rem;
                display: flex;
                flex-direction: column;

                .collection {
                    &-meta {
                        line-height: toRem(28);

                        div {
                            margin-bottom: toRem(8);
                        }

                        a {
                            font-size: 1rem;
                        }
                    }
                    &-editors {
                        font-style: italic;
                        margin-bottom: toRem(12);
                    }
                }
            }

            .taxonomy-child__heading {
                order: 1;

                h3 {
                    font-size: toRem(18);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        &.js--open {
            button.expand-all {
                i.icon-arrow_down {
                    transform: rotate(180deg) !important;
                }
            }
        }
        & button.expand-all {
            margin-bottom: 0 !important;
            margin-left: auto;
            padding-right: 0;

            i {
                padding: 0 !important;
                font-size: 1rem;
                font-weight: 900;
            }
        }
        &__count {
            margin-left: 0;
        }
    }

    &-card {
        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            flex-basis: calc(50% - #{$moreSageProducts-gap-horizontal--sm * 0.5});
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            flex-basis: calc(33% - #{math.div(2 * $moreSageProducts-gap-horizontal--lg, 3)});
        }

        .discipline-hub__link {
            word-break: break-word;
        }
    }
}

.sort-collection {
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
}
