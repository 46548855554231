.facet {
    border: none;
    padding: 0;
    margin-bottom: 0;

    + .facet {
        margin-top: 1.5rem;
        margin-bottom: 0;
        padding-top: 1.5rem;
        border-top: $default-border;
    }

    &__counter {
        width: max-content;
        color: $darkest-gray;
    }

    &s__wrapper {
        padding: $facet-padding-y $facet-padding-x;
        box-shadow: $box-shadow;
        border-radius: $menu-border-radius;

        &:empty {
            display: none;
        }
    }

    &__title {
        @extend .aside__header;
        border: none;
        padding: 0;
        margin: 0 0 1rem;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            .facet__title {
                margin-bottom: 0;
            }
        }
    }
    &__list {
        li {
            padding-bottom: 1rem;
        }
        a {
            text-decoration: none;
        }
    }

    &__content {
        &.accordion__content {
            padding: 0;
            margin: 0;
        }
    }
    i#{$icon-selector} {
        padding: 0;
        margin-left: 0.75rem;
    }
    &__reset {
        padding-top: 1.5rem;
        border-top: $default-border;

        .btn {
            width: 100%;
        }
    }

    &__link {
        i.icon-oa {
            color: $access-open;
            font-size: 1.25rem;
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    .show-more {
        color: inherit;
        text-decoration: none;
        text-transform: none;
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        .more-count {
            margin-left: 0.25rem;
            display: inline;
        }
    }
}

.applied-facets {
    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.75rem;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        gap: 0.625rem;

        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        border: toRem(1) solid $gray-2;
        border-radius: toRem(4);
        background-color: $very-light-gray;
        min-height: 2em;
        padding: toRem(6) toRem(12);
    }
    &__remove {
        color: inherit;

        i#{$icon-selector},
        #{$icon-selector} {
            margin: 0;
            font-size: $font-size-base;
        }
    }
    &__clear {
        font-size: $font-size-sm;
    }
}
