.section__header {
    font-size: toRem(18);
    color: $darkest-gray;
    margin-top: 1rem;
}

.kbart {
    &-token {
        &-providers {
            label {
                font-size: 1rem;
            }

            .input-group {
                label {
                    font-size: toRem(14);
                    cursor: not-allowed;
                    display: inline-flex;
                    color: $select-disabled-background;

                    input[type='radio'] {
                        margin-bottom: 0 !important;
                        cursor: pointer;
                        appearance: none;
                        background-color: $white;
                        vertical-align: middle;
                        font: inherit;
                        color: currentColor;
                        width: 1.4rem;
                        height: 1.3rem;
                        border: 1px solid currentColor;
                        border-radius: 50%;
                        transform: translateY(-0.075em);
                        display: grid;
                        place-content: center;

                        &::before {
                            content: '';
                            width: 0.85em;
                            height: 0.85em;
                            border-radius: 50%;
                            transform: scale(0);
                            transition: 120ms transform ease-in-out;
                            box-shadow: inset 1em 1em $black;
                        }

                        &:checked::before {
                            transform: scale(1);
                        }
                    }

                    &.noToken {
                        cursor: pointer;
                        color: inherit;
                    }
                }
            }
        }

        &__btn {
            margin: 1rem 0 2rem;
            padding: 0 2rem;
        }

        &_new {
            white-space: nowrap;
            border-bottom: 1px solid $gray;

            @include media('<tablet') {
                .provider {
                    position: relative;
                }

                .to_hide:not(.token-number) {
                    width: auto;
                    margin-right: 1rem;
                }

                #KbartTooltip {
                    top: auto;
                    bottom: 1.5rem;
                    right: calc(100% - 18rem);
                }
            }

            .tooltip {
                position: relative;
                display: inline-block;
                opacity: 1;
                padding: 1rem;

                &__body {
                    visibility: hidden;
                    width: toRem(120);
                    background-color: $white;
                    color: $black;
                    text-align: center;
                    box-shadow: 0 toRem(1) toRem(2) rgba(0, 0, 0, 0.298);
                    padding: toRem(5);
                    position: absolute;
                    z-index: $zindex-kbart-tooltip;
                    top: 1.5rem;
                    border-radius: 4px;
                    right: calc(100% - 3rem);
                    line-height: normal;
                    transform: translateX(-50%);
                    opacity: 0;
                    transition: opacity 0.2s;

                    &.show {
                        visibility: visible;
                        opacity: 1;
                        transition: opacity 0.2s;
                    }
                }
            }

            .btn {
                padding: 0 2rem;
            }
        }

        &_table {
            padding-bottom: 3rem;

            @include media('<tablet') {
                overflow-x: auto;
            }

            thead {
                background-color: $very-light-gray;
                border-bottom: 1px solid $primary;

                tr {
                    th {
                        font-size: toRem(14);
                        font-weight: 600;
                    }
                }
            }

            @include media('<tablet') {
                .expandable {
                    .expanded {
                        .to_hide {
                            display: none;
                        }
                    }
                }
            }

            & .ux-modal-container {
                .modal {
                    z-index: $zindex-kbart-modal;
                    white-space: normal;

                    &__header {
                        padding-bottom: 1.5rem;
                        h2 {
                            font-size: toRem(20);
                        }
                    }

                    &__dialog {
                        border-radius: 4px;
                        top: 25%;
                        max-width: 28rem;

                        @include media('<tablet') {
                            height: 30%;
                        }
                    }

                    &__footer {
                        border-top: none;
                        display: flex;
                        gap: 1.5rem;

                        a.btn {
                            padding: 0 2.5rem;
                        }

                        button.btn {
                            padding: 0 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
