.article-aside-grid {
    margin: $sage-extra-gap 0;
    display: grid;
    //row-gap: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'ad1' 'mid' 'ad2' 'below';

    @include media-breakpoint-up(md) {
        column-gap: $grid-gutter-width;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: 'ad1 ad2' 'mid mid' 'below below';
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 'ad1' 'mid' 'ad2' 'below';
    }

    &__block-1 {
        grid-area: ad1;
        text-align: center;

        .pb-ad {
            margin-bottom: toRem(20);
        }
    }
    &__block-2 {
        grid-area: mid;

        .sage-special-collections,
        .temis-related {
            margin-bottom: toRem(20) !important;
        }

        .pb-ad {
            margin-bottom: toRem(20);
        }
    }
    &__block-3 {
        grid-area: ad2;
        text-align: center;
        .pb-ad {
            margin-bottom: toRem(20);
        }
    }
    &__block-4 {
        grid-area: below;
        text-align: center;
    }
    .rhs__container {
        margin-bottom: 0;
        h2 {
            margin: 0 0 1rem;
            font-size: 1rem;
            #{$icon-selector} {
                color: inherit;
            }
        }
    }
    .bs-accordion__control,
    .accordion-tabbed__control {
        font-size: $h6-font-size;
        font-weight: $font-weight-normal;
        line-height: 1.75;
        padding: 0;
        margin: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        color: inherit;
        box-shadow: none;
        min-height: auto;
        i {
            display: block;
        }
    }
    .bs-accordion__content {
        &.show {
            padding: 0;
            box-shadow: none;
            color: inherit;
        }
    }
    .pb-las,
    .pb-ad {
        margin: $article-ads-margin;
    }
}
