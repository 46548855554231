.form-group {
    #ocupationLabel,
    #jobTitleLabel {
        & ~ select,
        .is-invalid {
            width: 100%;
            @include media('>phone') {
                width: 70%;
            }
        }

        & + select:disabled {
            background-color: $select-disabled-background;
        }
    }
}
