.podcasts,
.editorsChoice {
    table {
        border: none;
        border-collapse: collapse;
        & tbody {
            &:empty {
                display: none;
            }
        }
        & tr {
            &:empty {
                display: none;
            }
            &.spacer {
                display: table-row;
                border-bottom: $default-border;
            }
        }

        & td {
            padding: 1rem 0 1rem 1rem;
            font-size: $font-size-base;
            line-height: 1.75;
            vertical-align: middle;
            &:first-of-type {
                width: 1.5rem;
                padding-left: 0;
            }
        }
        p {
            font-size: $font-size-sm;
            margin: 0;
            color: $deep-gray;
        }
        img {
            display: block;
        }
    }
}

.podcasts {
    table {
        border: none;
        border-collapse: collapse;
        tbody + tbody {
            &:before {
                display: block;
                content: '';
                height: 1.5rem;
            }
        }
        tr {
            &.spacer {
                &:before {
                    display: block;
                    content: '';
                    height: 1.5rem;
                }
            }
        }
        td {
            padding-top: 0.375rem;
            padding-bottom: 0.375rem;
            &:first-of-type {
                vertical-align: middle;
                padding-top: 0.625rem;
            }
        }
        .smallButton {
            a {
                display: inline-flex;
                align-items: center;
                height: 2rem;
                padding-left: 2.375rem;
                background-image: url('../../images/download-outline.svg');
                background-size: 1.75rem;
                background-position: 0 50%;
                background-repeat: no-repeat;
            }
        }
    }
    &__download {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        margin-top: 1rem;
        span {
            text-decoration: underline;
        }
        &:hover {
            span {
                text-decoration: none;
            }
        }
    }
    #{$icon-selector} {
        border-radius: 100%;
        border: 1px solid $primary;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $black;
    }
}

.videos {
    h3 {
        @extend %heading-xs;
    }
}
.journalVideo {
    &Section {
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;
        border-bottom: $default-border;
    }
    &Container {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        column-gap: 1.5rem;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        &Left {
            width: 100%;
            flex-basis: 100%;
            @include media-breakpoint-up(md) {
                $container-left-width: 15rem;
                flex-shrink: 0;
                flex-basis: $container-left-width;
                width: $container-left-width;
                height: 8.5rem;
            }
        }
        &Right {
            a {
                font-size: $font-size-base;
            }
        }
    }
}

.moreFromButton {
    @extend .browse-by-buttons;
    &.smallButton {
        a {
            @extend %btn;
            @extend .btn-secondary;
            cursor: pointer;
            font-weight: 600;
            text-align: center;
            vertical-align: middle;
            user-select: none;
            background-color: $secondary;
            border: 0.063rem solid $secondary;
            color: $white;
            width: 14.5rem;
            font-size: 0.875rem;
            line-height: 1.667;
            text-transform: capitalize;
            padding: 0 1rem;
            text-decoration: none;
        }
    }
}

// FOR NEW HTML MARKUP
.podcasts,
.editors-choice {
    &__list {
        list-style: none;
        padding: 0;
        margin: 1rem 0;
        &__item {
            background-image: url('../../images/Editors-choice-icon.png');
            background-repeat: no-repeat;
            background-position: top left;
            padding-left: 2.5rem;
            padding-bottom: 1.5rem;
            margin-bottom: 1rem;
            border-bottom: $default-border;
        }
    }
    &__link {
        display: block;
    }
    &__content {
        margin-top: 1rem;
        p {
            font-size: $font-size-sm;
            color: $deep-gray;
            margin: 0;
        }
    }
    &__actions {
        text-align: center;
        .btn {
            max-width: 14.5rem;
        }
    }
}
