@mixin accordionHover() {
    background: $accordion-bg-hover;
    color: $accordion-color-hover;
    text-decoration: none;
    #{$icon-selector} {
        color: $white;
    }
}

.bs-accordion {
    display: flex;
    flex-direction: column;
    h1,
    h2,
    h3 {
        margin: 0;
    }
    &__control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 3.75rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
        text-decoration: none;
        font-size: toRem(18);
        font-family: 'Sage Peak', sans-serif;
        cursor: pointer;

        background: $accordion-bg;
        color: $accordion-color;
        border: $accordion-border;
        border-radius: toRem(5);
        box-shadow: inset 0.25rem 0 0 $primary;
        #{$icon-selector} {
            color: $deep-gray;
        }
        &:focus {
            text-decoration: none;
            color: $accordion-color;
        }
        &:hover {
            color: $accordion-color;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @include accordionHover;
            }
        }
        &[aria-expanded='true'] {
            @include accordionHover;
            margin-bottom: 0;
            border-radius: toRem(5) toRem(5) 0 0;
            .icon-arrow_down {
                transform: rotate(180deg);
            }
        }
        img {
            font-size: 1.5rem;
        }
        h2,
        h4,
        > div {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: inherit;
            font-size: inherit;
            margin: 0;
            #{$icon-selector} {
                color: inherit;
            }
        }
    }
    &__content {
        margin-top: 0;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        color: $accordion-body-text-color;
        border-radius: 0 0 toRem(5) toRem(5);
        &.show {
            box-shadow: $box-shadow-basic;
        }

        p {
            font-size: $font-size-sm;
        }
    }

    &__contact__list,
    .rss__list {
        padding-left: 0;
        li {
            margin-left: 1.25rem;
            margin-bottom: 0.5rem;
        }
    }
}
