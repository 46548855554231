.tooltip {
    &-inner {
        box-shadow: $tooltip-box-shadow;
        text-align: left;
        font-style: $tooltip-font-style;
        text-transform: $tooltip-case;
        font-weight: $tooltip-font-weight;
        border-radius: $border-radius;
        font-family: $default-font-family;
    }

    .arrow {
        transform: rotate(-45deg);
        background: $tooltip-arrow-color;
        border: 1px solid $tooltip-border-color;

        &:before {
            display: none;
        }
    }

    &.show {
        opacity: 1;
    }
    &__info {
        display: block;
        text-decoration: none;
        #{$icon-selector} {
            font-size: 1.75rem;
            &:before {
                color: $error;
            }
        }
        &:hover {
            #{$icon-selector} {
                &:before {
                    border-radius: 1.75rem;
                    color: $white;
                    background: $error;
                }
            }
        }
    }
}

.ecomm-vat {
    .tooltip__info {
        &:hover {
            .icon-info::before {
                color: $white;
                background: $primary;
            }
        }

        .icon-info::before {
            color: $primary;
        }
    }
}

.bs-tooltip {
    &-top {
        .arrow {
            box-shadow: -0.25rem 0.25rem 0.625rem -0.25rem rgba(0, 0, 0, 0.13);
            bottom: $tooltip-arrow-width * 0.5;
            border-top: none;
            border-right: none;
            display: none;
        }
    }
    &-bottom {
        .arrow {
            box-shadow: 0.25rem -0.25rem 0.625rem -0.25rem rgba(0, 0, 0, 0.13);
            top: $tooltip-arrow-width * 0.5;
            border-bottom: none;
            border-left: none;
            display: none;
        }
    }
    &-left {
        .arrow {
            box-shadow: 0.25rem 0.25rem 0.625rem -0.25rem rgba(0, 0, 0, 0.13);
            right: $tooltip-arrow-width * 0.5;
            border-top: none;
            border-left: none;
            display: none;
        }
    }
    &-right {
        .arrow {
            box-shadow: -0.25rem -0.25rem 0.625rem -0.25rem $box-shadow-basic;
            left: $tooltip-arrow-width * 0.5;
            border-bottom: none;
            border-right: none;
            display: none;
        }
    }
}
