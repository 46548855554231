.journals-metric-container {
    display: flex;
    flex-direction: column;
}

.journals-metric {
    &__section {
        + section {
            margin-top: 1.25rem;
        }
    }
    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;

        &__title {
            margin: 0;
            font-weight: $font-weight-semibold;
        }
    }
    &__list {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 1rem 2.25rem;
        columns: 2 auto;
        column-gap: 2rem;

        &__item {
            font-size: $journals-metric-list-font-size;
            line-height: $journals-metric-list-line-height;
            padding: $journals-metric-list-item-padding;
        }
    }
    &__metric {
        &s {
            border-radius: 0 0 toRem(5) toRem(5);
            padding: 1rem 0;
        }
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;
            background-color: $very-light-gray;
            font-size: $journals-metric-metric-header-font-size;
            font-weight: $journals-metric-metric-header-font-weight;
            line-height: $journals-metric-metric-header-line-height;
        }
        &__description {
            margin: 0.5rem 0 1rem;
            padding: 0 1rem;
            font-size: $journals-metric-metric-description-font-size;
            line-height: $journals-metric-metric-description-line-height;
        }
        &__value {
            text-align: right;
        }
    }

    &__category {
        margin-bottom: 0.5rem;
        &__header {
            margin: 0;
            .btn {
                border: $default-border;
                font-size: toRem(18);
                line-height: 1.75;
                padding: 2rem 1rem;
                color: $primary;
                box-shadow: inset 0.25rem 0 0 $primary;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background: $primary;
                        color: $white;
                    }
                }
                &:not(.collapsed) {
                    background: $primary;
                    color: $white;
                }

                i {
                    font-size: 1.5rem;
                }
            }
        }
        &__content {
            &.show {
                box-shadow: $box-shadow-basic;
            }
        }
    }
    &__tabs {
        margin-bottom: 2rem;
        .tab__pane {
            font-size: 1rem;
        }
        .tab__nav__item {
            .tab__nav__item__link {
                font-size: 1rem;
            }
        }
    }
}

.learn-more-link {
    margin-top: 2rem;
    text-align: center;
}

.alt-journals-metric {
    &__section {
        margin: 1.25rem 0;
        background-color: $alt-journals-metric-bg;
        border-radius: $border-radius;
    }

    &__metrics {
        padding: toRem(15);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: toRem(8);
        color: $deep-gray;
        @include media-breakpoint-down(xs) {
            padding: toRem(24);
            row-gap: 2rem;
        }
    }

    &__metric {
        &__header {
            @extend .journals-metric__metric__header;
            gap: toRem(12);
            background-color: unset;
            flex-direction: column;
            justify-content: flex-start;
            flex-grow: 1;
            padding: 0;

            span:not(.percentage) {
                margin-left: toRem(6);
            }
        }
        &__title {
            font-weight: 400;
        }
        &__value {
            font-weight: 600;
        }
    }

    &__extra_info {
        padding: toRem(15);
        @include media-breakpoint-down(xs) {
            padding: toRem(24);
        }
        font-size: toRem(14);
    }
}
