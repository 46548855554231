.journal-cover-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    color: $darkest-gray;
    background-color: $very-light-gray;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
    &__cover {
        flex-shrink: 0;
        width: $journal-cover-image-width;
        margin: 0 auto;
        @include media-breakpoint-up(sm) {
            margin: 0;
        }
        img {
            object-fit: contain;
            width: 100%;
            border: 1px solid $light-gray;
        }
    }
    &__description {
        p {
            margin: 0;
            font-size: 1rem;
            line-height: 1.375;
            & + p {
                margin-top: 1rem;
            }
        }
    }
}
