.journal-menu-banner {
    position: relative;
    display: flex;
    gap: $grid-gutter-width * 0.5;
    width: 100%;
    height: $journal-menu-banner-height;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $journal-menu-banner-bg-color;
    padding: $journal-menu-banner-padding-y $journal-menu-banner-padding-x;
    border-radius: $journal-menu-banner-border-radius;

    &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-basis: 100%;
    }

    &__heading {
        font-size: $journal-menu-banner-title-font-size;
        font-weight: $journal-menu-banner-title-font-weight;
        color: $journal-menu-banner-title-color;
        margin: $journal-menu-banner-title-margin;
    }
    &__nav {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
            gap: $grid-gutter-width * 0.5;
        }
        &__lists {
            display: none;
        }

        .mddb-navigation {
            &__button {
                //margin-left: $grid-gutter-width/2;
                padding: 0 1rem;
                text-transform: capitalize;
                white-space: nowrap;
                font-size: toRem(14);
            }
        }

        .w-slide & {
            display: block;
            width: 17rem;
            margin: $sage-extra-gap auto;

            @include media-breakpoint-up(sm) {
                width: 31.5rem;
            }

            .btn {
                width: 100%;
            }

            &__lists {
                display: block;
                border-top: $default-border;
            }

            .mddb-navigation__button {
                margin-left: 0;
                margin-bottom: $grid-gutter-width * 0.5;
            }
        }
    }
}
