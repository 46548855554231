@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?gvg0v5');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?gvg0v5#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?gvg0v5') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?gvg0v5') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?gvg0v5##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-epub .path1 {
  &:before {
    content: $icon-epub-path1;  
    color: #001a69;
  }
}
.icon-epub .path2 {
  &:before {
    content: $icon-epub-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-download-outline {
  &:before {
    content: $icon-download-outline;     
    color: #001a69;
  }
}
.icon-seamless-access {
  &:before {
    content: $icon-seamless-access;     
    color: #fff;
  }
}
.icon-lock_closed_not_fill {
  &:before {
    content: $icon-lock_closed_not_fill;     
    color: #001a69;
  }
}
.icon-shopping_cart {
  &:before {
    content: $icon-shopping_cart;     
    color: #001a69;
  }
}
.icon-SSS {
  &:before {
    content: $icon-SSS; 
  }
}
.icon-embedely {
  &:before {
    content: $icon-embedely; 
  }
}
.icon-email-social {
  &:before {
    content: $icon-email-social;     
    color: #001a69;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;     
    color: #001a69;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;     
    color: #001a69;
  }
}
.icon-wechat .path1 {
  &:before {
    content: $icon-wechat-path1;  
    color: rgb(0, 26, 105);
  }
}
.icon-wechat .path2 {
  &:before {
    content: $icon-wechat-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-wechat .path3 {
  &:before {
    content: $icon-wechat-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-wechat .path4 {
  &:before {
    content: $icon-wechat-path4;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-wechat .path5 {
  &:before {
    content: $icon-wechat-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-wechat .path6 {
  &:before {
    content: $icon-wechat-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;     
    color: #001a69;
  }
}
.icon-RSS-feed {
  &:before {
    content: $icon-RSS-feed;     
    color: #001a69;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;     
    color: #001a69;
  }
}
.icon-more {
  &:before {
    content: $icon-more;     
    color: #001a69;
  }
}
.icon-linkedin-square {
  &:before {
    content: $icon-linkedin-square;     
    color: #001a69;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;     
    color: #001a69;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;     
    color: #001a69;
  }
}
.icon-arrow_up {
  &:before {
    content: $icon-arrow_up;     
    color: #888;
  }
}
.icon-section_arrow_u {
  &:before {
    content: $icon-section_arrow_u;     
    color: #888;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;     
    color: #888;
  }
}
.icon-arrow_r {
  &:before {
    content: $icon-arrow_r;     
    color: #888;
  }
}
.icon-section_arow_rt {
  &:before {
    content: $icon-section_arow_rt;     
    color: #888;
  }
}
.icon-previous {
  &:before {
    content: $icon-previous;     
    color: #001a69;
  }
}
.icon-next {
  &:before {
    content: $icon-next;     
    color: #001a69;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;     
    color: #888;
  }
}
.icon-arrow_l {
  &:before {
    content: $icon-arrow_l;     
    color: #888;
  }
}
.icon-section_arrow_l {
  &:before {
    content: $icon-section_arrow_l;     
    color: #888;
  }
}
.icon-back {
  &:before {
    content: $icon-back;     
    color: #001a69;
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down;     
    color: #888;
  }
}
.icon-arrow_d {
  &:before {
    content: $icon-arrow_d;     
    color: #888;
  }
}
.icon-order {
  &:before {
    content: $icon-order;     
    color: #001a69;
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera;     
    color: #001a69;
  }
}
.icon-lock-open .path1 {
  &:before {
    content: $icon-lock-open-path1;  
    color: rgb(66, 165, 132);
  }
}
.icon-lock-open .path2 {
  &:before {
    content: $icon-lock-open-path2;  
    margin-left: -1em;  
    color: rgb(66, 165, 132);
  }
}
.icon-lock-open .path3 {
  &:before {
    content: $icon-lock-open-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-subject {
  &:before {
    content: $icon-subject;     
    color: #001a69;
  }
}
.icon-reply {
  &:before {
    content: $icon-reply;     
    color: #001a69;
  }
}
.icon-peer-review {
  &:before {
    content: $icon-peer-review;     
    color: #001a69;
  }
}
.icon-metrics {
  &:before {
    content: $icon-metrics;     
    color: #001a69;
  }
}
.icon-metrics-alt {
  &:before {
    content: $icon-metrics-alt;     
    color: #001a69;
  }
}
.icon-locked-grey .path1 {
  &:before {
    content: $icon-locked-grey-path1;  
    color: rgb(85, 85, 85);
  }
}
.icon-locked-grey .path2 {
  &:before {
    content: $icon-locked-grey-path2;  
    margin-left: -1em;  
    color: rgb(85, 85, 85);
  }
}
.icon-locked-grey .path3 {
  &:before {
    content: $icon-locked-grey-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-lock-alt .path1 {
  &:before {
    content: $icon-lock-alt-path1;  
    color: rgb(0, 26, 105);
  }
}
.icon-lock-alt .path2 {
  &:before {
    content: $icon-lock-alt-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-lock-alt .path3 {
  &:before {
    content: $icon-lock-alt-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-lock-alt .path4 {
  &:before {
    content: $icon-lock-alt-path4;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-location {
  &:before {
    content: $icon-location;     
    color: #001a69;
  }
}
.icon-list {
  &:before {
    content: $icon-list;     
    color: #001a69;
  }
}
.icon-contents {
  &:before {
    content: $icon-contents;     
    color: #001a69;
  }
}
.icon-discover {
  &:before {
    content: $icon-discover;     
    color: #001a69;
  }
}
.icon-cite {
  &:before {
    content: $icon-cite;     
    color: #001a69;
  }
}
.icon-tables {
  &:before {
    content: $icon-tables;     
    color: #001a69;
  }
}
.icon-tables-alt {
  &:before {
    content: $icon-tables-alt;     
    color: #001a69;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;     
    color: #eb0000;
  }
}
.icon-bin {
  &:before {
    content: $icon-bin;     
    color: #eb0000;
  }
}
.icon-bank-card {
  &:before {
    content: $icon-bank-card;     
    color: #001a69;
  }
}
.icon-abstracting {
  &:before {
    content: $icon-abstracting;     
    color: #001a69;
  }
}
.icon-full {
  &:before {
    content: $icon-full;     
    color: #001a69;
  }
}
.icon-free {
  &:before {
    content: $icon-free;     
    color: #001a69;
  }
}
.icon-access-full {
  &:before {
    content: $icon-access-full;     
    color: #001a69;
  }
}
.icon-access-free {
  &:before {
    content: $icon-access-free;     
    color: #001a69;
  }
}
.icon-check-circle-outline {
  &:before {
    content: $icon-check-circle-outline;     
    color: #008929;
  }
}
.icon-sort-descending {
  &:before {
    content: $icon-sort-descending;     
    color: #001a69;
  }
}
.icon-sort-ascending {
  &:before {
    content: $icon-sort-ascending;     
    color: #001a69;
  }
}
.icon-hide {
  &:before {
    content: $icon-hide;     
    color: #001a69;
  }
}
.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked;     
    color: #001a69;
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off;     
    color: #001a69;
  }
}
.icon-search {
  &:before {
    content: $icon-search;     
    color: #001a69;
  }
}
.icon-save {
  &:before {
    content: $icon-save;     
    color: #001a69;
  }
}
.icon-minus-circle-outline {
  &:before {
    content: $icon-minus-circle-outline;     
    color: #eb0000;
  }
}
.icon-person_outline {
  &:before {
    content: $icon-person_outline;     
    color: #001a69;
  }
}
.icon-notification {
  &:before {
    content: $icon-notification;     
    color: #001a69;
  }
}
.icon-no {
  &:before {
    content: $icon-no;     
    color: #001a69;
  }
}
.icon-access-no {
  &:before {
    content: $icon-access-no;     
    color: #001a69;
  }
}
.icon-access-restricted {
  &:before {
    content: $icon-access-restricted;     
    color: #001a69;
  }
}
.icon-like {
  &:before {
    content: $icon-like;     
    color: #001a69;
  }
}
.icon-incorrect {
  &:before {
    content: $icon-incorrect;     
    color: #eb0000;
  }
}
.icon-home_fill {
  &:before {
    content: $icon-home_fill;     
    color: #001a69;
  }
}
.icon-full-screen {
  &:before {
    content: $icon-full-screen;     
    color: #001a69;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;     
    color: #001a69;
  }
}
.icon-filter_list {
  &:before {
    content: $icon-filter_list;     
    color: #001a69;
  }
}
.icon-file {
  &:before {
    content: $icon-file;     
    color: #001a69;
  }
}
.icon-favourite {
  &:before {
    content: $icon-favourite;     
    color: #001a69;
  }
}
.icon-enrole-learners {
  &:before {
    content: $icon-enrole-learners;     
    color: #001a69;
  }
}
.icon-mail-outline {
  &:before {
    content: $icon-mail-outline;     
    color: #001a69;
  }
}
.icon-edit-mode {
  &:before {
    content: $icon-edit-mode;     
    color: #001a69;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;     
    color: #001a69;
  }
}
.icon-download1 {
  &:before {
    content: $icon-download1;     
    color: #001a69;
  }
}
.icon-cross-fill .path1 {
  &:before {
    content: $icon-cross-fill-path1;  
    color: rgb(235, 0, 0);
  }
}
.icon-cross-fill .path2 {
  &:before {
    content: $icon-cross-fill-path2;  
    margin-left: -1em;  
    color: rgb(235, 0, 0);
  }
}
.icon-cross-fill .path3 {
  &:before {
    content: $icon-cross-fill-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-cross-fill .path4 {
  &:before {
    content: $icon-cross-fill-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;     
    color: #eb0000;
  }
}
.icon-correct {
  &:before {
    content: $icon-correct;     
    color: #008929;
  }
}
.icon-copy-link {
  &:before {
    content: $icon-copy-link;     
    color: #001a69;
  }
}
.icon-collapse-full-screen {
  &:before {
    content: $icon-collapse-full-screen;     
    color: #001a69;
  }
}
.icon-close {
  &:before {
    content: $icon-close;     
    color: #001a69;
  }
}
.icon-close_thin {
  &:before {
    content: $icon-close_thin;     
    color: #001a69;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;     
    color: #001a69;
  }
}
.icon-cart-filled .path1 {
  &:before {
    content: $icon-cart-filled-path1;  
    color: rgb(0, 26, 105);
  }
}
.icon-cart-filled .path2 {
  &:before {
    content: $icon-cart-filled-path2;  
    margin-left: -1em;  
    color: rgb(235, 0, 0);
  }
}
.icon-cart-filled .path3 {
  &:before {
    content: $icon-cart-filled-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-cart-filled .path4 {
  &:before {
    content: $icon-cart-filled-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-audio {
  &:before {
    content: $icon-audio;     
    color: #001a69;
  }
}
.icon-circle-plus {
  &:before {
    content: $icon-circle-plus;     
    color: #008929;
  }
}
.icon-plus-circle-outline {
  &:before {
    content: $icon-plus-circle-outline;     
    color: #008929;
  }
}
.icon-share {
  &:before {
    content: $icon-share;     
    color: #001a69;
  }
}
.icon-share-alt {
  &:before {
    content: $icon-share-alt;     
    color: #001a69;
  }
}
.icon-warning-1 {
  &:before {
    content: $icon-warning-1;     
    color: #eb0000;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;     
    color: #eb0000;
  }
}
.icon-text-size {
  &:before {
    content: $icon-text-size;     
    color: #001a69;
  }
}
.icon-speech-marks {
  &:before {
    content: $icon-speech-marks;     
    color: #001a69;
  }
}
.icon-view {
  &:before {
    content: $icon-view;     
    color: #001a69;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;     
    color: #001a69;
  }
}
.icon-settings-1 {
  &:before {
    content: $icon-settings-1;     
    color: #001a69;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;     
    color: #001a69;
  }
}
.icon-person {
  &:before {
    content: $icon-person;     
    color: #001a69;
  }
}
.icon-notification-active {
  &:before {
    content: $icon-notification-active;     
    color: #001a69;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;     
    color: #001a69;
  }
}
.icon-export_citation {
  &:before {
    content: $icon-export_citation;     
    color: #001a69;
  }
}
.icon-info-active {
  &:before {
    content: $icon-info-active;     
    color: #001a69;
  }
}
.icon-info {
  &:before {
    content: $icon-info;     
    color: #001a69;
  }
}
.icon-info-alt {
  &:before {
    content: $icon-info-alt;     
    color: #001a69;
  }
}
.icon-folder-active {
  &:before {
    content: $icon-folder-active;     
    color: #001a69;
  }
}
.icon-filter-1 {
  &:before {
    content: $icon-filter-1;     
    color: #001a69;
  }
}
.icon-file-active {
  &:before {
    content: $icon-file-active;     
    color: #001a69;
  }
}
.icon-favourite-1 {
  &:before {
    content: $icon-favourite-1;     
    color: #001a69;
  }
}
.icon-email-active {
  &:before {
    content: $icon-email-active;     
    color: #001a69;
  }
}
.icon-list-view-active .path1 {
  &:before {
    content: $icon-list-view-active-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-list-view-active .path2 {
  &:before {
    content: $icon-list-view-active-path2;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-list-view-active .path3 {
  &:before {
    content: $icon-list-view-active-path3;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-list-view-active .path4 {
  &:before {
    content: $icon-list-view-active-path4;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-grid-view-active .path1 {
  &:before {
    content: $icon-grid-view-active-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-grid-view-active .path2 {
  &:before {
    content: $icon-grid-view-active-path2;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-grid-view-active .path3 {
  &:before {
    content: $icon-grid-view-active-path3;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-grid-view-active .path4 {
  &:before {
    content: $icon-grid-view-active-path4;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-grid-view-active .path5 {
  &:before {
    content: $icon-grid-view-active-path5;  
    margin-left: -1em;  
    color: rgb(0, 26, 105);
  }
}
.icon-download {
  &:before {
    content: $icon-download;     
    color: #001a69;
  }
}
.icon-download-circle {
  &:before {
    content: $icon-download-circle;     
    color: #001a69;
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline;     
    color: #001a69;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;     
    color: #001a69;
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full;     
    color: #001a69;
  }
}
.icon-vantage {
  &:before {
    content: $icon-vantage;     
    color: #00818d;
  }
}
.icon-grid-view {
  &:before {
    content: $icon-grid-view;     
    color: #001a69;
  }
}
.icon-list-view {
  &:before {
    content: $icon-list-view;     
    color: #001a69;
  }
}
.icon-readership {
  &:before {
    content: $icon-readership;     
    color: #001a69;
  }
}
.icon-oa {
  &:before {
    content: $icon-oa;     
    color: #f68212;
  }
}
.icon-access-open {
  &:before {
    content: $icon-access-open;     
    color: #f68212;
  }
}
.icon-institution {
  &:before {
    content: $icon-institution;     
    color: #001a69;
  }
}
.icon-societies {
  &:before {
    content: $icon-societies;     
    color: #001a69;
  }
}

