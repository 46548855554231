.microsites {
    &__descriptive-text {
        background-color: $very-light-gray;
        padding: 1.5rem;
        border-radius: $border-radius;
        &__image {
            text-align: center;
            margin-bottom: 1.5rem;
            img {
                max-height: 5rem;
            }
        }
        &__text {
            &,
            & p {
                font-size: 1rem;
                line-height: 1.75;
                margin: 0;
                + p {
                    margin-top: 1rem;
                }
            }
        }
    }
}
