.marketing-spots {
    display: grid;
    margin-top: toRem(24);
    gap: 1.25rem;
    grid-template-columns: 1fr;
    justify-items: center;
    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    &--home {
        margin-bottom: toRem(40);
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.marketing-spot {
    $this: &;
    width: 100%;
    color: inherit;
    border: 1px solid $gray-2;
    border-radius: 8px;
    overflow: hidden;
    /* stylelint-disable unit-disallowed-list*/
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    /* stylelint-enable unit-disallowed-list*/
    @include media-breakpoint-up('lg') {
        max-width: toRem(288);
    }

    &__btn {
        height: 100%;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        text-decoration: none;
        color: inherit;
    }
    &__svg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $marketing-spot-svg-container-size;
        margin-bottom: 1rem;
        background-color: rgba($sageProductDefault, 0.2);

        svg {
            height: $marketing-spot-svg-size;
            width: $marketing-spot-svg-size;
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                fill: $sageProductDefault;
            }
        }
        @include media-breakpoint-up(sm) {
            height: $marketing-spot-svg-container-size-md;
            svg {
                height: $marketing-spot-svg-size-md;
                width: $marketing-spot-svg-size-md;
            }
        }
        @include media-breakpoint-up(lg) {
            height: $marketing-spot-svg-container-size-lg;
            svg {
                height: $marketing-spot-svg-size-lg;
                width: $marketing-spot-svg-size-lg;
            }
        }
    }

    &__title {
        $lh: 1.555em;
        $pb: 0.75rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem 1rem toRem(29) 1rem;
        margin-bottom: 1rem;
        font-size: $h6-font-size;
        line-height: toRem(28);
        color: $deep-gray;
        height: toRem(56);
        //@include media-breakpoint-up(sm) {
        //    height: calc(2 *#{$lh} + #{$pb});
        //}

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: toRem(80);
            height: toRem(1);
            background-color: $sageProductDefault;
        }
    }
    &__text {
        font-size: $font-size-sm;
        line-height: toRem(22);
        color: $darkest-gray;
        padding: 0 1rem 1rem;
        & p {
            @extend .marketing-spot__text;
            margin: 0;
        }
    }

    &__footer {
        padding: 1rem;
        margin-top: auto;
        a {
            font-size: toRem(14);
        }
    }

    &__list {
        margin: 0;
        padding: 0 0 0 toRem(16);

        li + li {
            padding: 1rem 0 0;
        }

        &.no-bullets {
            list-style: none;
            padding: 0;
        }
    }

    .btn {
        width: 100%;
        padding: 0 1rem;
        //margin-top: 1.5rem;
    }
}

@each $key, $color in $sageProducts {
    .marketing-spot.#{$key} {
        svg {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                fill: $color;
            }
        }
        .marketing-spot__svg {
            background-color: rgba($color, 0.2);
        }
        .marketing-spot__title {
            &:after {
                background-color: $color;
            }
        }
    }
    .marketing-spots.#{$key} {
        color: inherit;
        .marketing-spot {
            svg {
                path,
                rect,
                circle,
                ellipse,
                line,
                polyline,
                polygon {
                    fill: $color;
                }
            }
            .marketing-spot__svg {
                background-color: rgba($color, 0.2);
            }
            .marketing-spot__title {
                &:after {
                    background-color: $color;
                }
            }
        }
    }
}
