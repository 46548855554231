.tfa-form-container {
    box-shadow: $box-shadow-basic-sm;
    padding: 1rem;
    p {
        font-size: $font-size-sm;
        line-height: 1.57;
    }
    form {
        width: 100%;
        max-width: 31rem;
        &.js__pinCode {
            text-align: left;
        }

        label {
            display: block;
            font-size: $font-size-sm;
        }

        .heading-xxs {
            strong {
                width: 100%;
            }
        }

        .actions {
            .btn {
                padding: toRem(10) toRem(20);
            }
        }
    }
    .tfa-dropDown-menu {
        font-size: inherit;
    }
}
