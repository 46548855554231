.pb {
    .filterContainer {
        .dropdown-toggle {
            display: inline;
        }
    }
    .pb-activity-stream-panel {
        .input-group {
            width: auto;
        }
        input {
            &[type='checkbox'] {
                -webkit-appearance: checkbox;
                appearance: auto;
            }
        }
    }
}
#pb-editor {
    .pb-wrap {
        .journal-menu-banner__nav__lists,
        .hidden-lg,
        .hidden-xl {
            display: block !important;
            background: rgb(243, 243, 243);

            .dropzone {
                background: rgb(255, 238, 238);
            }
        }

        .banner-widget__box {
            position: relative;
        }

        .d-lg-none {
            display: block !important;
            background: rgb(243, 243, 243);

            &:before {
                content: 'This is hidden on lg devices';
                font-size: $font-size-xs;
                color: rgb(255, 0, 0);
            }
        }
        .tab__nav__item {
            max-width: 7.5rem;
            overflow: hidden;
        }
    }
}

.pb-toolbox {
    .collapse {
        &:not(.show) {
            display: block;
        }
    }
}
.pb-dropzone-mode .pb-widget {
    margin: 1.5rem 0;
}

.pb-changes-dialog.pb-save-dialog .modal-footer {
    .btn.dropdown-toggle {
        display: none;
    }
    .btn.btn-primary {
        border-radius: toRem(4);
        margin-inline-end: toRem(5);
    }
}
