.purchase-items {
    border-bottom: $default-border;
}
.addedMessage {
    flex-direction: column;
    align-items: center;
    padding: 1rem 1.5rem;

    .text {
        display: inline-flex;
        flex-direction: row;
        gap: 0.75em;
        font-size: $font-size-base;
        line-height: 1.75;
        #{$icon-selector}::before {
            color: $success;
            font-size: 1.5rem;
        }
    }
}
.eCommercePurchaseAccessWidget {
    .addedMessage {
        .success-message {
            font-size: $font-size-base;
        }
    }
}
.temporarily-unavailable {
    text-align: center;
    &__icon {
        font-size: 2rem;
        color: $primary;
        #{$icon-selector} {
            display: block;
        }
    }
    &__title {
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        margin: 1rem 0 0.5rem;
    }
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label {
    &:after {
        opacity: 0.5;
    }
    &:before {
        background-color: $custom-control-indicator-disabled-bg;
    }
}
