.quick-search {
    @include media-breakpoint-up(xl) {
        width: $quick-search-width;
    }

    @include media-breakpoint-down(md) {
        flex-grow: 1;
        .quickSearchFormContainer {
            height: 100%;
        }
        .quick-search__form {
            justify-content: space-between;
        }
        .tab {
            display: flex;
            height: 25%;
            flex-direction: column;
            gap: $grid-gutter-width * 0.5;

            .quick-search__searchbox {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
            }
        }
        .tab__content {
            display: flex;
            flex-grow: 1;
            .tab__pane {
                &.active {
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                }
            }
        }
    }

    &__form {
        flex-direction: column;
        .quick-search {
            &__input[type='search'] {
                border-radius: 0;
                border: $quick-search-border;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                padding: 0.562rem 0.75rem;
                height: $quick-search-dropblock-height;

                @include media-breakpoint-up(sm) {
                    padding: 0.562rem 1rem;
                }
                @include media-breakpoint-up(lg) {
                    border-right: 0;
                }
                &:focus {
                    box-shadow: 0 0 0 0.125rem $input-btn-focus-color;
                }

                ::placeholder {
                    color: $dark-gray;
                }
            }
        }
        .search-icon {
            width: toRem(20);
            height: toRem(20);
        }
    }

    &__button.btn {
        border-radius: 0;
        background: $white;
        border: $quick-search-border;
        border-left: 0;
        color: $header-icon-color;
        padding: 0;
        font-size: 1.25rem;
        height: toRem(40);
        .quick-search:not(.quick-search--microsites):not(.quick-search--home) & {
            @include media('<desktop') {
                width: 100%;
                border: 0;
                background: $btn-bg;
                padding: 0.75rem;
                span {
                    font-size: 0.75rem;
                    color: $quick-search-label-color;
                    font-weight: 500;
                }
                i[class*='icon-'],
                .search-icon {
                    display: none;
                }
            }
        }

        &:hover {
            border: $quick-search-border;
            color: darken($header-icon-color, 10%);
        }
    }

    &--home,
    &--microsites {
        background: $white;
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
        .quick-search {
            &__advanced-link {
                display: block;
                padding: 0;
            }
            &__form {
                flex-direction: row !important;
            }
            &__searchbox {
                width: 100%;
                flex-direction: row !important;
            }
            &__wrapper {
                flex: auto;
            }
            &__input {
                border-right: 0 !important;
                margin: 0 !important;
            }
        }
    }
    &--home {
        @include media-breakpoint-up(lg) {
            max-width: 31.25rem;
        }
    }
    &--citation {
        input {
            width: 100%;
            margin: 0;
        }

        .journal-search {
            width: 100%;
        }

        .other-citation-search {
            display: flex;
            gap: unset;
        }
        @include media-breakpoint-up(md) {
            .journal-search {
                input {
                    width: 100%;
                }
            }
        }
    }
    &--all {
        background: none;
        margin: 0;
        padding: 0;
        box-shadow: none;
        margin-left: toRem(8);

        .quick-search {
            &__advanced-link {
                display: block;
                padding: 0;
                margin-top: 0.25rem;
                text-align: end;
                width: auto;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                color: $quick-search-advanced-search-link;

                @include media-breakpoint-down(lg) {
                    float: none;
                    margin-top: 1rem;
                }

                &:hover {
                    text-decoration: none;
                    color: $quick-search-advanced-search-link;
                }
            }
            &__wrapper {
                position: relative;
            }
            &__form {
                .quick-search__input {
                    border-radius: 0.25rem;
                    @include media-breakpoint-up(lg) {
                        padding-left: $quick-search-dropblock-width + 0.75rem;
                        padding-right: 3.75rem;
                        height: $quick-search-dropblock-height;
                    }
                }
            }
            &__button {
                position: relative;
                margin-top: 1rem;
                height: 2.5rem;
                border-radius: 0 0.25rem 0.25rem 0;

                @include media-breakpoint-up(lg) {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 0;
                    z-index: $zindex-qs;
                }
            }
        }
    }

    & .dropBlock {
        &__link {
            &:first-child {
                border-radius: 0;
                background: $quick-search-dropblock-bg;
                color: $quick-search-dropblock-color;
                border: $quick-search-border;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                text-decoration: none;
                @include media('<desktop') {
                    color: $quick-search-dropblock-color-mobile;
                    background: $white;
                }

                &.js--open {
                    #{$icon-selector} {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .dropblock--tab {
        padding: 0;

        .tab__nav__item {
            margin-bottom: 0;
            padding: 0;

            a {
                &.active {
                    color: $dropblock-dropdown-item-hover-color;
                    background: $light-primary;
                    box-shadow: $box-shadow-active;
                    text-decoration: underline;
                }
            }
        }

        a {
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            padding: 1rem 0.75rem;

            &:hover {
                color: $btn-color-4;
                background: $light-primary;
                box-shadow: $box-shadow-hover;
                text-decoration: underline;
            }
        }
    }

    & .tab {
        flex-direction: column;
        align-items: flex-end;

        .dropBlock {
            & > a {
                height: $quick-search-dropblock-height;
                padding: 0 0.75rem;
                border-radius: 0.25rem 0 0 0.25rem;
            }
        }

        .quick-search {
            &__searchbox {
                position: relative;
                align-items: center;
                flex-direction: column;
                gap: 0.75rem;

                .dropBlock {
                    position: relative;
                    width: 100%;
                    height: auto;
                    margin-bottom: 0.5rem;
                    flex: 0 0 auto;
                    white-space: nowrap;

                    //@include media('<desktop') {
                    @include media-breakpoint-up(lg) {
                        position: absolute !important;
                        left: 0;
                        top: 0;
                        z-index: $zindex-qs;

                        width: $quick-search-dropblock-width;
                        height: $quick-search-dropblock-height;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__advanced-link {
        width: max-content;
        text-transform: none;
        @include media('<desktop') {
            margin-top: 1rem;
        }
    }
}

#search-overlay {
    @include media-breakpoint-down(md) {
        display: flex;
        z-index: $zindex-behindall;
        position: absolute;
        top: var(--header-height);
        left: 0;
        right: 0;
        height: calc(100vh - var(--header-height));
        padding: $sage-extra-gap 2rem;
        background-color: $white;
        &.js--hidden {
            transform: translateY(-100vh);
            transition: transform 0.5s ease;
        }
        &.js--shown {
            transform: translateY(0);
            transition: transform 0.5s ease;
        }
    }
    @include media('<phone') {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.search-trigger {
    padding: 0 0.813rem;
    &:first-child {
        display: none;
    }
    @include media-breakpoint-down(md) {
        padding: 0 0.625rem;

        &:first-child {
            display: flex;
        }
        .icon-close {
            display: none;
        }
        &.js--target-shown {
            .icon-close {
                display: block;
            }

            .search-icon,
            .icon-search {
                display: none;
            }
        }
        &.js--target-hidden {
            .search-icon,
            .icon-search {
                display: block;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0.25rem;
    }
}
.quickSearchAutocomplete {
    &.ui-autocomplete {
        z-index: $zindex-dropdown;
    }
}

.autoComplete {
    &__item {
        display: block;
        font-size: $font-size-sm !important; //To overwrite the font-size that's coming from _variables-colors
        color: $dark-gray;
        text-decoration: none;
        font-weight: 400;
        padding: 1rem 0 0 0.75rem !important; //To overwrite the important that's coming from _autocomplete from core;
        &:hover {
            text-decoration: underline;
        }
        &:last-child {
            padding-bottom: 1rem !important; //To overwrite the important that's coming from _autocomplete from core;;
        }
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            gap: $grid-gutter-width * 0.5;
        }
        @include media-breakpoint-down(md) {
            padding: 0.5rem 0.75rem;
        }
    }
    &__label {
        flex-grow: 1;
        .single_highlight_class {
            font-weight: $font-weight-semibold;
            color: $darkest-gray;
            background-color: transparent;
        }
    }
    &__category {
        //white-space: normal;
        flex-basis: 8.875rem;
        flex-grow: 0;
        flex-shrink: 0;
        color: $darkest-gray;
        @include media-breakpoint-down(md) {
            font-weight: $font-weight-semibold;
            width: auto;
            margin-right: 0.25rem;
        }
    }
}
