.advanced-search {
    .autocomplete-wrapper {
        width: 100%;
    }
    .autocomplete-results {
        &:not(:empty) {
            margin-bottom: 0.25rem;
        }
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
    }
    .autocomplete-result-item {
        font-size: $font-size-xs;
        padding: 0.25rem 0.5rem;
        height: auto;
        gap: 0.25rem;
        white-space: nowrap;
    }
    &-page {
        aside {
            margin-top: 2.5rem;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
    }
    fieldset {
        padding: 0;
        border: none;
    }
    .searchIn {
        &--field {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 1rem;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                gap: 1.5rem;
            }
            .label,
            label:not(.custom-control-label) {
                display: block;
                width: 100%;
                font-size: $font-size-sm;
                line-height: 1.57;
                margin: 0.5rem 0 0;
            }
            .custom-control-label {
                @extend .custom-control-label;
                width: auto;
                margin: 0;
                line-height: 1.57;
            }

            [type='text'] {
                width: 100%;
            }
            .custom-control-input {
                @extend .custom-control-input;
            }
        }
    }

    &__tabs {
        &--hidden {
            visibility: visible;
            display: none;
        }
    }

    &__row {
        @extend .searchIn--field;
        .custom-control {
            flex-shrink: 0;
            margin-top: 0.5rem;
            min-width: 9em;
            &-label {
                &--icon {
                    display: inline-flex !important;
                    align-items: center;
                    gap: 0.5rem;
                    #{$icon-selector} {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
    &__column {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        width: 100%;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        .dropdown {
            width: 100%;
        }
        &:first-child {
            @include media-breakpoint-up(md) {
                width: 11.5rem; //From Figma
                align-items: flex-start;
                flex-shrink: 0;
            }
        }
        input {
            width: 100%;
        }
    }

    &--filters {
        border: none;
        padding: 0;
        margin: 0;
    }

    [class*='-ctrl-field'] {
        flex: 0 0 auto;
        font-size: toRem(20);
        margin: 0;
        color: $success;
        vertical-align: unset;
    }
    .remove-ctrl-field {
        color: $error;
    }
    .access-type {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }
    .time-frame {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
        .advanced-search__select-container {
            width: 100%;
            min-width: 6.25rem;
            flex-grow: 1;
            @include media-breakpoint-up(lg) {
                flex-grow: 0;
            }
        }
        .dropdown {
            width: 100%;
        }
        .label {
            width: 3.25rem;
        }
    }
    .timeFrame-to,
    .timeFrame-from {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;
        flex-direction: column;
        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }
    .custom-range-fields {
        width: 100%;
    }

    .month-year-range {
        display: flex;
        gap: 0.5rem;
        flex-grow: 1;
        @include media-breakpoint-up(md) {
            gap: 1.5rem;
        }
    }

    .ms-ctn {
        padding: 0;
        height: auto;
        border: none;
        .ms-sel-ctn {
            padding: 0;
            margin: 0;
            input {
                border: $default-border;
                width: 100% !important;
                float: none;
            }
        }
    }
    .ms-ctn-focus {
        box-shadow: none;
    }
}
#advancedFilters,
#publicationDate {
    width: 100%;
}
