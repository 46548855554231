@for $i from 1 through 6 {
    h#{$i} {
        font-size: map-get($heading-fonts, #{$i});
        margin-top: 0;
        margin-bottom: 1.2rem;
        color: $heading-title-color;
        font-weight: $font-weight-normal;
    }
}

h1,
h2 {
    font-family: $sage-font-family;
    color: $primary;
}

h3,
h4,
h5,
h6 {
    font-family: $sage-font-family;
}

.aside {
    &__header {
        padding: $mddb-navigation-gap 0 $mddb-navigation-gap * 0.5;
        margin: 0 0 1rem;
        font-size: $facet-title-font-size;
        font-weight: $facet-title-font-weight;
        line-height: $facet-title-line-height;
        text-transform: none;
        color: $facet-title-color;
        border-bottom: $mddb-navigation-border-width $mddb-navigation-border-style $mddb-navigation-border-color;
    }
}

p {
    font-size: $font-size-base;
    color: $paragraph-font-color;
    font-weight: $font-weight-normal;
    &.info-text {
        font-size: $font-size-sm;
    }
    &.xs-text {
        font-size: $font-size-xs;
    }
    &.xss-text {
        font-size: $font-size-xxs;
    }
    &.density-low {
        line-height: 1.75;
    }
}
.disabled {
    color: $disabled-text;

    li {
        &::marker {
            color: $disabled-text;
        }
    }
}

.error {
    color: $error-text-color;

    li {
        &::marker {
            color: $error-text-color;
        }
    }
}
.message.success,
.success-message {
    margin: 1rem 0;
    display: inline-flex;
    gap: 0.5rem;

    #{$icon-selector} {
        font-size: 1.25rem;
    }
}
.profile-updated {
    display: flex;
    gap: 0.5rem;
    &:before {
        @include icomoon;
        content: $icon-check-circle-outline;
        font-size: 1.5rem;
        color: $success;
    }
}

a {
    color: $link-text;
    text-decoration: underline;

    &:hover {
        color: $link-text;
        text-decoration: none;
    }

    &.external {
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;

        #{$icon-selector} {
            color: $deep-gray;
        }
    }
}
.submission-guideline ol li::marker {
    color: inherit !important;
}
ol:not(.rlist):not(.rlist--inline):not(.error):not(.disabled) {
    color: $list-text-color;
    li {
        &::marker {
            color: $primary;
        }
    }
}
ul:not(.rlist):not(.rlist--inline):not(.error):not(.disabled) {
    color: $list-text-color;
    li {
        &::marker {
            color: $primary;
            font-size: $font-size-xs;
        }
    }
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

.field-items {
    ol {
        li {
            line-height: 1.75;
            margin: 0.5rem 0;
            a {
                text-decoration: none;
            }
        }
    }
}

.info-box {
    padding: 1rem;
    border-radius: $border-radius;
    background-color: $very-light-gray;
}

.icon-24 {
    font-size: 1.5rem;
}

.slist {
    padding-left: 1rem;
    li {
        line-height: 1.75;
        margin-bottom: 1rem;
    }
}

@each $key, $val in $font-sizes {
    .text-#{$key} {
        font-size: $val;
    }
}

mark,
.mark {
    &:empty {
        display: none;
    }
}
