.loi {
    padding: 0;
    &__banner {
        font-weight: $font-weight-normal !important;
    }
    &__date-range {
        font-size: $font-size-md;
        font-weight: $font-weight-normal;
        font-family: $sage-font-family;
        line-height: 1.75;
        color: $darkest-gray;
        margin-bottom: 3em;
    }
    &__tab__nav {
        overflow: auto;
        text-align: center;
        font-family: $sage-font-family;
    }
    &__issues {
        display: grid;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
        }
    }
    &__issue {
        padding: 0 0 1.5rem;
        margin: 0 0 1.5rem;
        border-bottom: $default-border;
        color: $deep-gray;
        &__info {
            font-size: $font-size-base;
            line-height: 1.75;
            margin-bottom: 0.25rem;
        }
        &__title {
            margin-bottom: 0.25rem;
        }
        &__title,
        &__page-range {
            font-size: $font-size-sm;
            line-height: 1.57;
        }
        .cover-image {
            width: 7.75rem;
            img {
                border: $default-border;
            }
        }
        &:nth-last-child(2):nth-child(odd),
        &:last-child {
            margin: 0;
            border: none;
        }
    }
    &__list {
        li {
            a {
                display: block;
                margin: 0;
                line-height: 1.75;
            }
        }
    }
}
