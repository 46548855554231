.modal {
    &__header {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        .close {
            float: none;
            justify-self: flex-end;
            color: $primary;
            &:focus {
                outline: 0;
            }
        }
    }
    &__title {
        margin: 0;
    }
    &__dialog {
        background: $white;
        @include media-breakpoint-up(md) {
            width: 80%;
            max-width: $modal-wide-max-width;
        }
        border-radius: 8px;
    }
    &-dialog {
        pointer-events: unset;
    }
    &-header {
        align-items: center;
    }
    &-footer {
        &--center {
            justify-content: center;
        }
    }
    &--wide {
        .modal-dialog {
            @include media-breakpoint-up(md) {
                width: 80%;
                max-width: $modal-wide-max-width;
            }
        }
    }
    &.in {
        padding: 0 !important;
    }
    &-backdrop {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.ux-modal-container {
    .modal {
        a:not(.btn):not(.dropdown-item) {
            color: $link;
            &:hover {
                text-decoration: none;
            }
        }
        a.btn-primary,
        a.btn-secondary {
            color: $white;
        }

        a.btn:hover,
        a.dropdown-item:hover,
        a:focus {
            text-decoration: none;
        }

        a.dropdown-item:hover {
            text-decoration: underline;
            color: $btn-color-4;
        }
    }
    .modal__dialog {
        @include media-breakpoint-up(md) {
            width: 80%;
            max-width: $modal-max-width;
        }
        border-radius: 8px;
    }
}

.modal-content {
    border-radius: 8px;
}
