// COLORS VARIABLES
$very-light-gray: #f7f7f7;
$very-light-gray-alt: #f0f0f0;
$light-gray: #eee;
$light-gray-2: #d9d9d9;
$gray-3: #bbb;
$gray-2: #ddd;
$gray: #ccc;
$dark-gray: #888;
$deep-gray: #555;
$darkest-gray: #333;
$grey-box-shadow: #dedede;
$secondary-text: #707070;
$disabled-text: $dark-gray;
$disabled-bg: $light-gray;

$white: #fff;
$gray-100: $very-light-gray;
$gray-200: $light-gray;
$gray-300: $gray-2;
$gray-400: $gray;
$gray-500: $dark-gray;
$gray-600: $secondary-text;
$gray-700: $deep-gray;
$gray-800: $darkest-gray;
$gray-900: #111;
$black: #000;

$semanticGrays: ();
$semanticGrays: map-merge(
    (
        'very-light-gray': $very-light-gray,
        'light-gray': $light-gray,
        'gray-2': $gray-2,
        'gray': $gray,
        'dark-gray': $dark-gray,
        'deep-gray': $deep-gray,
        'darkest-gray': $darkest-gray,
    ),
    $semanticGrays
);

$grays: ();
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
    ),
    $grays
);

$primary: #001a69;
$secondary: #046ff8;
$success: #008929;
$danger: #b0132d;
$warning: #bfac79;
$info: #5f8ab9;
$active: #14797b;
$light: $very-light-gray;
$dark: $dark-gray;
$link: $secondary;
$light-primary: #d9eeff;
$light-secondary: #e6f2f2;
$link-text: $link;
$journal-color: $secondary;
$orcid-color: #a6ce3a;
$view-more-color: #006acc;

$theme-colors: ();
$theme-colors: map-merge(
    (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
    ),
    $theme-colors
);

$cqlibraryFooter: #ffb500;
$dataplanetFooter: #57dce1;
$sagebusinesscasesFooter: #ffa55f;
$sagecampusFooter: #62c9af;
$sageknowledgeFooter: #001a69;
$sageresearchmethodsFooter: #77e296;
$sagevideoFooter: #ff9db4;

$sageProductsFooter: ();
$sageProductsFooter: map-merge(
    $sageProductsFooter,
    (
        'cqlibrary': $cqlibraryFooter,
        'dataplanet': $dataplanetFooter,
        'sagebusinesscases': $sagebusinesscasesFooter,
        'sagecampus': $sagecampusFooter,
        'sageknowledge': $sageknowledgeFooter,
        'sageresearchmethods': $sageresearchmethodsFooter,
        'sagevideo': $sagevideoFooter,
    )
);

$sageProductDefault: $primary;
$sageProductSecondary: $secondary;
$cqlibrary: #fbae16;
$cqlibrary-landingpage: #fdd689;
$dataplanet: #00b7af;
$leanlibrary: #002e54;
$sagebusinesscases: #ec6608;
$sagebusinesscases-landingpage: #ffa55f;
$sagecampus: #008563;
$sageknowledge: #630361;
$sageresearchmethods: #14797b; //008563
$sagevideo: #f0536a;
$blue: #3e75cf;
$green: #66b96b;
$green-landingpage: #70e492;
$pink: #ff0160;
$maroon: #8f143a;
$lilac: #5f5ca4;
$dark-red: #c4122f;
$dark-red-landingpage: #e08896;
$aegen-green: #64a993;
$aegen-green-landingpage: #b0d4c8;
$persian-green: #009492;
$persian-green-landingpage: #7fc9c8;
$geographyurban: #bbbaba;
$geographyurban-landingpage: #d2d2d2;
$sickly-green: #98b721;
$sickly-green-landingpage: #cbdb90;
$religion: #e9cc13;
$religion-landingpage: #f4e686;
$blue-gray: #6b6a93;
$blue-gray-landingpage: #b6b4c9;
$purple-studies: #639;
$purple-studies-landingpage: #b199cb;
$blue-1: #3e62a7;
$blue-2: #395fa5;
$blue-3: #4581ab;
$blue-4: #2b8cc8;
$blue-4-card: #b8d1e8;
$blue-1-landingpage: #9caed2;
$blue-2-landingpage: #9caed2;
$blue-3-landingpage: #a1c0d4;
$blue-4-landingpage: #b5e0d9;
$green-phar: #7abe63;
$green-phar-landingpage: #bedeb1;
$primary-dark: #b81e2d;
$primary-light: #fceeef;
$secondary-dark: #0d5b5e;
$secondary-light: #e6f2f2;
$error: #eb0000;

$sageProducts: ();
$sageProducts: map-merge(
    (
        'sageProductDefault': $sageProductDefault,
        'sageProductSecondary': $sageProductSecondary,
        'cqlibrary': $cqlibrary,
        'cqlibrary-landingpage': $cqlibrary-landingpage,
        'dataplanet': $dataplanet,
        'leanlibrary': $leanlibrary,
        'sagebusinesscases': $sagebusinesscases,
        'sagebusinesscases-landingpage': $sagebusinesscases-landingpage,
        'sagecampus': $sagecampus,
        'sageknowledge': $sageknowledge,
        'sageresearchmethods': $sageresearchmethods,
        'sagevideo': $sagevideo,
        'blue': $blue,
        'green': $green,
        'green-landingpage': $green-landingpage,
        'pink': $pink,
        'maroon': $maroon,
        'lilac': $lilac,
        'dark-red': $dark-red,
        'dark-red-landingpage': $dark-red-landingpage,
        'aegen-green': $aegen-green,
        'aegen-green-landingpage': $aegen-green-landingpage,
        'persian-green': $persian-green,
        'persian-green-landingpage': $persian-green-landingpage,
        'geographyurban': $geographyurban,
        'geographyurban-landingpage': $geographyurban-landingpage,
        'sickly-green': $sickly-green,
        'sickly-green-landingpage': $sickly-green-landingpage,
        'religion': $religion,
        'religion-landingpage': $religion-landingpage,
        'blue-gray': $blue-gray,
        'blue-gray-landingpage': $blue-gray-landingpage,
        'purple-studies': $purple-studies,
        'purple-studies-landingpage': $purple-studies-landingpage,
        'blue-1': $blue-1,
        'blue-2': $blue-2,
        'blue-3': $blue-3,
        'blue-4': $blue-4,
        'blue-4-card': $blue-4-card,
        'blue-1-landingpage': $blue-1-landingpage,
        'blue-2-landingpage': $blue-2-landingpage,
        'blue-3-landingpage': $blue-3-landingpage,
        'blue-4-landingpage': $blue-4-landingpage,
        'green-phar': $green-phar,
        'green-phar-landingpage': $green-phar-landingpage,
        'primary-dark': $primary-dark,
        'primary-light': $primary-light,
        'secondary-dark': $secondary-dark,
        'secondary-light': $secondary-light,
        'gray-1': $very-light-gray,
        'gray-2': $light-gray,
        'gray-3': $gray-2,
        'gray-4': $gray,
        'gray-5': $dark-gray,
        'gray-6': $deep-gray,
        'gray-7': $darkest-gray,
    ),
    $sageProducts
);

$access-open: #f68212;
$access-free: #66a22b;
$access-no: $danger;

$access-colors: ();
$access-colors: map-merge(
    (
        'access-free': $access-free,
        'access-open': $access-open,
        'access-no': $access-no,
    ),
    $access-colors
);

$facebook: #3b5998;
$twitter: #1da1f2;
$linkedin: #007bb5;
$youtube: #f00;
$instagram: #f00075;
$pinterest: #da3239;
$wechat: #00a152;

$socialnetworks-colors: ();
$socialnetworks-colors: map-merge(
    (
        'facebook': $facebook,
        'twitter': $twitter,
        'linkedin': $linkedin,
        'youtube': $youtube,
        'instagram': $instagram,
        'pinterest': $pinterest,
        'wechat': $wechat,
    ),
    $socialnetworks-colors
);

$input-btn-focus-color: #00309f;
$input-btn-secondary-focus-color: #adc7ff;

// buttons
$btn-color-1: $primary;
$btn-color-2: $secondary;
$btn-color-3: $light-primary;
$btn-color-4: #001f83;
$btn-color-5: #2c459f;
$btn-color-6: #2c459f;

$btn-colors: (
    // btn-class:(bg-color, text-color, border-color, hover-bg-color, hover-text-color, hover-border-color, active-bg-color, active-text-color, active-border-color)
    btn-primary:
        (
            $btn-color-1,
            $btn-color-3,
            $btn-color-1,
            $btn-color-3,
            $btn-color-4,
            $btn-color-4,
            $btn-color-3,
            $btn-color-4,
            $btn-color-4
        ),
    btn-outline-primary: (
        $white,
        $btn-color-1,
        $btn-color-1,
        $btn-color-4,
        $white,
        $btn-color-4,
        $btn-color-5,
        $white,
        $btn-color-5
    ),
    btn-secondary: (
        $btn-color-2,
        $white,
        $btn-color-2,
        $btn-color-4,
        $white,
        $btn-color-4,
        $btn-color-5,
        $white,
        $btn-color-5
    ),
    btn-outline-secondary: (
        $white,
        $btn-color-2,
        $btn-color-2,
        $btn-color-4,
        $white,
        $btn-color-4,
        $btn-color-5,
        $white,
        $btn-color-5
    ),
    btn-gray: ($very-light-gray, $deep-gray, $gray, $light-gray, $deep-gray, $gray, $white, $deep-gray, $gray)
);
