.institution {
    $_this: &;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 0.5rem;

    &__intro {
        display: flex;
        align-items: center;
        color: $institution-intro-color;
        font-size: $institution-intro-font-size;
        line-height: $institution-intro-line-height;
        white-space: nowrap;
        height: toRem(20);
        margin-top: toRem(1);
        @include media-breakpoint-up(lg) {
            font-size: $institution-intro-font-size-lg;
            line-height: $institution-intro-line-height-lg;
        }
    }

    &__institution {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: $institution-image-width;
    }
    &__image {
        flex: 0 0 auto;
        width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: left;
        &__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $institution-image-width;
            height: 2.5rem;
            overflow: hidden;

            a {
                display: block;
                width: 100%;
                height: 100%;
                padding: 0;
            }
        }
    }
    &__name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $institution-name-color;
        font-size: $institution-name-font-size;
        line-height: $institution-name-line-height;
        @include media-breakpoint-up(lg) {
            font-size: $institution-name-font-size-lg;
            line-height: $institution-name-line-height-lg;
        }
    }

    &.no-image {
        #{$_this}__institution {
            margin: 0;
            width: 100%;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                width: $institution-image-width;
            }
        }
        #{$_this}__name {
            display: block;
            color: $institution-name2-color;
            font-size: $institution-name2-font-size;
            font-weight: $font-weight-normal;
            text-align: left;

            @include media-breakpoint-up(lg) {
                font-size: $institution-name2-font-size-lg;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-align: center;
            }
        }
    }
    // institution banner for mobile and tablet view
    &__holder {
        padding: 1rem 0;
        margin: 0 1rem;
        border-top: $institution-dropzone-top-border;
        .institution {
            display: flex;
        }
    }
}

.header .institution a {
    color: $link;
    padding: 0;
    &:hover,
    &:focus {
        text-decoration: underline;
        color: $link;
    }
}

.profile-main {
    a[target]:not([data-toggle='tab']) {
        color: $secondary !important;
    }

    ul {
        li .icon-external-link::before {
            color: $primary !important;
        }
    }

    .icon-external-link {
        position: static;
        transform: unset;
        margin-inline-start: 0.5rem;
    }
}
