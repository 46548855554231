.twitter-timeline {
    display: block !important;
    width: 100% !important;
    max-width: 26.875rem !important;
    margin: 0 auto !important;
    iframe {
        width: 100% !important;
    }
}
.twitter-feed {
    &--horizontal {
        .twitter-timeline {
            max-width: 100% !important;
            margin: 1.5rem auto !important;
        }
    }
}
