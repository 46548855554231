@use 'sass:math';

.back-to-top {
    $this: &;

    position: fixed;
    bottom: math.div($back-to-top-uniform-padding, 1.5);
    left: math.div($back-to-top-uniform-padding, 1.5);
    width: $back-to-top-size-md;
    height: $back-to-top-size-md;
    visibility: hidden;
    opacity: 0;
    transform: translateY($back-to-top-fade-position-amount);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s 0.4s;
    z-index: $back-to-top-z-index;

    #{$icon-selector} {
        font-size: $back-to-top-action-icon-size;
        color: $back-to-top-action-icon-color;
    }

    @include media-breakpoint-up(lg) {
        bottom: $back-to-top-uniform-padding;
        left: $back-to-top-uniform-padding;
        width: $back-to-top-size;
        height: $back-to-top-size;
    }

    &__action {
        width: $back-to-top-size-md;
        height: $back-to-top-size-md;
        background: none;
        cursor: pointer;
        z-index: $back-to-top-action-z-index;
        border-radius: 50%;

        @include media-breakpoint-up(lg) {
            width: $back-to-top-size;
            height: $back-to-top-size;
        }

        &:hover {
            #{$this}__action-icon {
                animation: bounce $back-to-top-bounce-animation-duration ease-in-out infinite alternate;
            }
        }

        &__ring {
            stroke: $back-to-top-ring-color;
            fill: none;
            stroke-width: $back-to-top-ring-thickness;
            stroke-linecap: butt;
            animation: progress 0.3s ease-out forwards;
        }

        &__ring-bg {
            fill: none;
            stroke: $back-to-top-ring-bg-color;
            stroke-width: $back-to-top-ring-thickness;
        }

        #{$this}--title-hidden &-title {
            width: $back-to-top-action-icon-size;
            height: $back-to-top-action-icon-size;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &--align-right {
        left: auto;
        right: $back-to-top-uniform-padding * 0.5;

        @include media-breakpoint-up(lg) {
            right: $back-to-top-uniform-padding;
        }
    }

    &--visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s 0s;
    }

    &--snapped {
        position: absolute;
    }
}

@keyframes bounce {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY($back-to-top-bounce-animation-translateY-amount);
    }
}
