@use 'sass:map';

@mixin disabledBtn() {
    &.disabled,
    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        background-color: $btn-disabled-background;
        border-color: $btn-disabled-border-color;
        color: $btn-disabled-color;
        opacity: 1;
        pointer-events: none;
    }
}
@mixin disabledOutlineBtn() {
    &.disabled,
    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        appearance: none;
        background-color: $btn-disabled-background;
        border-color: $btn-disabled-border-color;
        color: $btn-disabled-color;
        opacity: 1;
        pointer-events: none;
    }
}
button {
    appearance: none;
    font-family: inherit;
    font-size: inherit;
}
.btn,
%btn {
    border-radius: $btn-border-radius;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: toRem(40);
    padding: toRem(10) toRem(20);
    font-size: 1rem;
    line-height: toRem(24);
    text-transform: capitalize;
    font-family: 'Sage Peak', sans-serif;
    transition: all 0.2s;

    @each $btn-type, $key-number in $btn-colors {
        &.#{$btn-type} {
            background-color: nth($key-number, 1);
            color: nth($key-number, 2);
            border-color: nth($key-number, 3);
            &:hover,
            &:focus {
                background-color: nth($key-number, 4);
                color: nth($key-number, 5) !important;
                border-color: nth($key-number, 6);
            }
            &:not(:disabled):not(.disabled):active {
                background-color: nth($key-number, 7);
                color: nth($key-number, 8);
                border-color: nth($key-number, 9);
            }
        }
    }

    &-lg {
        font-size: toRem(18);
        padding: 0 toRem(24);
    }
    &-sm {
        font-size: toRem(14);
        padding: 0 toRem(16);
    }

    &-link {
        padding: 0;
        height: auto;
        border: none;
        background: transparent;
        color: $link-text;
        text-decoration: underline;

        &:hover {
            color: darken($link-text, 10%);
            background: transparent;
            text-decoration: none;
        }
        &:not(:disabled):not(.disabled) {
            &:focus,
            &.focus,
            &:active {
                color: darken($link-text, 10%);
                background: transparent;
                text-decoration: none;
            }
        }
    }
    @include disabledBtn();

    &-outline {
        &-primary {
            &:hover {
                background-color: $light-primary;
                color: $primary;
            }
        }
        &-secondary {
            &:hover {
                background-color: $light-secondary;
                color: $secondary;
            }
        }
    }
    &[class*='btn-outline'] {
        @include disabledOutlineBtn();
    }

    &--collapse {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        #{$icon-selector} {
            font-size: 1.3rem;
        }

        &:not(.collapsed) {
            #{$icon-selector} {
                transform: rotate(180deg);
            }
        }
    }
    &--external {
        padding-right: 2.5rem;
        position: relative;
        #{$icon-selector} {
            position: absolute;
            right: 0;
            top: 50%;
            /* stylelint-disable unit-disallowed-list*/
            transform: translate(-10px, -50%);
            /* stylelint-enable unit-disallowed-list*/
            font-size: 1rem;
        }
    }
    &--with-icon {
        position: relative;
        gap: toRem(8);
        span {
            flex-grow: 1;
            text-align: center;
        }
        #{$icon-selector} {
            font-size: 1.25rem;
        }
    }
    &--institution-login {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: toRem(17);
        padding: toRem(9) 1rem;
        font-size: toRem(14);
        font-weight: 700;
        line-height: $btn-sso-login-info-line-height;
        height: toRem(50);
        width: toRem(328);
        border-radius: $btn-sso-login-border-radius;
        @include media-breakpoint-up(sm) {
            max-width: 23rem !important;
        }
        &:hover,
        &:focus {
            em {
                background: $primary;
            }
        }
        i {
            display: block;
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            flex-shrink: 0;
            font-size: toRem(22);
        }
        em {
            width: toRem(1);
            height: 2rem;
            background: white;
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-transform: none;
            width: 100%;
        }
        strong {
            display: block;
            font-size: $btn-sso-login-font-size;
            font-weight: $btn-sso-login-font-weight;
            line-height: $btn-sso-login-line-height;
        }
    }

    &-submit {
        display: inline-flex;
        width: 100%;
        height: 2.5rem;
        padding: 0;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        font-size: toRem(14);
        text-decoration: none;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(sm) {
            max-width: 18rem;
        }
    }
    &-small {
        font-size: toRem(14);
        line-height: 1.667;
    }
    &--icon {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        #{$icon-selector} {
            font-size: 1.25rem;
        }
        span {
            text-decoration: underline;
        }
        &:hover {
            span {
                text-decoration: none;
            }
        }
    }
}

a.btn:not(.btn-link) {
    text-decoration: none;
}

a[data-id='global-homepage-promotion-cta'] {
    font-size: toRem(14);
}

.society-activation {
    .btn {
        font-size: toRem(14);
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        &.focus,
        &:focus {
            outline: none;
            box-shadow: $box-shadow-focus;
        }

        @if $color == 'secondary' {
            &.focus,
            &:focus {
                outline: none;
                box-shadow: $box-shadow-focus-secondary;
            }
        }
        @include disabledBtn();
    }
    .btn-outline-#{$color} {
        &.focus,
        &:focus {
            outline: none;
            box-shadow: $box-shadow-focus;
            color: $value;
        }
        &:focus:hover {
            color: $white;
        }
        @include disabledBtn();
    }
}
[data-toggle='collapse'] {
    .whenCollapsed {
        display: none;
    }
    .whenOpened {
        display: block;
        text-transform: capitalize;
    }
    &:not(.collapsed) {
        &#{$icon-selector} {
            transform: rotate(180deg);
        }
    }
    &.collapsed {
        .whenCollapsed {
            display: block;
            text-transform: capitalize;
        }
        .whenOpened {
            display: none;
        }
    }
}

[data-toggle='dropdown'] {
    .whenCollapsed {
        display: none;
    }
    .whenOpened {
        display: block;
    }
    &[aria-expanded='false'] {
        .whenCollapsed {
            display: block;
        }
        .whenOpened {
            display: none;
        }
    }
}

.co-branding {
    .btn {
        min-width: $btn-min-width;
        text-transform: capitalize;
    }
}

[class^='icon-'],
[class*=' icon-'] {
    &:before {
        color: inherit;
    }
}
#access-institution-china {
    .btn {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
    }
}
