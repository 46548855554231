#citation {
    .alert__list {
        .form-check-input {
            flex-shrink: 0;
        }
        .form-check-label {
            display: flex;
            flex-shrink: 1;
            a {
                text-decoration: none;
                &:hover {
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }
    }

    #citationAlerts {
        .section__separator {
            flex-direction: column;
            align-items: flex-start;
        }
        .alert__sort {
            margin-bottom: 1.5rem;

            @include media('<=tablet') {
                align-self: center;
            }

            .left-side-label {
                align-items: center;

                label[for='citationsortby'] {
                    width: 4.25rem !important; // to override the css in the photo theme;
                }
            }
        }
        .checkbox--primary {
            .form-check-input {
                position: relative;
            }
        }
        #citationsortby {
            width: toRem(300);
        }

        .expandable {
            @include media('<=phone') {
                td:nth-child(2) {
                    display: block;

                    label {
                        padding-inline-start: toRem(16);
                    }
                }

                .to_hide {
                    display: none;
                }
            }
        }
    }
}

.alerts {
    .tab__nav {
        &__item:last-child {
            padding-inline-end: toRem(16);
        }

        a.tab__nav__item__link {
            font-family: $sage-font-family;

            @include media-breakpoint-down(lg) {
                font-family: $default-font-family;
            }
        }
    }
}

#citation:has(.disabled) {
    #citationAlerts,
    .disabled {
        opacity: 0.6;
    }
}

.alert__list {
    tbody {
        a,
        .to_hide {
            color: $secondary-text;
        }
    }
    tr {
        &:hover {
            background-color: $very-light-gray;
        }
    }
}
