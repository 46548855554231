.journal-aside-grid {
    margin: $sage-extra-gap 0;
    display: grid;
    row-gap: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'ad1' 'mid' 'ad2';

    @include media-breakpoint-up(md) {
        column-gap: $grid-gutter-width;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: 'ad1 ad2' 'mid mid';
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 'ad1' 'mid' 'ad2';
    }

    &__block-1 {
        grid-area: ad1;
        text-align: center;
    }
    &__block-2 {
        grid-area: mid;
    }
    &__block-3 {
        grid-area: ad2;
        text-align: center;
    }
    .rhs__container {
        margin-bottom: 0;
        h2 {
            margin: 0 0 0.25rem;
            font-size: $h6-font-size;
            #{$icon-selector} {
                color: inherit;
            }
        }
    }
}
