$flag-base-width: 2.5rem;
.dropdown-toggle {
    span {
        &.flag {
            width: calc(#{$flag-base-width} + 4ex);
        }
    }
}
.dropdown-item {
    &.flag {
        background-origin: content-box;
        padding: 0 0 0 0.5rem;
        line-height: 2rem;
        width: 100%;
        & span {
            display: flex;
            align-items: center;
            padding-left: $flag-base-width;
        }
    }
}

.sage-phone {
    .input-group {
        flex-wrap: nowrap;
    }
}
