.society-logos-widget {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        row-gap: 1.5rem;
    }

    .dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $society-logo-width;
        height: $society-logo-height;
    }
    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
    }
    &__image {
        display: block;
        flex: 0 0 auto;
        width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: left center;
    }
    &__text {
        font-size: $font-size-sm;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-left: toRem(5);
    }

    &__logos {
        position: absolute;
        z-index: $zindex-society-logos;
        top: toRem(86);
        left: 0;
        right: 0;
        padding: 4.75rem 1.5rem 1.5rem;
        background: $white;
        box-shadow: $box-shadow-basic-sm;
        border: $default-border;
        border-radius: 0.5rem;

        .row {
            display: flex;
            column-gap: 1rem;
            row-gap: 1.5rem;
            margin: 0;
            @include media-breakpoint-up(lg) {
                column-gap: 2rem;
            }
        }
        .col {
            flex-basis: calc(25% - 0.75rem);
            flex-grow: 0;
            padding: 0;
            @include media-breakpoint-up(lg) {
                flex-basis: calc(25% - 1.5rem);
            }
        }
        .society-logos-widget {
            &__logo {
                width: 100%;
                height: toRem(58);
                @include media-breakpoint-up(lg) {
                    height: toRem(100);
                }
            }
            &__image {
                width: auto;
                object-position: center;
            }
        }
        &__close {
            position: absolute;
            top: 1.75rem;
            right: 1.75rem;
            height: auto;
            padding: 0;
            margin: 0;
            font-size: $font-size-base;
            line-height: 1;
        }
    }
}
