.email-alerts {
    &__journals {
        border-bottom: $email-alerts-table-border-bottom;
        margin: 1rem 0;
        thead {
            background: $email-alerts-table-head-bg;
            th {
                font-size: $font-size-xs;
                font-weight: $font-weight-semibold;
                color: $email-alerts-table-color;
                text-align: center;
            }
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
        tbody {
            td {
                text-align: center;
                &:first-child {
                    text-align: left;
                }
            }
            tr {
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &--title {
            font-size: $font-size-sm;
            color: $email-alerts-table-color;
            font-weight: $font-weight-semibold;
            border-bottom: $email-alerts-table-border-bottom;
            @include media-breakpoint-up(sm) {
                border-bottom: none;
            }
        }
        input[type='checkbox'] {
            $size: 1.25rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: flex;
            align-items: center;
            margin: 0;
            border: none;
            height: auto;
            width: auto;
            background-color: transparent;
            @include media-breakpoint-up(sm) {
                width: $size;
                flex-basis: $size;
                margin: 0 auto;
            }

            &:before {
                background: transparent;
                display: inline-flex;
                width: $size;
                height: $size;
                border-color: $email-alerts-checkbox-border-color;
                @include media-breakpoint-up(sm) {
                    margin: 0;
                }
            }
            &:checked {
                background: transparent;
            }
        }
        @include media-breakpoint-down(xs) {
            input[name='journalCode'] {
                &:after {
                    content: 'New Content';
                    font-size: $font-size-sm;
                    color: $email-alerts-table-color;
                    font-weight: $font-weight-semibold;
                }
            }
            input[name='code'] {
                &:after {
                    content: 'Announcements';
                    font-size: $font-size-sm;
                    color: $email-alerts-table-color;
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }
    button[type='submit'] {
        text-transform: capitalize;
        float: right;
        padding: 0 1rem;
        font-size: toRem(14);
    }
}
