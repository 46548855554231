@mixin accordionHover() {
    background: $accordion-bg-hover;
    color: $accordion-color-hover;
    text-decoration: none;
    #{$icon-selector} {
        color: $white;
    }
    .accordion__img--invert {
        filter: brightness(0) invert(1);
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    &__control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 3.75rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
        text-decoration: none;
        font-family: 'Sage Peak', sans-serif;
        font-size: toRem(18);
        cursor: pointer;
        border-radius: toRem(5);

        background: $accordion-bg;
        color: $accordion-color;
        border: $accordion-border;
        box-shadow: inset 0.25rem 0 0 $primary;
        #{$icon-selector} {
            color: $deep-gray;
        }
        &:focus {
            text-decoration: none;
            color: $accordion-color;
        }
        &:hover {
            color: $accordion-color;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @include accordionHover;
            }
        }
        .js--open &,
        &.js--open {
            @include accordionHover;
            margin-bottom: 0;
            border-radius: toRem(5) toRem(5) 0 0;

            .icon-arrow_down {
                transform: rotate(180deg);
            }

            & + .accordion__content {
                box-shadow: $box-shadow-basic;
            }
        }
        i {
            font-size: 1.5rem;
        }
        h2,
        h4,
        > div {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: inherit;
            font-size: toRem(18);
            margin: 0;
            #{$icon-selector} {
                color: inherit;
            }
        }
    }
    &__content {
        margin-top: 0;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        color: $accordion-body-text-color;
        border-radius: 0 0 toRem(5) toRem(5);

        p {
            font-size: $font-size-sm;
        }

        .btn {
            &.btn-primary,
            &.btn-outline-primary {
                min-width: 16.5rem;

                @include media-breakpoint-down(xs) {
                    min-width: auto;
                }
            }
        }

        .icon-external-link {
            position: static;
            transform: unset;
            font-size: 1rem;
        }
    }

    &__contact__list,
    .rss__list {
        padding-left: 0;
        li {
            margin-left: 1.25rem;
            margin-bottom: 0.5rem;
        }
    }
}
/// html widget Give feedback / contact SAGE  LIT-413849
.accordion__actions {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    @include media-breakpoint-down(xs) {
        margin: 1rem;
        flex-direction: column;
        align-items: center;
    }
    &__link {
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 1rem;
        box-shadow: 0 0.125rem 0.5rem $grey-box-shadow;
        margin-left: 1rem;
        font-weight: $font-weight-normal;
        text-decoration: underline;
        color: $link-text;
        line-height: 1.6;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-bottom: 1rem;
            margin-left: 0;
        }
        &:hover {
            text-decoration: underline;
            color: $link-text;
            box-shadow: 0 0.125rem 1rem $grey-box-shadow;
        }
        i {
            font-size: 2rem;
            color: $primary;
            margin-bottom: 0.5rem;
        }
        img {
            margin-bottom: 0.5rem;
        }
    }
    .feedback__link {
        width: 12.5rem;
        font-size: $font-size-sm;
    }
    .rss__link {
        width: 13.5rem;
        font-size: $font-size-xs;
    }
}
.expand-all {
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: none;
    font-size: $font-size-sm;
    line-height: toRem(22);
    color: $accordion-show-all-color !important;
    min-height: 2rem;
    margin-bottom: 1rem;
    border: none;
    background-color: transparent;

    span {
        text-decoration: underline;
    }

    #{$icon-selector} {
        font-size: $font-size-lg;
        margin-right: 0.25rem;
        text-decoration: none;
    }
    img {
        margin-right: 0.25rem;
    }
    &:focus,
    &:hover {
        color: darken($accordion-show-all-color, 10%);
        span {
            text-decoration: none;
        }
    }
}

// keep up to date social media
.social-media {
    &__container {
        display: flex;
        justify-content: center;
        margin: 1rem 0;
        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
            margin-top: 0.5rem;
        }
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            margin: 0 2rem;
            text-decoration: none;
            color: $accordion-body-text-color;
            @include media-breakpoint-down(xs) {
                width: 50%;
                margin: 1rem 0;
            }
            img {
                margin-bottom: 0.3rem;
            }
        }
    }
}

.accordion-tabbed {
    &__control {
        &:hover {
            color: inherit;
        }
        .js--open &,
        &.js--open {
            margin-bottom: 0;

            .icon-arrow_down {
                transform: rotate(180deg);
            }
        }
        #{$icon-selector} {
            font-size: 1.5rem;
        }
    }
}
