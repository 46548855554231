.collection-display {
    list-style-position: inside;

    h2 {
        @extend %heading-s;
    }

    h2,
    h3 {
        display: inline;
        margin-bottom: 0;
    }

    &.top-selected {
        padding-inline-start: 0;
        border-top: 1px solid $border-color;
    }

    &.child {
        margin: 0.5rem 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        h3 {
            font-size: $font-size-base;
            line-height: 1.75;
            margin-bottom: 0;
        }
    }

    &.parent {
        display: inline-block;
    }

    &.none-child {
        padding: 1.5rem 0;
        border-bottom: 1px solid $border-color;

        &:last-of-type {
            border: 0;
        }
    }

    .collection-Meta {
        margin-bottom: 0.5rem;
        font-size: $font-size-sm;
        padding-inline-start: 1rem;

        &:first-of-type {
            margin-top: 0.5rem;
        }
    }

    .collection-editors {
        font-style: italic;
    }
}

.group-topics {
    &__collapse {
        float: right;

        #{$icon-selector} {
            color: $deep-gray;
        }
    }

    &__topics {
        padding-inline-start: 1rem;
        list-style: disc;
    }
}
