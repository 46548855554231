input[type='text'],
input[type='search'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea,
select,
.form-control,
.ms-ctn {
    -webkit-appearance: none;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: 1.571;
    height: 2.5rem;
    padding: 0 0.75rem;
    border-radius: toRem(4);
    &:focus {
        box-shadow: $box-shadow-focus;
    }
    @include disabledState();
}
input[type='password'] {
    &::-ms-reveal {
        display: none;
    }
}
textarea {
    height: 5rem;
    resize: none;
}
.custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: $box-shadow-focus;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label:before {
    background-color: transparent;
}
.checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

label {
    flex-shrink: 0;
    font-size: $font-size-base;
    line-height: 1.75;
}

.form-control {
    &:focus {
        border-color: inherit;
        box-shadow: $box-shadow-focus;
    }
    &.error {
        border-color: $error;
        color: inherit;
    }
}

.form-check {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    &-label {
        flex-shrink: 1;
    }
    &-input {
        flex-shrink: 0;
        position: static;
    }
    &.no-label {
        gap: 0;
    }
}

.required {
    &:before {
        content: '*';
        color: $error;
    }
}

@mixin is-invalid() {
    border: 1px solid $error !important;
    background-image: url('../../images/red-exc.svg');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1.5rem 1.5rem;
    color: $error;
}

.tfa-form-container {
    .type-pass-pin {
        &.is-invalid,
        &.is-invalid:focus-visible {
            @include is-invalid();
        }
    }
}

input[aria-invalid='true'] {
    @include is-invalid();
}

.form-control.is-invalid,
select.is-invalid {
    @include is-invalid();
}
.dropdown-selectable .dropdown-toggle.is-invalid {
    background-position: right 2.25rem center;
}

.custom-radio {
    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            background-image: none;
            background-color: $primary;
            border-radius: $custom-radio-indicator-border-radius;
            border: none;
        }
    }
}
.custom-control-input {
    &:checked ~ .custom-control-label::before {
        color: $custom-control-indicator-checked-color;
        border-color: $custom-control-indicator-checked-border-color;
        background-color: $white;
    }
    margin: 0;
}
.custom-control-label {
    display: inline-block;
    line-height: normal;
    // Background-color and (when enabled) gradient
    &::before {
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
    }

    // Foreground (icon)
    &::after {
        width: $custom-control-inside-indicator-size;
        height: $custom-control-inside-indicator-size;
        transform: translate(
            ($custom-control-indicator-size - $custom-control-inside-indicator-size) * 0.5,
            ($custom-control-indicator-size - $custom-control-inside-indicator-size) * 0.5
        );
    }
}
.custom-switch {
    .custom-control-label:after {
        transform: translate(0, 0);
    }
    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            background-color: $primary;
        }
    }
}

input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    margin: 0;
    background-color: transparent;
    border: none;

    &:before {
        display: flex;
        color: $primary;
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        background-color: $custom-control-indicator-bg;
        border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
        border-radius: 3px;
    }
    &:checked {
        background: transparent;
        &:before {
            content: '\e5ca';
            color: $primary;
            border-color: $dark-gray;
            background: white;
        }
    }
}

.form-group {
    position: relative;
    font-size: $font-size-sm;
    label {
        display: block;
        font-size: $font-size-sm;
        line-height: 1.57;
        margin-bottom: 0.25rem;
    }
    .form-check-label {
        margin-bottom: 0;
    }
}
.form-custom-row,
.form-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
            margin: 0 0 1rem 0;
        }
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 1.5rem;
        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            &:not(:last-child) {
                margin: 0 1.5rem 0 0;
            }
        }
    }
    &--fill {
        .btn:first-child {
            @include media-breakpoint-up(sm) {
                flex: 1 1 auto;
            }
        }
    }
    .btn {
        text-transform: capitalize;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}
.actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    margin-top: 1.5rem;
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 1rem;
        align-items: center;
    }

    #advanced-search-btn {
        padding: toRem(10) toRem(20);
    }

    .mainSearchButton {
        padding: toRem(10) toRem(20);
    }
}

.btn {
    .icon-delete {
        color: $error !important;
    }
}

select {
    border-radius: toRem(4);
    &.select-country-prefix {
        width: auto;
    }
}

.input-group {
    margin: 0;
    &-append {
        .btn {
            border: 1px solid $input-border-color;
            border-left: 0;
        }
    }
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
