.publication {
    &__nav {
        &__toggler {
            display: flex;

            @include media('>=large') {
                display: unset;
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}
