.mddb-navigation {
    $this: &;
    aside &,
    .col-r & {
        margin-bottom: $sage-extra-gap;
    }
    &__box {
        border-radius: $menu-border-radius;
        box-shadow: $mddb-navigation-box-shadow;
    }

    &__label {
        @extend .aside__header;
        margin: 0 $mddb-navigation-gap $mddb-navigation-gap * 0.5;
        color: $primary;
    }

    &__item {
        padding: 0;
    }

    &__link {
        display: block;
        padding: $mddb-navigation-gap * 0.5 $mddb-navigation-gap;
        font-size: $mddb-navigation-link-font-size;
        font-weight: $mddb-navigation-link-font-weight;
        line-height: $mddb-navigation-link-line-height;
        color: $mddb-navigation-link-color;
        text-decoration: none;
        &:hover {
            color: $btn-color-4;
            background-color: $light-primary;
            box-shadow: inset 0.125rem 0 0 $secondary;
            text-decoration: underline;
        }
        &.active {
            color: $mddb-navigation-link-color;
            background-color: $very-light-gray;
            box-shadow: inset 0.125rem 0 0 $primary;
            font-weight: $font-weight-semibold;
        }
    }

    &__button {
        text-transform: capitalize;
    }

    //Collapsible variant
    &--collapsible {
        margin: 0;
        #{$this} {
            &__toggle {
                padding: $mddb-navigation-gap 0;
                margin: 0;
                font-size: $font-size-base;
                font-weight: $font-weight-semibold;
                line-height: 1.75;
                color: $mddb-navigation-link-color;
                box-shadow: none;
                &.collapsed {
                    font-weight: $font-weight-normal;
                    /* stylelint-disable unit-disallowed-list*/
                    box-shadow: 0 1px 0 0 $gray;
                    /* stylelint-enable unit-disallowed-list*/
                }
            }
        }

        #{$this}__link {
            display: block;
            padding: $mddb-navigation-gap 0;
            /* stylelint-disable unit-disallowed-list*/
            box-shadow: inset 0 1px 0 0 $gray;
            /* stylelint-enable unit-disallowed-list*/
            &:hover,
            &.active {
                margin-left: -1 * $mddb-navigation-gap;
                padding-left: $mddb-navigation-gap;
            }

            &:hover {
                box-shadow: inset 0.125rem 0 0 $secondary;
            }

            &.active {
                box-shadow: inset 0.125rem 0 0 $primary;
            }
        }
    }
}
