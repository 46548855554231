.ip-ranges {
    &__info {
        font-size: $font-size-sm;
    }
    &__records {
        &-heading {
            @extend %heading-xxs;
            font-weight: $font-weight-bold;
            padding: 0 0 0.5rem;
            margin: 0;
            border-bottom: $default-border;
        }
    }
    &__record {
        font-size: $font-size-base;
        line-height: 1.75;
        padding: 1rem 0;
        border-bottom: $default-border;
        color: $darkest-gray;
    }
}

.ipRangesWidget a:not([role='tab']) {
    // this is to override theme color
    color: $secondary;
}
