.holdings {
    &__after {
        ol {
            padding-left: 0.5rem;
        }
        ul {
            padding-left: 1.5rem;
        }
        ol ul {
            padding-left: 0;
        }
        li {
            margin: 0.75rem;
            line-height: 1.75;
        }
        li li {
            list-style: lower-latin;
            &:marker {
                font-size: 1em;
            }
        }
    }
    .info {
        color: $dark-gray;
    }
    .actions {
        margin-top: 0;

        .btn {
            font-size: toRem(14);
        }
    }

    .holdings__form {
        input[type='radio'] {
            display: unset;
        }
    }
}
