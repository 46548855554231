.adplaceholder {
    position: relative;
    border: 1px solid red;
    padding-top: 1rem;
    text-align: center;

    &__title {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);

        padding: 0.2rem 1rem;
        margin: 0;
        background-color: $placeholder-label-bg;

        font-size: $font-size-xxs;
        line-height: 1.8;
        color: $secondary-text;
        text-transform: capitalize;
    }

    &.edit {
        border: $placeholder-edit-border;
        text-align: center;
        font-weight: bold;
    }

    &.exists {
        border: $placeholder-exist-border;
        border-radius: 0;
    }

    &.debug {
        border: $placeholder-debug-border;
    }
}

.pb-ad,
.pb-las {
    text-align: center;
    font-size: 0;
}

.pb-ad {
    @include media-breakpoint-down(sm) {
        overflow-x: auto;
    }
}
