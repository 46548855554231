.editorial-board {
    .accordion__control {
        &.js--open {
            & + .accordion__content {
                padding: 1rem 0;
            }
        }
    }
    tr:nth-child(even) {
        background-color: $graphql-table-striped;
    }
    .ed-board-member {
        a {
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            color: $graphql-member-name-color;
            text-decoration: none;
            &:hover {
                cursor: text;
            }
        }
        &-affiliation {
            text-align: left;
            font-size: $font-size-sm;
            font-weight: $font-weight-normal;
            color: $graphql-member-name-color;
            @include media-breakpoint-up(sm) {
                text-align: right;
            }
        }
    }

    .ed-board-name {
        i {
            font-size: 1.5rem;
        }
    }

    .ed-board-table {
        tr,
        td {
            display: block;
        }
        td {
            padding: 0.5rem 1rem;
        }

        @include media-breakpoint-up(sm) {
            tr {
                display: table-row;
            }
            td {
                display: table-cell;
                padding: 1rem;
            }
        }
    }
}
