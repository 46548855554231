.browse-cards {
    margin-inline: -1.25rem;

    &__list {
        @extend %more-sage-products__list;
        margin: 1.75rem 0 1.5rem;
        gap: $browse-cards-gap-vertical;

        @include media-breakpoint-up(sm) {
            gap: $browse-cards-gap-vertical--sm $browse-cards-gap-horizontal--sm;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-up(lg) {
            gap: $browse-cards-gap-horizontal--lg;
        }
    }
}

.browse-card {
    @extend .more-sage-products__list__item;
    position: relative;
    padding-top: 0.25rem;
    margin-bottom: 0;
    color: $deep-gray;

    @include media-breakpoint-up(sm) {
        flex-basis: calc(50% - #{$browse-cards-gap-horizontal--sm * 0.5});
    }

    @include media-breakpoint-up(lg) {
        flex-basis: calc(20% - #{4 * $browse-cards-gap-horizontal--lg * 0.2});
    }

    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        background-color: $sageProductDefault;
        height: 1rem;
        width: 100%;
        border-radius: toRem(8) toRem(8) 0 0;
    }

    &__link {
        @extend .more-sage-products__link;
        border-radius: 0 0 toRem(8) toRem(8);
        padding: 1.25rem 1rem 2.063rem;

        .product-name {
            display: flex;
            height: 3.5rem;
            align-items: center;
            justify-content: center;
            position: relative;
            color: $deep-gray;

            &:after {
                position: absolute;
                bottom: -1rem;
                background-color: $sageProductDefault;
                margin: 1rem auto 0;
            }
        }
    }

    &__title {
        font-size: $font-size-base;
        line-height: 1.75;
    }
}
